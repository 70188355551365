import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PropTypes } from 'prop-types';
import { withStyles, Divider, Typography, Grid } from '@material-ui/core';
import PlansTableRow from '../plansTableRow/plansTableRow';
import {
  calculateFeePerAudit,
  calculateQuotePerMissionPlan,
} from '../../services/newCampaignPricing.service';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: 'unset !important',
  },
  tableHeadHeight: {
    height: '10vh',
  },
  tableHeadHeightAdHoc: {
    height: '8vh',
  },
  logo: {
    maxHeight: '25px',
  },
  membership: {
    fontSize: '19px',
    fontWeight: '600',
    color: '#ffffff',
  },
  price: {
    fontSize: '19px',
    fontWeight: '600',
  },
  subscriptionTerm: {
    fontSize: '11px',
    opacity: '0.9',
    color: '#ffffff',
  },
  free: {
    color: '#4bb78a',
    textTransform: 'uppercase',
  },
  percentage: {
    fontSize: '19px',
    fontWeight: '600',
  },
  center: {
    textAlign: 'center',
    marginBottom: '10px',
    height: '15px',
  },
  feePerAudit: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#33475b',
  },
  fontBold: {
    fontWeight: 600,
    fontSize: '21px',
    color: '#33475b',
  },
  deduction: {
    fontSize: '11px',
    opacity: '0.9',
    color: '#b6bdc3',
    margin: '4px',
  },
};

class PlansTable extends React.PureComponent {
  renderHeader = () => {
    const { packageType } = this.props;
    const { membership, subscriptionTerm } = packageType;
    return (
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <Typography style={styles.membership}>{membership}</Typography>
        </Grid>
        <Grid item>
          <Typography style={styles.subscriptionTerm}>{subscriptionTerm}</Typography>
        </Grid>
      </Grid>
    );
  };
  renderFree = value => {
    const { packageType } = this.props;
    return packageType[value] === 0 ? (
      <Typography style={styles.free}> free </Typography>
    ) : (
      <Typography> ${packageType[value].toLocaleString()} </Typography>
    );
  };

  renderTrackFee = value => {
    const { packageType, quoteOrder } = this.props;
    if (packageType.membership === 'Ad-hoc' && !quoteOrder[value]) {
      return <Typography> n.a. </Typography>;
    }
    return this.renderFree(value);
  };

  renderPercentage = isValue =>
    isValue > 0 ? (
      <Typography style={styles.percentage}> -{isValue}%</Typography>
    ) : (
      <Typography style={styles.percentage}> - </Typography>
    );

  render() {
    const { classes, packageType, packSelected, quoteOrder, color } = this.props;
    return (
      <React.Fragment>
        <CustomGrid color={color}>
          <Table
            testid="table"
            size="small"
            style={{
              border: packSelected ? `1px solid ${color}` : 'none',
            }}
          >
            <TableHead style={{ backgroundColor: color }}>
              <TableRow
                selected
                classes={{
                  root:
                    packageType.membership === 'Ad-hoc'
                      ? classes.tableHeadHeightAdHoc
                      : classes.tableHeadHeight,
                }}
              >
                <TableCell classes={{ root: classes.tableCell }} align="center">
                  {this.renderHeader()}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <PlansTableRow>
                <Typography style={styles.feePerAudit}>
                  $
                  {(
                    Math.round(
                      calculateFeePerAudit(packageType.membership, quoteOrder.feePerAudit) * 10,
                    ) / 10
                  ).toLocaleString()}
                </Typography>
              </PlansTableRow>
              <PlansTableRow>{this.renderFree('designDataFee')}</PlansTableRow>
              <PlansTableRow>
                <Divider light variant="middle" />
              </PlansTableRow>
              <PlansTableRow>{this.renderTrackFee('fastCompletionFee')}</PlansTableRow>
              <PlansTableRow>{this.renderTrackFee('fastTrackFee')}</PlansTableRow>
              <PlansTableRow>
                <Divider light variant="middle" />
              </PlansTableRow>
              <PlansTableRow>
                <Typography style={styles.fontBold}>
                  $
                  {Math.round(
                    calculateQuotePerMissionPlan(packageType.membership, quoteOrder),
                  ).toLocaleString()}
                </Typography>
                {packageType.membership === 'Ad-hoc' ? null : (
                  <Typography style={styles.deduction}>
                    will be deducted from your credits
                  </Typography>
                )}
              </PlansTableRow>
            </TableBody>
          </Table>
        </CustomGrid>
      </React.Fragment>
    );
  }
}

PlansTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  packageType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    membership: PropTypes.string.isRequired,
    subscriptionTerm: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    feePerAudit: PropTypes.number.isRequired,
    designDataFee: PropTypes.number.isRequired,
    realTimeOnlineDashboard: PropTypes.bool.isRequired,
    digitalOnlineLibrary: PropTypes.bool.isRequired,
    rawDataExtract: PropTypes.bool.isRequired,
    chartsExtract: PropTypes.bool.isRequired,
    fastTrackFee: PropTypes.number.isRequired,
    fastCompletionFee: PropTypes.number.isRequired,
    insightPresentation: PropTypes.number.isRequired,
    bestDisplay: PropTypes.bool.isRequired,
    allSyndicatedReports: PropTypes.number.isRequired,
    omnibus: PropTypes.string.isRequired,
  }).isRequired,
  packSelected: PropTypes.bool.isRequired,
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  color: PropTypes.string.isRequired,
};

const CustomGrid = styled(Grid)`
  border-radius: 4px;
  box-shadow: 0 0 11px 5px rgba(234, 240, 246, 0.5);
  background-color: #ffffff;
  :hover {
    border: 1px solid ${props => props.color};
    background-color: #edf8ff;
    cursor: pointer;
  }
`;

export default withStyles(styles)(PlansTable);
