import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';
import { ArrowBackIosOutlined } from '@material-ui/icons';

const styles = {
  arrowColor: { color: '#03a6e9' },
};

const GoBack = connect(
  null,
  {
    goBack,
  },
)(({ record, data, ...props }) => (
  <IconButton onClick={props.goBack}>
    <ArrowBackIosOutlined testid="back" style={styles.arrowColor} color="#0071bc" />
  </IconButton>
));

export default GoBack;
