import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

const styles = {
  media: {
    height: 'auto',
    width: '90%',
    margin: 5,
    borderRadius: 4,
  },
  container: { overflow: 'auto' },
};
export const ImageExamplesPresenter = ({ classes, record }) => {
  const { imageExamples } = record;

  const renderImageCards = (example, index) => (
    <Grid item xs={8} md={7} lg={6}>
      <img
        key={index}
        testid="img"
        className={classes.media}
        alt={`example ${index}`}
        src={example}
      />
    </Grid>
  );
  return imageExamples ? (
    <Grid item xs={12} container style={styles.container}>
      {imageExamples.map((example, index) => renderImageCards(example, index))}
    </Grid>
  ) : null;
};

ImageExamplesPresenter.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  record: PropTypes.shape(PropTypes.any),
};
ImageExamplesPresenter.defaultProps = {
  record: { imageExamples: null },
};

export default withWidth()(withStyles(styles)(ImageExamplesPresenter));
