import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

export function NumberFormatCustom(props) {
  const { inputRef, onChange, prefix, ...other } = props;

  return (
    <NumberFormat
      testid="numberFormat"
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={prefix && prefix === 'Price' ? '$' : ''}
    />
  );
}

NumberFormatCustom.propTypes = {
  prefix: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatField({ value, onChange, label, ...props }) {
  return (
    <TextField
      testid="textField"
      label={label}
      value={value === 0 ? null : value}
      onChange={onChange}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      {...props}
    />
  );
}

NumberFormatField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberFormatField;
