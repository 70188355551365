import React from 'react';
import Grid from '@material-ui/core/Grid';

import Title from './components/title';
import Description from './components/description';
import Summary from './components/summary';
import GalleryPreview from './components/galleryPreview';
import SendMail from '../../components/sendMail';

const description = {
  title: `Understanding the drivers of performance during key selling periods such as Father's day has never been more important for informing optimal investment, innovation and execution for suppliers.`,
  intro: `Between 19/8 and 22/9, Snooper will collect data on in store execution and display measurement in a representative sample of liquor retailers to help alcohol suppliers understand which brands and categories won and what drove their performance.`,
  question: ` What's included in the report? `,
  topics: [
    {
      title: 'Objectives',
      content: `Understand how suppliers leverage store assets and off-location displays during this key selling period, and which brands were able to create the most impactful touch points outside the shelves.`,
    },
    {
      title: 'KPIs',
      content: `- Share of display per category;
      - Share of display per brand to understand who outperformed the market within each category and who managed to maximise the number of touchpoints outside the aisle;
      - Presence of creative concepts and shopper insights on the impact of displays in store;
      - Comparison of all KPIs across retail banners to help you prioritise your investments during key selling periods;
      
      You can request additional KPIs by showing your interest here below.`,
    },
  ],
};

const summary = {
  price: 7000,
  type: 'in-store execution',
  topics: ['Off-location', 'Gondola ends'],
  period: '13/08/2019',
  respondents: 22,
};

const images = [
  'https://client-ui-prod-static.s3-ap-southeast-2.amazonaws.com/static/media/NPD_OrderingPl.7ac3f55e.jpg',
  'https://client-ui-prod-static.s3-ap-southeast-2.amazonaws.com/static/media/NPD_OrderingPl.7ac3f55e.jpg',
];

const styles = {
  padding: {
    padding: 20,
  },
  summary: {
    backgroundColor: '#ffffff',
    padding: 20,
  },
  container: { height: '100%', backgroundColor: '#f5f8fa' },
};

export default function Overview() {
  const [open, setOpen] = React.useState(false);
  const openSendRequest = () => setOpen(true);
  const closeSendRequest = () => setOpen(false);

  return (
    <React.Fragment>
      <Title testid="title" openSendRequest={openSendRequest} record={{ name: 'test' }} />
      <Grid container style={styles.container}>
        <Grid item xs={12} md={10} lg={9} style={styles.padding}>
          <Description testid="description" content={description} />
        </Grid>
        <Grid item xs={12} md={2} lg={3} container style={styles.summary}>
          <Grid item>
            <Summary testid="summary" content={summary} />
          </Grid>
          <Grid item>
            <GalleryPreview testid="galleryPreview" images={images} />
          </Grid>
        </Grid>
      </Grid>
      <SendMail
        testid="sendMail"
        closeSendRequest={closeSendRequest}
        open={open}
        campaignType="Data serie"
        mailTitle="Client custom data serie request"
        mailBody="The client has reached out to build his own data serie"
        content="One of our customer success managers will get in touch to discuss your needs and to give access to this Data Series"
        isWithInput
        inputPlaceholder="Please share any additional information relevant to the Data Series"
      />
    </React.Fragment>
  );
}
