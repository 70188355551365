import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';

import onUpdatePhotosService from '../../services/updatePhoto.service';

import PhotoCard from '../cards/userCheckpointAnswerPhotoCard';

const styles = {
  noMatch: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
};

export class PhotosPerSubmissionsPresenter extends React.PureComponent {
  onUpdatePhotos = (dataFromChild, submissionId) => {
    const { onPhotosByCampaign, id, photosByCampaign } = this.props;
    const newPhotos = onUpdatePhotosService(photosByCampaign[id], dataFromChild, submissionId);
    return onPhotosByCampaign(id, newPhotos);
  };

  render() {
    const { drawerHeight, filteredPhotos, isFiltering, record, onHandleErrorMessage } = this.props;
    if (!filteredPhotos || isFiltering) {
      return (
        <Grid item xs={7} md={8} lg={9} style={{ maxHeight: drawerHeight, ...styles.noMatch }}>
          <CircularProgress testid="loader" />
        </Grid>
      );
    }
    if (filteredPhotos.length === 0 && !isFiltering) {
      return (
        <Grid item xs={7} md={8} lg={9} style={{ maxHeight: drawerHeight, ...styles.noMatch }}>
          <p testid="noMatch"> No submissions match this filter </p>
        </Grid>
      );
    }
    const photos = filteredPhotos.filter(photo => photo.photosPerSubmission);
    const { onTrackEvent } = this.props;
    return (
      <Grid item xs={7} md={8} lg={9} style={{ maxHeight: drawerHeight, overflowY: 'hidden' }}>
        <AutoSizer testid="photoCards">
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={photos.length}
              overscanRowCount={3}
              rowHeight={300}
              rowRenderer={({ index, style, key }) => {
                const checkPointAnswerPhotoByMission = photos[index];
                return (
                  <div style={style} key={key}>
                    <PhotoCard
                      location={checkPointAnswerPhotoByMission.location}
                      checkpointAnswerPhotosByMission={
                        checkPointAnswerPhotoByMission.photosPerSubmission
                      }
                      missionId={checkPointAnswerPhotoByMission.missionId}
                      segments={checkPointAnswerPhotoByMission.segments}
                      submissionId={checkPointAnswerPhotoByMission.submissionId}
                      record={record}
                      onUpdatePhotos={this.onUpdatePhotos}
                      cycle={checkPointAnswerPhotoByMission.cycle}
                      onHandleErrorMessage={onHandleErrorMessage}
                      width={width}
                      onTrackEvent={onTrackEvent}
                    />
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </Grid>
    );
  }
}

PhotosPerSubmissionsPresenter.propTypes = {
  id: PropTypes.string.isRequired,
  drawerHeight: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onPhotosByCampaign: PropTypes.func.isRequired,
  photosByCampaign: PropTypes.shape({}).isRequired,
  filteredPhotos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onHandleErrorMessage: PropTypes.func.isRequired,
  onTrackEvent: PropTypes.func.isRequired,
};

export default PhotosPerSubmissionsPresenter;
