import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SearchLogo from '../../../../../../../assets/images/search.png';

const styles = {
  noResults: {
    fontSize: '18px',
  },
  logo: {
    height: '75px',
  },
  middle: {
    position: 'fixed',
    top: '45%',
    left: '45%',
    width: '15%',
  },
};

const EmptyList = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    style={styles.middle}
    spacing={16}
  >
    <Grid item>
      <Typography testid="context" style={styles.noResults}>
        No results found
      </Typography>
    </Grid>
    <Grid item>
      <img testid="logo" src={SearchLogo} alt="" style={styles.logo} />
    </Grid>
  </Grid>
);

export default EmptyList;
