import * as Sentry from '@sentry/browser';

import fan from '../../../services/fan';

export const MEDIA_TYPE_OCTET_STREAM = 'application/octet-stream';
export const MEDIA_TYPE_APPLICATION_JSON_UTF8 = `application/json;charset=UTF-8`;

export async function getExcel(campaignID) {
  /* Added back responseType: 'blob', because AXIOS bugs out otherwise.
   * Still not 100% as AXIOS omits the data if it expects a blob response.
   * Working around this, as per below changes, as it needs a quick fix in Prod.
   *
   * Reference: https://github.com/axios/axios/issues/3779 */
  return fan
    .get(
      `/campaigns/${campaignID}/export?parse-session=${localStorage.getItem('token')}`,
      {
        responseType: 'blob',
        headers: {
          Accept: `${MEDIA_TYPE_OCTET_STREAM}, ${MEDIA_TYPE_APPLICATION_JSON_UTF8}`,
        },
      },
      {
        validateStatus(status) {
          return status === 200 || status === 202;
        },
      },
    )
    .then(response => {
      const isJsonBlob = data =>
        data instanceof Blob && data.type && data.type.startsWith('application/json');
      const isFileBlob = data => data instanceof Blob && data.type === MEDIA_TYPE_OCTET_STREAM;

      if (isJsonBlob(response.data)) {
        return {
          /*
           * To distinguish between the back-end response, using a non-existent type. Will be changed later.
           * This should show the message received from the back-end instead, but AXIOS does not provide the
           * data attribute if it is expecting a blob and JSON is returned. Might be a bug in AXIOS, but there
           * may be a workaround. For now, just providing this generic error instead.
           */
          type: 'CampaignExportNotYetAvailable',
          message:
            'Your campaign export is being processed by our system. Please try again in 2 hours.',
        };
      } else if (isFileBlob(response.data)) {
        const url = window.URL.createObjectURL(new Blob([response.data]), {
          type: `${MEDIA_TYPE_OCTET_STREAM}`,
        });
        const link = document.createElement('a');
        link.href = url;
        link.download = `extract_${campaignID}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();

        return null;
      }
      return response.data;
    })
    .catch(err => {
      if (err.status === 403) {
        return {
          type: 'CampaignExcelExportNotAllowed',
          message: 'Cannot export Excel for the campaign as admin has restricted exporting.',
        };
      }
      Sentry.captureException(err);
      return null;
    });
}

export async function getExcelDashboard(campaignID) {
  await fan
    .get(`/campaigns/${campaignID}/dashboard?parse-session=${localStorage.getItem('token')}`, {
      responseType: 'blob',
      headers: {
        Accept: `${MEDIA_TYPE_OCTET_STREAM}, ${MEDIA_TYPE_APPLICATION_JSON_UTF8}`,
      },
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]), {
        type: `${MEDIA_TYPE_OCTET_STREAM}`,
      });
      const link = document.createElement('a');
      link.href = url;
      link.download = `dashboard_${campaignID}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export default null;
