import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { Event } from '../../../../../setup/analytics';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  button: {
    width: '121px',
    height: '41px',
    borderRadius: '4px',
    backgroundColor: '#00c7fc',
    color: '#ffffff',
  },
  topRight: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#fff',
  },
  dialog: {
    height: '80%',
  },
  iframe: { width: '100%', height: '100%' },
};

function UseCaseDialog({ pdf, classes }) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    Event('Navigation', `See use case: ${pdf}`, 'Excellence programs');
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button testid="seeUseCase" style={styles.button} onClick={handleClickOpen}>
        View use case
      </Button>

      <Dialog
        testid="dialog"
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{ paperFullWidth: classes.dialog }}
      >
        <DialogContent>
          <IconButton onClick={handleClose} style={styles.topRight}>
            <Close />
          </IconButton>
          <iframe testid="pdf" src={pdf} title="pdf" style={styles.iframe} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

UseCaseDialog.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pdf: PropTypes.string.isRequired,
};

export default withStyles(styles)(UseCaseDialog);
