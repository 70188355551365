import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom';
import partyLogo from '../../../../../assets/images/party.png';

const styles = {
  logo: {
    marginLeft: 10,
    width: '50px',
  },
  title: { textAlign: 'center', fontSize: 25, fontWeight: 500 },
};

export function CongratulationsDialog(props) {
  function handleRedirect() {
    localStorage.removeItem('newCampaignOrder');
    setTimeout(() => {
      props.history.push('/home');
    }, 1000);
  }
  return (
    <Dialog testid="dialog" open={props.isSuccessful}>
      <DialogTitle testid="congrats" id="form-dialog-title" disableTypography style={styles.title}>
        Congratulations!
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <img style={styles.logo} src={partyLogo} alt="" />
      </DialogContent>
      <DialogContent>
        <DialogContentText>{props.dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button testid="cancel" onClick={handleRedirect} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CongratulationsDialog.propTypes = {
  isSuccessful: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  dialogContent: PropTypes.string.isRequired,
};

export default withRouter(CongratulationsDialog);
