import fan from '../../../services/fan';
import { order } from '../../variables/variables';

export async function mailNewCampaignOrder(newCampaign, email, phone) {
  const newOrder = {
    ...order,
    emailInput: email,
    phoneNumber: phone,
    orderItems: [newCampaign],
  };
  await fan.post(`/order/campaign`, JSON.stringify(newOrder), {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export default null;
