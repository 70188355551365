import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Event } from '../../../../../../../setup/analytics';
import { getGraphToken } from './services/getMetabaseGraphToken';

const styles = {
  root: { margin: '20px' },
  keyInsights: { fontWeight: 600 },
  noContent: {
    textAlign: 'center',
    marginTop: 50,
  },
};

class MetabasePreview extends React.PureComponent {
  state = {
    activeStep: 0,
    isLoadingIFrame: true,
    tokens: [],
  };

  componentDidMount = async () => {
    const { ids } = this.props.metabasePreviewIDs;
    const tokens = await Promise.all(ids.map(id => getGraphToken(id)));
    this.setState({ tokens });
  };

  componentDidUpdate = async prevProps => {
    const { ids } = this.props.metabasePreviewIDs;
    if (prevProps.metabasePreviewIDs.ids !== ids && ids.length !== 0) {
      const tokens = await Promise.all(ids.map(id => getGraphToken(id)));
      this.setState({ tokens, activeStep: 0 });
    }
  };

  handleIFrameLoading = () => {
    this.setState({ isLoadingIFrame: false });
  };

  handleNext = () => {
    Event('Navigation', 'Select next graph/picture', `Explore modules, insights preview`);
    const { ids, photos } = this.props.metabasePreviewIDs;
    const previewIds = ids.length > 0 ? ids : photos;
    if (this.state.activeStep === previewIds.length - 1) {
      return this.setState(prevState => ({
        activeStep: prevState.activeStep - (previewIds.length - 1),
      }));
    }
    return this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  handleBack = () => {
    Event('Navigation', 'Select preview graph/picture', `Explore modules, insights preview`);
    const { ids } = this.props.metabasePreviewIDs;
    if (this.state.activeStep === 0) {
      return this.setState(prevState => ({
        activeStep: prevState.activeStep + (ids.length - 1),
      }));
    }
    return this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  };

  renderCircularProgress = () => (
    <React.Fragment>
      <CircularProgress size={19} />
      <Typography> Loading ... </Typography>
    </React.Fragment>
  );

  renderKeyInsights = () => {
    const { activeStep } = this.state;
    const { metabasePreviewIDs } = this.props;
    const { insights } = metabasePreviewIDs;

    if (insights && insights.length > 0) {
      return insights.map((insight, index) =>
        activeStep === index ? (
          <Typography gutterBottom>
            <span style={styles.keyInsights}> Key insights: </span>
            {insight}
          </Typography>
        ) : null,
      );
    }
    return null;
  };

  renderIFrames = () => {
    const { activeStep, tokens } = this.state;
    if (tokens.length > 0) {
      return tokens.map((token, index) =>
        activeStep === index ? (
          <iframe
            key={index}
            title="iframe"
            testid="iframe"
            src={`https://metabase.snooper-app.com/embed/question/${token}#bordered=true&titled=true`}
            width="100%"
            height="350"
            frameBorder="0"
            onLoad={this.handleIFrameLoading}
          />
        ) : null,
      );
    }
    return null;
  };

  renderPictures = () => {
    const { photos } = this.props.metabasePreviewIDs;
    return photos.map((photo, index) =>
      this.state.activeStep === index ? (
        <Grid key={index} style={{ textAlign: 'center' }}>
          <img
            testid="image"
            src={photo}
            onLoad={this.handleIFrameLoading}
            rotate="90"
            width="auto"
            height="400px"
            alt=""
          />
        </Grid>
      ) : null,
    );
  };

  renderImageStepper = content => (
    <MobileStepper
      testid="steps"
      steps={content.length}
      position="static"
      activeStep={this.state.activeStep}
      nextButton={
        <Button size="small" onClick={this.handleNext} color="secondary">
          Next
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button size="small" onClick={this.handleBack} color="secondary">
          <KeyboardArrowLeft />
          Back
        </Button>
      }
    />
  );

  renderContent = () => {
    const { metabasePreviewIDs } = this.props;

    if (metabasePreviewIDs.ids.length === 0 && metabasePreviewIDs.photos.length === 0) {
      this.setState({ isLoadingIFrame: false });
      return <Typography style={styles.noContent}> No content available </Typography>;
    }

    if (metabasePreviewIDs.photos.length > 0 && metabasePreviewIDs.ids.length === 0) {
      return this.renderPictures();
    }

    return (
      <React.Fragment>
        {this.renderKeyInsights()}
        {this.renderIFrames()}
      </React.Fragment>
    );
  };

  render() {
    const { metabasePreviewIDs } = this.props;
    const { isLoadingIFrame } = this.state;
    return (
      <div style={styles.root}>
        <Grid container alignItems="center" justify="center">
          {isLoadingIFrame ? this.renderCircularProgress() : null}
        </Grid>
        {this.renderContent()}
        {metabasePreviewIDs.photos.length > 1
          ? this.renderImageStepper(metabasePreviewIDs.photos)
          : null}
        {metabasePreviewIDs.ids.length > 1 ? this.renderImageStepper(metabasePreviewIDs.ids) : null}
      </div>
    );
  }
}

MetabasePreview.propTypes = {
  metabasePreviewIDs: PropTypes.shape({
    ids: PropTypes.array,
    photos: PropTypes.array,
    insights: PropTypes.array,
  }).isRequired,
};

export default MetabasePreview;
