import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

const styles = {
  font: { marginBottom: '-10px' },
};

export default function ShareOfVisibilityFilter({
  sharesOfVisibilityFilters,
  sharesOfVisibilitySelectedValues,
  onHandleChange,
}) {
  const onHandleChangeSharesOfVisibilityFilters = filterName => selectedValues => {
    onHandleChange('sharesOfVisibilitySelectedValues', {
      ...sharesOfVisibilitySelectedValues,
      [filterName]: selectedValues,
    });
  };
  if (Object.entries(sharesOfVisibilityFilters).length > 0) {
    return (
      <div testid="shareOfVisibilityFilters">
        <ListItem>
          <h4 style={styles.font}> Filters for share of visibility</h4>
        </ListItem>
        <ListItem testid="shareOfVisibilityFilters">
          <FilterSelect
            testid="locationForShareOfVisibilities"
            segmentValues={[sharesOfVisibilityFilters.locations]}
            segmentName={['Location in store']}
            onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters('locationsSelected')}
            segmentValue={sharesOfVisibilitySelectedValues.locationsSelected}
          />
        </ListItem>
        <ListItem>
          <FilterSelect
            testid="labelForShareOfVisibilities"
            segmentValues={[sharesOfVisibilityFilters.labels]}
            segmentName={['Label']}
            onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters('labelsSelected')}
            segmentValue={sharesOfVisibilitySelectedValues.labelsSelected}
          />
        </ListItem>
        <ListItem>
          <FilterSelect
            testid="categoriesForShareOfVisibilities"
            segmentValues={[sharesOfVisibilityFilters.categories]}
            segmentName={['Categories']}
            onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters('categoriesSelected')}
            segmentValue={sharesOfVisibilitySelectedValues.categoriesSelected}
          />
        </ListItem>
        {sharesOfVisibilityFilters.subCategories.length > 0 && (
          <ListItem>
            <FilterSelect
              testid="subCategoriesForShareOfVisibilities"
              segmentValues={[sharesOfVisibilityFilters.subCategories]}
              segmentName={['Sub categories']}
              onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters(
                'subCategoriesSelected',
              )}
              segmentValue={sharesOfVisibilitySelectedValues.subCategoriesSelected}
            />
          </ListItem>
        )}
        {sharesOfVisibilityFilters.manufacturers.length > 0 && (
          <ListItem>
            <FilterSelect
              testid="manufacturersForShareOfVisibilities"
              segmentValues={[sharesOfVisibilityFilters.manufacturers]}
              segmentName={['Manufacturers']}
              onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters(
                'manufacturersSelected',
              )}
              segmentValue={sharesOfVisibilitySelectedValues.manufacturersSelected}
            />
          </ListItem>
        )}
        {sharesOfVisibilityFilters.packGroups.length > 0 && (
          <ListItem>
            <FilterSelect
              testid="packGroupsForShareOfVisibilities"
              segmentValues={[sharesOfVisibilityFilters.packGroups]}
              segmentName={['Pack groups']}
              onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters('packGroupsSelected')}
              segmentValue={sharesOfVisibilitySelectedValues.packGroupsSelected}
            />
          </ListItem>
        )}
        {sharesOfVisibilityFilters.promoPacks.length > 0 && (
          <ListItem>
            <FilterSelect
              testid="promoPacksForShareOfVisibilities"
              segmentValues={[sharesOfVisibilityFilters.promoPacks]}
              segmentName={['Promo packs']}
              onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters(
                'promoMechanicsSelected',
              )}
              segmentValue={sharesOfVisibilitySelectedValues.promoMechanicsSelected}
            />
          </ListItem>
        )}
        {sharesOfVisibilityFilters.productSegments.length > 0 && (
          <ListItem>
            <FilterSelect
              testid="productSegmentsForShareOfVisibility"
              segmentValues={[sharesOfVisibilityFilters.productSegments]}
              segmentName={['Product Segments']}
              onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters(
                'productSegmentsSelected',
              )}
              segmentValue={sharesOfVisibilitySelectedValues.productSegmentsSelected}
            />
          </ListItem>
        )}
        {sharesOfVisibilityFilters.brands.length > 0 && (
          <ListItem>
            <FilterSelect
              testid="brandsFilter"
              segmentValues={[sharesOfVisibilityFilters.brands]}
              segmentName={['Brands']}
              onHandleChangeFilter={onHandleChangeSharesOfVisibilityFilters('brandsSelected')}
              segmentValue={sharesOfVisibilitySelectedValues.brandsSelected}
            />
          </ListItem>
        )}
      </div>
    );
  }
  return <div />;
}

ShareOfVisibilityFilter.propTypes = {
  sharesOfVisibilityFilters: PropTypes.shape({}).isRequired,
  sharesOfVisibilitySelectedValues: PropTypes.shape({}).isRequired,
  onHandleChange: PropTypes.func.isRequired,
};
