import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fan from '../modules/services/fan';
import { readEncodedUserFromLocalStorage } from '../modules/authentication/AuthProvider';

export const UserContext = createContext();

/**
 * Context provider component for managing user data and subscriptions.
 * Fetches user data and customer subscriptions on mount and provides them via context.
 *
 * @component
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render within the provider
 * @returns {JSX.Element} JSX element representing the user context provider
 */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [customerSubscriptions, setCustomerSubscriptions] = useState(null);

  const getCustomerSubscriptions = async () => {
    try {
      const customerSubscriptionsResponse = await fan.get('customers/subscriptions/mine');
      return customerSubscriptionsResponse.data.data;
    } catch (error) {
      throw error;
    }
  };

  const getUser = async clientId => {
    try {
      const userResponse = await fan.get(`/users/${clientId}`);
      return userResponse.data.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const clientId = localStorage.getItem('clientId');
      const localStorageUser = readEncodedUserFromLocalStorage();
      try {
        if (clientId && localStorageUser.role !== 'ADMIN') {
          const [userData, customerSubscriptionsData] = await Promise.all([
            getUser(clientId),
            getCustomerSubscriptions(),
          ]);
          setUser(userData);
          setCustomerSubscriptions(customerSubscriptionsData);
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <UserContext.Provider value={{ user, customerSubscriptions }}>{children}</UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};
