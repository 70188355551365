import React, { useState } from 'react';
// eslint-disable-next-line import/extensions
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FastRewind from '@material-ui/icons/FastRewind';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-image-lightbox/style.css';

import tagPhotoAnswerService from '../../services/tagPhoto.service';
import ReviewersPhotoLink from '../reviewersLink/reviewersPhotoLink';
import resetPhotoToOriginal from '../../services/resetPhotoService';
import Alert from '../alert/alert';

const PhotoIcons = ({
  photo,
  submissionId,
  onHandleErrorMessage,
  onUpdatePhotoTag,
  campaignId,
  onImageReload,
}) => {
  const { photoAnswerTags, checkpointId, answerPhotoIndex, url } = photo;
  const [isLoading, setLoading] = React.useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
  });
  const [alertOpen, setAlertOpen] = React.useState(false);

  const resetToOriginal = async () => {
    setLoading(true);
    try {
      await resetPhotoToOriginal(submissionId, checkpointId, photo.key);
      onImageReload();
      setLoading(false);
    } catch (e) {
      Sentry.captureException(e);
      setLoading(false);
      onHandleErrorMessage('Failed to reset photo to original');
    }
    return null;
  };

  const onHidePhotoAnswer = (tagName, tagValue) => async () => {
    setLoading(true);
    try {
      const result = await tagPhotoAnswerService(
        submissionId,
        checkpointId,
        answerPhotoIndex,
        tagName,
        tagValue,
      );
      setLoading(false);
      if (result && result.data) {
        const newTags = result.data.answers.filter(
          answer => answer.checkpointId === checkpointId,
        )[0].answer[answerPhotoIndex].tags;
        onUpdatePhotoTag(campaignId, {
          ...photo,
          photoAnswerTags: newTags,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      setLoading(false);
      onHandleErrorMessage('Failed to (un)hide, please try again');
    }
    return null;
  };

  const renderVisibilityIcon = () => {
    if (
      photoAnswerTags &&
      photoAnswerTags.filter(t => t.name === 'hiddenToClients' && t.value === 'yes').length > 0
    ) {
      return (
        <Tooltip title={`Make visible for clients, ${submissionId}`}>
          <IconButton
            testid="makeVisible"
            style={photoIconsStyles.hideIcon}
            onClick={onHidePhotoAnswer('hiddenToClients', 'no')}
          >
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={`Hide for clients, ${submissionId}`}>
        <IconButton
          testid="hide"
          style={photoIconsStyles.visibleIcon}
          onClick={onHidePhotoAnswer('hiddenToClients', 'yes')}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    );
  };

  if (isLoading) {
    return <CircularProgress size={22} color="secondary" />;
  }

  return (
    <div>
      <Alert
        onConfirm={() => resetToOriginal()}
        open={alertOpen}
        setOpen={setAlertOpen}
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        dialogText="Are you sure you want to revert this photo to the original? This will remove any face blur and any modifications added. This operation cannot be undone."
        title="Revert to Original"
      />
      {renderVisibilityIcon()}
      <PopoverContainer>
        <Popover className="relative">
          <Popover.Button ref={setReferenceElement} as={PopoverButton}>
            ...
          </Popover.Button>

          <Popover.Panel ref={setPopperElement} style={styles.popper}>
            <Tooltip title="Reset the photo to original">
              <Button testid="reset" onClick={() => setAlertOpen(true)}>
                <Avatar>
                  <FastRewind />
                </Avatar>
              </Button>
            </Tooltip>
            <ReviewersPhotoLink id={submissionId} photoUrl={url} />
          </Popover.Panel>
        </Popover>
      </PopoverContainer>
    </div>
  );
};

const PopoverContainer = styled.div`
  margin-left: 11px;
  margin-right: 15px;
  margin-bottom: 40px;
`;

const PopoverButton = styled.button`
  width: 50;
  height: 50;
  border-radius: 50px;
  opacity: 70%;
`;

PhotoIcons.propTypes = {
  photo: PropTypes.shape({
    checkpointId: PropTypes.string,
    key: PropTypes.string,
    shortDescription: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    url: PropTypes.string,
    photoAnswerTags: PropTypes.array,
    answerPhotoIndex: PropTypes.number,
    userMissionId: PropTypes.string.isRequired,
  }).isRequired,
  submissionId: PropTypes.string.isRequired,
  onHandleErrorMessage: PropTypes.func.isRequired,
  onUpdatePhotoTag: PropTypes.func.isRequired,
  onImageReload: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
};

const photoIconsStyles = {
  visibleIcon: { boxShadow: 'unset', color: '#6ecc73' },
  hideIcon: { boxShadow: 'unset', color: '#ea6960' },
  icons: { textAlign: 'center' },
};

export default PhotoIcons;
