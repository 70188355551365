import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  infoIcon: { maxHeight: 15, color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' },
  bold: {
    fontWeight: 500,
  },
  italic: {
    fontStyle: 'italic',
  },
  padding: {
    padding: 8,
  },
  background: {
    backgroundColor: '#f9fbfc',
    opacity: '1 !important',
  },
};

const DesignAndDataFeeTitle = () => (
  <Grid style={styles.background}>
    <Typography gutterBottom style={styles.padding}>
      Design & Data fee covers Approach & Methodology
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      Design & Build of mission in app
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      Reporting including real time access to customizable online dashboard
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      Raw data in excel
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      Digital library of pictures and ability to Download photos in zip folder
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      Data storage for as long as required
    </Typography>
  </Grid>
);

const FastTrackTitle = () => (
  <Grid style={styles.background}>
    <Typography gutterBottom style={styles.listItems}>
      A <span style={styles.italic}> ‘Fast track’ </span> fee applies when the start of your mission
      is within 2 days of your request.
    </Typography>
  </Grid>
);

const FastCompletionTitle = () => (
  <Grid style={styles.background}>
    <Typography gutterBottom style={styles.listItems}>
      A <span style={styles.italic}> ‘Fast completion’ </span> fee applies when the difference
      between the start and the end of your mission is less than 7 days.
    </Typography>
  </Grid>
);

const TotalCostTitle = () => (
  <Grid style={styles.background}>
    <Typography gutterBottom style={styles.listItems}>
      This is an estimated quote. Final cost will be confirmed after one of our Customer Success
      Managers will have contacted you.
    </Typography>
    <Typography gutterBottom style={styles.listItems}>
      This is an estimated quote for a 1-off mission. Further discounts apply for recurring
      missions.
    </Typography>
    <Typography gutterBottom style={styles.listItems}>
      If you’re on a Missions Pack, this amount will only be deducted from your remaining credits
      once you actually launch your mission.
    </Typography>
  </Grid>
);

const CustomTooltip = ({ title }) => (
  <StyledTooltip disableTouchListener title={title} interactive>
    <InfoIcon style={styles.infoIcon} />
  </StyledTooltip>
);

CustomTooltip.propTypes = {
  title: PropTypes.shape(PropTypes.any).isRequired,
};

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f9fbfc',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  popper: {
    opacity: '1',
  },
}))(Tooltip);

export const DesignAndDataFeeTooltip = () => (
  <CustomTooltip testid="tooltip" title={<DesignAndDataFeeTitle />} />
);
export const FastTrackTooltip = () => <CustomTooltip title={<FastTrackTitle />} />;
export const FastCompletionTooltip = () => <CustomTooltip title={<FastCompletionTitle />} />;
export const TotalCostTooltip = () => <CustomTooltip title={<TotalCostTitle />} />;

export default null;
