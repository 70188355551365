import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { sumTotalShoppingCart } from '../../services/shoppingCart.service';
import RequestMailOrder from '../requestMailOrder/requestMailOrder';

const styles = {
  totalPrice: { fontWeight: 500 },
  container: {
    marginTop: 30,
    padding: 20,
  },
};

const ShoppingCartTotal = ({ ids, data, onHandleMessage }) => {
  const total = sumTotalShoppingCart(ids, data);
  const gst = total / 10;
  const totalInclGST = total + gst;
  return (
    <Grid container style={styles.container}>
      <Grid item xs={10}>
        <RequestMailOrder order={data} onHandleMessage={onHandleMessage} />
      </Grid>
      <Grid item xs={2}>
        <Typography testid="total">Total ${total.toLocaleString()}</Typography>
        <Typography>GST(10%) ${gst.toLocaleString()}</Typography>
        <Typography style={styles.totalPrice}>
          Total incl GST ${totalInclGST.toLocaleString()}
        </Typography>
      </Grid>
    </Grid>
  );
};

ShoppingCartTotal.propTypes = {
  ids: PropTypes.shape(PropTypes.any),
  data: PropTypes.shape(PropTypes.any),
  onHandleMessage: PropTypes.func.isRequired,
};

ShoppingCartTotal.defaultProps = {
  data: {},
  ids: [],
};

export default ShoppingCartTotal;
