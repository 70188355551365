import React from 'react';
import { List, BulkDeleteButton } from 'react-admin';
import ShoppingCartListPresenter from './shoppingCartShow/shoppingCartListPresenter';

const styles = {
  list: {
    marginTop: 5,
  },
};

const ShoppingCartList = props => (
  <List
    {...props}
    title="checkout"
    actions={null}
    bulkActionButtons={<BulkDeleteButton {...props} undoable={false} />}
    pagination={null}
    style={styles.list}
  >
    <ShoppingCartListPresenter />
  </List>
);

export default ShoppingCartList;
