import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ContentCreate from '@material-ui/icons/Create';
import EYE_BASE_URL from './reviewersPlatformConstants';

const reviewersPlatformLinkButton = ({ id }) => {
  const token = localStorage.getItem('token');
  return (
    <Tooltip title={`View/review submission ${id}`}>
      <Button
        testid="link"
        href={`${EYE_BASE_URL}/${id}?token=${token}`}
        target="_blank"
        label=""
        style={{
          marginLeft: '5px',
          maxWidth: '40px',
          maxHeight: '40px',
          minWidth: '40px',
          minHeight: '40px',
        }}
      >
        <ContentCreate
          style={{
            maxHeight: '20px',
            color: '#0072bc',
          }}
        />
      </Button>
    </Tooltip>
  );
};

reviewersPlatformLinkButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default reviewersPlatformLinkButton;
