import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

const styles = {
  font: { marginBottom: '-10px' },
};

export default function CheckpointsAnswerFilter({
  checkpoints,
  selectedCheckpointsByCategories,
  onHandleChange,
}) {
  if (checkpoints == null || checkpoints.length === 0) {
    return null;
  }
  const onHandleChangeCheckpoint = shortDescription => (
    checkpointsSelected,
    checkpointShortDescriptionSelected,
  ) => {
    onHandleChange(
      'selectedCheckpointsByCategories',
      selectedCheckpointsByCategories.map(oldCheckpoints =>
        oldCheckpoints[0] === shortDescription
          ? [checkpointShortDescriptionSelected, checkpointsSelected]
          : oldCheckpoints,
      ),
    );
  };

  return (
    <div>
      <ListItem>
        <h4 style={styles.font}> Filter by checkpoint </h4>
      </ListItem>
      {checkpoints.map((checkpoint, index) => (
        <ListItem key={index}>
          <FilterSelect
            testid="checkpoint"
            segmentValues={[checkpoint.options.sort((a, b) => a.localeCompare(b))]}
            segmentName={[checkpoint.shortDescription]}
            checkpointQuestion={checkpoint.question}
            onHandleChangeFilter={onHandleChangeCheckpoint(checkpoint.shortDescription)}
            isCheckpointFilter
            segmentValue={selectedCheckpointsByCategories[index][1]}
          />
        </ListItem>
      ))}
    </div>
  );
}

CheckpointsAnswerFilter.propTypes = {
  checkpoints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCheckpointsByCategories: PropTypes.arrayOf(PropTypes.array).isRequired,
  onHandleChange: PropTypes.func.isRequired,
};
