import React from 'react';
import PropTypes from 'prop-types';
/*
 * This component is responsible for loading the Hubspot chatbot script based on the provided url and tracking code.
 */
const HubSpotSnippet = ({ url, trackingCode }) => {
  React.useEffect(() => {
    const scriptUrl = `${url}${trackingCode}.js`;

    const scriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    scriptElement.async = true;

    document.body.appendChild(scriptElement);
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [url, trackingCode]);
  // return <script testid="hubspotScript" src="//js-na1.hs-scripts.com/24118779.js" />;
  return <></>;
};

HubSpotSnippet.propTypes = {
  url: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
};

export default HubSpotSnippet;
