import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import { PropTypes } from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import PlansTableRow from '../plansTableRow/plansTableRow';
import {
  DesignAndDataFeeTooltip,
  FastTrackTooltip,
  FastCompletionTooltip,
  TotalCostTooltip,
} from './components/tooltips';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: 'unset',
  },
  tableHeadHeight: {
    '@media (min-width: 700px)': {
      height: '9vh',
    },
    '@media (min-width: 850px)': {
      height: '6vh',
    },
  },
  fontBold: {
    fontWeight: 500,
  },
  listItem: {
    display: 'list-item',
    marginLeft: 20,
  },
  logo: {
    maxHeight: '12px',
    opacity: '0.7',
  },
  table: {},
};

class PackageTableHead extends React.PureComponent {
  renderInfoIcon = tooltip => (
    <TableCell
      classes={{ root: this.props.classes.tableCell }}
      align="right"
      component="th"
      scope="row"
    >
      {this.renderTooltip(tooltip)}
    </TableCell>
  );

  renderTooltip = tooltip => {
    switch (tooltip) {
      case 'DesignAndDataFeeTooltip':
        return <DesignAndDataFeeTooltip />;
      case 'FastCompletionTooltip':
        return <FastCompletionTooltip />;
      case 'FastTrackTooltip':
        return <FastTrackTooltip />;
      case 'TotalCostTooltip':
        return <TotalCostTooltip />;
      default:
        return null;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Table testid="table" size="small" style={styles.table}>
        <TableHead>
          <TableRow classes={{ root: classes.tableHeadHeight }}>
            <TableCell classes={{ root: classes.tableCell }} align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          <PlansTableRow align="left">
            <Typography testid="feePerAudit"> Fee per audit/respondent </Typography>
          </PlansTableRow>
          <PlansTableRow infoIcon={this.renderInfoIcon('DesignAndDataFeeTooltip')} align="left">
            <Typography> Design & data fee </Typography>
          </PlansTableRow>
          <PlansTableRow align="left">
            <Divider light variant="middle" />
          </PlansTableRow>
          <PlansTableRow infoIcon={this.renderInfoIcon('FastCompletionTooltip')} align="left">
            <Typography> Fast completion fee </Typography>
          </PlansTableRow>
          <PlansTableRow infoIcon={this.renderInfoIcon('FastTrackTooltip')} align="left">
            <Typography testid="fastTrackFee">Fast track fee</Typography>
          </PlansTableRow>
          <PlansTableRow align="left">
            <Divider light variant="middle" />
          </PlansTableRow>
          <PlansTableRow infoIcon={this.renderInfoIcon('TotalCostTooltip')} align="left">
            <Typography style={styles.fontBold}> Total mission cost </Typography>
          </PlansTableRow>
        </TableBody>
      </Table>
    );
  }
}

PackageTableHead.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(PackageTableHead);
