const optionRenderer = type => values => {
  switch (values.length) {
    case 0:
      return '';
    case 1:
      return values[0];
    default:
      return `${values.length} ${type} selected`;
  }
};

export default optionRenderer;
