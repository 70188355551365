import React from 'react';
import { Grid } from '@material-ui/core';

import { infoExploreCampaignTypes, dataSeries } from '../../variables/variables';
import ModulesInfo from '../modules/components/modulesInfo';

import SendMail from '../components/sendMail';
import CampaignCard from './components/campaignCard';
import ModulesListFooter from '../modules/components/modulesListFooter';

const styles = {
  container: { padding: '4%', marginBottom: 30 },
};

function DataSeries() {
  const [open, setOpen] = React.useState(false);
  const closeSendRequest = () => setOpen(false);
  const openSendRequest = () => setOpen(true);

  return (
    <React.Fragment>
      <Grid>
        <ModulesInfo
          testid="modulesInfo"
          infoExploreCampaignTypes={infoExploreCampaignTypes.dataSeries}
        />
      </Grid>

      <Grid container alignItems="center" spacing={32} style={styles.container}>
        {dataSeries.map(dataSerie => (
          <Grid item xs={12} md={3} container justify="center">
            <CampaignCard testid="cards" content={dataSerie} />
          </Grid>
        ))}
      </Grid>
      <SendMail
        testid="sendMail"
        closeSendRequest={closeSendRequest}
        open={open}
        campaignType="Data serie"
        mailTitle="Client custom data serie request"
        mailBody="The client has reached out to build his own data serie"
        content="One of our customer success managers will get in touch to discuss your needs and to give access to this Data Series"
        isWithInput
        inputPlaceholder="Please share any additional information relevant to the Data Series"
      />
      <ModulesListFooter
        title="Request a Data Series: "
        titleButton="Contact us"
        openSendRequest={openSendRequest}
        variant="outlined"
      />
    </React.Fragment>
  );
}

export default DataSeries;
