import fan from './fan';

export async function getMyMembership() {
  const result = await fan.get(`/users/me/teams`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
  if (result.data.data && result.data.data[0].membership !== 'No plan') {
    return result.data.data[0].membership;
  }
  return 'Ad-hoc';
}

export const getCurrentMembership = async () => {
  const newCampaignOrder = JSON.parse(localStorage.getItem('newCampaignOrder'));
  if (!newCampaignOrder) {
    const currentMembership = await getMyMembership();
    return currentMembership;
  }
  return newCampaignOrder.quoteOrder.membership;
};
