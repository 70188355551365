import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { PropTypes } from 'prop-types';
import CongratulationsDialogContainer from '../components/congratulationsDialog/congratulationsDialog';
import { calculateFeePerAudit } from '../quote/components/plans/services/newCampaignPricing.service';
import ContinueFooter from '../components/continueFooter/continueFooter';
import SendMail from '../../explore/components/sendMail';

import HASQUOTE from '../../../../setup/featureFlipping';

const styles = {
  contentBackground: {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 0 11px 5px rgba(234, 240, 246, 0.5)',
    height: '520px',
    overflowY: 'auto',
    marginTop: '20px',
    padding: 20,
  },
  container: {
    padding: 13,
  },
};

const dialogContentText = `Thank you for your interest! One of our customer success
managers will be in touch really soon. In the meantime don’t hesitate to contact us at
info@snooper-app.com!`;

class OrderSummary extends React.PureComponent {
  state = {
    isSuccessful: false,
    isOpen: false,
  };
  onOpenSuccesDialog = isSuccessful => {
    this.setState({ isSuccessful });
  };

  onGotoNextStep = () => {
    this.setState({ isOpen: true });
  };

  onCloseSendRequest = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isSuccessful, isOpen } = this.state;
    const { quoteOrder, onGotoPreviousStep } = this.props;
    const {
      mission,
      completionStrategy,
      storeTypes,
      startDate,
      endDate,
      numberOfLocations,
      multipleVisitPerStore,
      membership,
      price,
    } = quoteOrder;
    const feePerAudit = calculateFeePerAudit(quoteOrder.membership, quoteOrder.feePerAudit);
    return (
      <Grid container direction="column" style={styles.container}>
        <Typography variant="h5" testid="header" paragraph style={styles.marginLeft}>
          Summary
        </Typography>
        <Typography testid="explanation">
          You won’t be charged by submitting this expression of interest.
        </Typography>
        <Grid item xs={6} style={styles.contentBackground}>
          <Typography testid="mission" gutterBottom>
            Mission Objective: {mission}
          </Typography>
          <Typography gutterBottom> Start date: {startDate} </Typography>
          <Typography gutterBottom> End date: {endDate}</Typography>
          <Typography testid="channels" gutterBottom>
            Channels: {storeTypes.join(', ')}
          </Typography>
          <Typography testid="completionStrategy" gutterBottom>
            Completion methodology: {completionStrategy}
          </Typography>
          {HASQUOTE ? (
            <Typography gutterBottom>
              Fee per audit: $
              {feePerAudit ? (Math.round(feePerAudit * 10) / 10).toLocaleString() : '-'}
            </Typography>
          ) : null}
          <Typography gutterBottom>
            Number of audits: {numberOfLocations ? numberOfLocations.toLocaleString() : '-'} x
            {multipleVisitPerStore ? multipleVisitPerStore.toLocaleString() : '-'}
          </Typography>
          {HASQUOTE ? <Typography gutterBottom> Selected pack: {membership} </Typography> : null}
          {HASQUOTE ? (
            <Typography gutterBottom>
              Cost of mission: ${price ? price.toLocaleString() : '-'}
            </Typography>
          ) : null}
        </Grid>
        <CongratulationsDialogContainer
          isSuccessful={isSuccessful}
          dialogContent={dialogContentText}
        />
        <SendMail
          closeSendRequest={this.onCloseSendRequest}
          open={isOpen}
          content="One of our customer success managers will get in touch to finalise your order and answer any questions you might have. Note that you won’t be charged by sending this expression of interest"
          mailTitle="New Modules request"
          mailBody="A client wants to request a new module"
          title="Submit expression of interest"
          newOrder={{ ...quoteOrder, feePerAudit }}
          onOpenSuccesDialog={this.onOpenSuccesDialog}
        />
        <ContinueFooter
          testid="footer"
          onGotoNextStep={this.onGotoNextStep}
          onGotoPreviousStep={onGotoPreviousStep}
        />
      </Grid>
    );
  }
}
OrderSummary.propTypes = {
  quoteOrder: PropTypes.shape({
    mission: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    storeTypes: PropTypes.array.isRequired,
    completionStrategy: PropTypes.string.isRequired,
    numberOfLocations: PropTypes.number.isRequired,
    multipleVisitPerStore: PropTypes.number.isRequired,
    membership: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    feePerAudit: PropTypes.number.isRequired,
  }).isRequired,
  onGotoPreviousStep: PropTypes.func.isRequired,
};
export default OrderSummary;
