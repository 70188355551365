import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY_REFERENCE,
  CREATE,
  DELETE_MANY,
  GET_MANY,
} from 'react-admin';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    let serverResource = resource;

    if (resource.startsWith('industry-reports/')) {
      serverResource = 'industry-reports';
    }

    if (resource.startsWith('explore/industry-reports')) {
      serverResource = 'industry-reports';
    }
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          sort: field,
          order,
          skip: (page - 1) * perPage,
          limit: perPage,
        };
        url = `${apiUrl}/${serverResource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${serverResource}/${params.id}`;
        break;
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          sort: field,
          order,
          skip: (page - 1) * perPage,
          limit: perPage,
        };
        url = `${apiUrl}/${serverResource}?${stringify(query)}`;
        break;
      }
      case GET_MANY: {
        const query = {
          id: params.ids,
        };
        url = `${apiUrl}/${serverResource}?${stringify(query)}`;
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type) => {
    let { json } = response;
    json = {
      data: [],
      ...json,
    };
    switch (type) {
      case GET_LIST:
        // case GET_MANY_REFERENCE:
        return json;
      default:
        return json;
    }
  };

  const checkoutResponse = (type, params) => {
    let shoppingCartDB = JSON.parse(localStorage.getItem('shoppingCartDB'));
    let shoppingCartResult;
    switch (type) {
      case GET_LIST:
        shoppingCartResult = shoppingCartDB;
        break;
      case CREATE:
        shoppingCartDB = {
          ...shoppingCartDB,
          data: [
            ...shoppingCartDB.data,
            {
              id: shoppingCartDB.data.length + 1,
              orderItem: { ...params.data },
            },
          ],
          total: shoppingCartDB.data.length + 1,
        };
        shoppingCartResult = { data: { id: params.data.id } };
        break;
      case DELETE_MANY:
        shoppingCartDB = {
          ...shoppingCartDB,
          data: shoppingCartDB.data.filter(item => !params.ids.includes(item.id)),
          total: shoppingCartDB.data.filter(item => !params.ids.includes(item.id)).length,
        };
        shoppingCartResult = { data: params.ids };
        break;
      default:
        break;
    }
    localStorage.setItem(
      'shoppingCartDB',
      JSON.stringify({
        ...shoppingCartDB,
        data: [...shoppingCartDB.data],
        total: shoppingCartDB.data.length,
      }),
    );
    return shoppingCartResult;
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    if (!localStorage.getItem('shoppingCartDB')) {
      localStorage.setItem('shoppingCartDB', JSON.stringify({ data: [], total: 0 }));
    }
    if (resource === 'shopping-cart') {
      return checkoutResponse(type, params);
    }
    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params),
    );
  };
};
