import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import Edit from '../../../../../assets/images/Build.png';
import HASQUOTE from '../../../../../setup/featureFlipping';

const styles = {
  logo: {
    maxHeight: '20px',
    float: 'right',
  },
  storeTypes: { listStyleType: 'square', marginLeft: '20px' },
  padding: { padding: 0 },
  margin: { marginTop: 10 },
};

const pointer = {
  cursor: 'pointer',
};

class VerticalLinearStepper extends React.Component {
  getSteps = () => {
    if (HASQUOTE) {
      return ['Objective', 'Insights preview', 'Scope', 'Quote', 'Summary'];
    }
    return ['Objective', 'Insights preview', 'Scope', 'Summary'];
  };

  renderStoreType = storeTypes => (
    <div style={styles.margin}>
      {storeTypes.map((storeType, index) => (
        <li testid="storeTypes" key={index} style={styles.storeTypes}>
          {storeType}
        </li>
      ))}
    </div>
  );

  renderStepContent = (step, active, order) => {
    switch (step) {
      case 0:
        if (order.mission) return order.mission;
        return '';
      case 2:
        return (
          <React.Fragment>
            {order.startDate ? <p> Start: {order.startDate} </p> : null}
            {order.endDate ? <p> End: {order.endDate} </p> : null}
            {order.storeTypes.length > 0 ? (
              <div style={{ padding: 0 }} testid="storeTypes">
                Store type:
                {this.renderStoreType(order.storeTypes)}
              </div>
            ) : null}
          </React.Fragment>
        );
      default:
        return '';
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    return (
      <React.Fragment>
        <Stepper
          testid="stepper"
          activeStep={this.props.active}
          orientation="vertical"
          style={{ background: '#fff' }}
        >
          {steps.map((label, index) => (
            <Step testid="step" key={label}>
              <StepLabel onClick={() => this.props.onGotoStep(index)} style={pointer}>
                {label}
                {this.props.active > index ? (
                  <img src={Edit} alt="" className={classes.logo} />
                ) : null}
              </StepLabel>
              <StepContentStyled testid="stepContent">
                {this.renderStepContent(index, this.props.active, this.props.order)}
              </StepContentStyled>
            </Step>
          ))}
        </Stepper>
        {this.props.active === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
          </Paper>
        )}
      </React.Fragment>
    );
  }
}

const StepContentStyled = styled.ul`
  color: #a7a7a7;
`;

VerticalLinearStepper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  active: PropTypes.number.isRequired,
  onGotoStep: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(VerticalLinearStepper);
