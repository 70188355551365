import { AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT, AUTH_GET_PERMISSIONS, AUTH_ERROR } from 'react-admin';
import fan from '../services/fan';

const logout = () => {
  // localStorage.removeItem('token');
  // localStorage.removeItem('clientId');
  // localStorage.removeItem('shoppingCartDB');
  // localStorage.removeItem('role');
  // localStorage.removeItem('auth');
  // localStorage.removeItem('user');
  localStorage.clear();
};

const decodeURLParams = search => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const split = hash.indexOf('=');

    if (split < 0) {
      return Object.assign(params, {
        [hash]: null,
      });
    }

    const key = hash.slice(0, split);
    const val = hash.slice(split + 1);

    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

export const saveEncodedUserToLocalStorage = user => {
  if (!user) {
    localStorage.removeItem('user');
    return undefined;
  }

  const { id, role, email, firstName, lastName, createdAt, userName } = user;

  const minimalUserProfile = {
    id,
    role,
    email,
    firstName,
    lastName,
    createdAt,
    userName,
  };
  const encoded = Buffer.from(JSON.stringify(minimalUserProfile)).toString('base64');
  localStorage.setItem('user', encoded);

  return encoded;
};

export const readEncodedUserFromLocalStorage = () => {
  const encoded = localStorage.getItem('user');
  const decoded = encoded ? Buffer.from(encoded, 'base64').toString() : undefined;
  return decoded ? JSON.parse(decoded) : undefined;
};

export default (type, params) => {
  const login = async () => {
    const { username, password } = params;
    const payload = JSON.stringify({
      username,
      password,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const result = await fan.post(`/users/login`, payload, config);
    const { objectId, sessionToken } = result.data;
    localStorage.setItem('token', sessionToken);
    localStorage.setItem('clientId', objectId);

    const user = await fan.get(`/users/${objectId}`);
    const { role } = user.data.data;
    localStorage.setItem('role', role);

    /*
     * Temporary quick win: base64 encode and save in local storage.
     * Must be followed up with story https://snooper.atlassian.net/browse/SNOOP-1326
     */
    const userProfile = saveEncodedUserToLocalStorage(user.data.data);

    return {
      auth: result.data,
      user: userProfile,
    };
  };

  if (type === AUTH_LOGIN) {
    return login();
  }

  if (type === AUTH_LOGOUT) {
    logout();
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    const { token } = decodeURLParams(window.location.href);
    if (token !== undefined) {
      localStorage.setItem('token', token);
      const hash = window.location.hash.split('?')[0];
      window.location.hash = hash;
    }
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role'); // Constant to toggle permissions true or false
    return role === 'ADMIN' || role === 'REVIEWER' || role === 'SENIOR_REVIEWER'
      ? Promise.resolve(role)
      : Promise.reject();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  }

  return Promise.reject(Error('Unknown method'));
};
