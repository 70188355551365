import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Packs from '../../../../../../packs/packs';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  packs: {
    color: '#00C7FC',
    cursor: 'pointer',
  },
  topRight: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
};

export default function PacksDialog() {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Typography style={styles.packs} testid="seePacks" onClick={handleClickOpen}>
        see all packs
      </Typography>
      <Dialog
        testid="dialog"
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton onClick={handleClose} color="primary" style={styles.topRight}>
            <Close />
          </IconButton>
          <Packs testid="packs" />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
