import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const styles = () => ({
  dialog: {
    zIndex: 5,
  },
  title: {
    textAlign: 'center',
  },
  button: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#0071bc',
    borderRadius: 10,
    color: 'white',
    textDecoration: 'none',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 20,
    justifyContent: 'center',
  },
});

const mailTo = 'contact.shareofdisplay@wiser.com';
const subject = 'Full Access to Wiser Retail Intelligence';
const body = `Dear Wiser Team, I am writing to formally request full access to the Wiser's Retail Intelligence service. [Optional: Briefly explain the reason for your request here], Sincerely, [Your Name] [Your Job Title] [Your Department (if applicable)]`;

export const mailToHref = `mailto:${mailTo}?subject=${subject}&body=${body}`;

export const FreeTrialExpireModal = ({ classes, customerSubscriptions }) => {
  const [showExpireDialog, setShowExpireDialog] = useState({
    access: true,
    title: '',
  });

  const checkFreeTrailValidity = () => {
    if (!customerSubscriptions || customerSubscriptions.length === 0) return;

    const { startDate, endDate } = customerSubscriptions[0];

    const currentDate = new Date();

    if (currentDate < new Date(startDate)) {
      setShowExpireDialog({
        ...showExpireDialog,
        access: false,
        title: 'Your free trial has not started yet. Please contact us for assistance',
      });
    } else if (currentDate > new Date(endDate)) {
      setShowExpireDialog({
        ...showExpireDialog,
        access: false,
        title: 'Your free trial has ended',
      });
    }
  };

  useEffect(() => {
    checkFreeTrailValidity();

    const interval = setInterval(() => {
      const ele = document.getElementById('freeTrailExpireModal');
      if (ele) {
        ele.classList.remove('__web-inspector-hide-shortcut__');
      } else if (showExpireDialog.access !== false) {
        setShowExpireDialog({ access: true });
        clearInterval(interval);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      open={!showExpireDialog.access}
      testid="freeTrailExpireModal"
      id="freeTrailExpireModal"
      className={classes.dialog}
    >
      <DialogTitle className={classes.title} testid="freeTrailExpireModalTitle">
        {showExpireDialog.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText testid="freeTrailExpireModalMainContent">
          If you want to keep enjoying the Wiser Retail Intelligence platform or if you want to
          launch your own project, contact us and one of our customer success managers will get in
          touch to understand your needs.
        </DialogContentText>
        <br />
        <DialogContentText testid="freeTrailExpireModalSubContent">
          If after clicking ‘Email us’, an email doesn’t open, you can contact us at
          <span style={{ color: '#0071bc' }}> contact.shareofdisplay@wiser.com</span>.
        </DialogContentText>
        <DialogActions>
          <div className={classes.buttonContainer}>
            <a href={mailToHref} testid="freeTrailExpireModalButton" className={classes.button}>
              Email us
            </a>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

FreeTrialExpireModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  customerSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      customerId: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default withStyles(styles)(FreeTrialExpireModal);
