export const topics = [
  `Out of stocks`,
  `Promo Visibility`,
  `Perfect store`,
  `Pricing`,
  `Availability`,
  `Distribution`,
  `Gondola`,
  `Off-location`,
];

export const industries = [
  `Chocolate`,
  `Beverages`,
  `Milk & Dairy`,
  `Confectionery`,
  `Telco`,
  `Alcohol & Spirits`,
  `Grocery`,
  `General`,
];

export const types = [
  `In-store execution`,
  `In-store shopper insights`,
  `At-home shopper insights`,
  `Execution + shopper insights`,
  `Mystery shopper`,
];
