import PropTypes from 'prop-types';
import { useSegment, useSegmentIdentity } from '@wisersolutions/segment';
// eslint-disable-next-line import/no-named-as-default
import selectSegmentUserSettings from './segmentSelector';

const SegmentSnippet = () => {
  const runtimeEnvironment = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'LOCAL';
  let segmentId;
  if (runtimeEnvironment !== 'LOCAL') {
    segmentId = process.env.REACT_APP_SEGMENT_ID;
  }

  if (segmentId) useSegment(segmentId);
  return null;
};

export const SegmentSessionRoot = ({ user, customerSubscriptions }) => {
  const runtimeEnvironment = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'LOCAL';

  if (runtimeEnvironment !== 'LOCAL') {
    const segmentSettings = selectSegmentUserSettings(user, customerSubscriptions);
    useSegmentIdentity(segmentSettings);
  }
  return null;
};

SegmentSessionRoot.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ),
  customerSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      customerId: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

SegmentSessionRoot.defaultProps = {
  user: {},
  customerSubscriptions: [],
};

export default SegmentSnippet;
