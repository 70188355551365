import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

const styles = {
  font: { marginBottom: '-10px' },
};

export default function PhotoTagsFilter({
  photosTagsFilters,
  photosTagsSelectedValues,
  onHandleChange,
}) {
  const onHandleChangePhotoTagsFilter = filterName => selectedValues =>
    onHandleChange('photosTagsSelectedValues', {
      ...photosTagsSelectedValues,
      [filterName]: selectedValues,
    });

  if (Object.keys(photosTagsFilters).length > 0) {
    return (
      <div>
        <ListItem>
          <h4 style={styles.font}> Filter by tags </h4>
        </ListItem>
        {Object.keys(photosTagsFilters).map((key, index) => (
          <ListItem key={index}>
            <FilterSelect
              testid="tagFilter"
              segmentValues={[
                Array.from(photosTagsFilters[key]).sort((a, b) => a.localeCompare(b)),
              ]}
              segmentName={[key]}
              onHandleChangeFilter={onHandleChangePhotoTagsFilter(key)}
              isCheckpointFilter
              segmentValue={
                photosTagsSelectedValues[key] ? Array.from(photosTagsSelectedValues[key]) : []
              }
            />
          </ListItem>
        ))}
      </div>
    );
  }
  return <div />;
}

PhotoTagsFilter.propTypes = {
  photosTagsFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  photosTagsSelectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHandleChange: PropTypes.func.isRequired,
};
