import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ImageExamples from '../imageExamples/imageExamples';

const styles = {
  infoCard: {
    backgroundColor: '#ffffff',
    padding: 20,
    display: 'table',
    height: '100%',
  },
  container: { height: '100%' },
  bold: { fontWeight: 600 },
  htmlWrapper: {
    padding: 20,
    fontFamily: 'Roboto, SANS-SERIF',
    height: 'calc(100% - 60px)',
    overflow: 'auto',
  },
  summary: {
    display: 'table-row',
    height: 0,
  },
  photos: {
    height: '90%',
    overflow: 'auto',
    marginTop: '10px',
  },
  tags: {
    fontFamily: 'Roboto, SANS-SERIF',
    color: '#33475b',
    fontSize: 12,
  },
};

class MainInfo extends React.PureComponent {
  renderSummary = () => {
    const { record } = this.props;
    return (
      <Grid item xs={12} testid="summary" style={styles.summary}>
        <Typography gutterBottom paragraph style={styles.bold}>
          Summary
        </Typography>
        <Typography gutterBottom style={styles.tags}>
          Type: {record.types.join(', ')}
        </Typography>
        <Typography gutterBottom style={styles.tags} testid="topics">
          Topics: {record.topics.join(', ')}
        </Typography>
        <Grid
          item
          style={styles.tags}
          testid="description"
          dangerouslySetInnerHTML={{
            __html: record.tags
              ? record.tags.filter(tag => tag.name === 'period')[0].value || '-'
              : '-',
          }}
        />
        <Grid
          item
          style={styles.tags}
          testid="description"
          dangerouslySetInnerHTML={{
            __html: record.tags
              ? record.tags.filter(tag => tag.name === 'what you get')[0].value || '-'
              : '-',
          }}
        />
      </Grid>
    );
  };

  renderPhotoPreview = () => {
    const { record } = this.props;
    return record.imageExamples ? (
      <Grid item style={styles.photos}>
        <Typography gutterBottom paragraph style={styles.bold}>
          Photo gallery preview
        </Typography>
        <ImageExamples testid="imageExamples" record={record} />
      </Grid>
    ) : null;
  };

  renderDescription = () => {
    const { record } = this.props;
    return record.description ? (
      <Grid
        item
        testid="description"
        dangerouslySetInnerHTML={{ __html: record.description }}
        style={styles.htmlWrapper}
      />
    ) : null;
  };

  render() {
    return (
      <Grid container style={styles.container}>
        <Grid item xs={12} md={9} style={styles.container}>
          {this.renderDescription()}
        </Grid>
        <Grid item xs={12} md={3} container style={styles.infoCard}>
          {this.renderSummary()}
          {this.renderPhotoPreview()}
        </Grid>
      </Grid>
    );
  }
}

MainInfo.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MainInfo;
