export function sumTotalShoppingCart(ids, shoppingCartItems) {
  let total = 0;
  ids.forEach(id => {
    total += shoppingCartItems[id].orderItem.price;
    return total;
  });
  return total;
}

export default null;
