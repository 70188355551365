import fan from '../../../services/fan';

export async function getPhotos(campaignID) {
  const result = await fan.get(`/campaigns/${campaignID}/photos/gallery2`);
  return result.data;
}

export async function getCampaigns(campaignID) {
  const result = await fan.get(`/campaigns/${campaignID}`);
  return result.data.data;
}
export default null;
