import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import CampaignSubmissionPhotosContainer from '../../components/photoGallery/campaignSubmissionPhotos.container';
import isAbsentOrBlank from '../../../../../../../util/stringUtil';

const styles = {
  close: {
    color: '#636363',
    cursor: 'pointer',
    margin: '5px',
    fontSize: '32px',
    position: 'absolute',
    top: 3,
    right: 3,
  },
  gallery: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  paperAnchorDockedBottom: {
    zIndex: 1,
  },
};

const DrawerComponent = props =>
  !isAbsentOrBlank(props.record.metabaseId) ||
  !isAbsentOrBlank(props.record.metabaseDashboardUrl) ? (
    <Drawer
      testid="drawer"
      anchor="bottom"
      open={props.open}
      onClose={props.onToggleDrawer}
      variant="persistent"
      transitionDuration={700}
      classes={{
        paperAnchorDockedBottom: props.classes.paperAnchorDockedBottom,
        paperAnchorBottom: props.classes.paperAnchorDockedBottom,
      }}
    >
      <Grid container style={{ height: props.height }}>
        <CampaignSubmissionPhotosContainer
          {...props}
          testid="gallery"
          renderDrawer
          drawerHeight={props.height}
          onGalleryIsLoaded={props.onGalleryIsLoaded}
          onGalleryIsNotEmpty={props.onGalleryIsNotEmpty}
        />
        <Close testid="closeGallery" style={styles.close} onClick={props.onToggleDrawer} />
      </Grid>
    </Drawer>
  ) : null;

DrawerComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any),
  onGalleryIsLoaded: PropTypes.func.isRequired,
  onGalleryIsNotEmpty: PropTypes.func.isRequired,
};

DrawerComponent.defaultProps = {
  record: {},
};

export default withStyles(styles)(DrawerComponent);
