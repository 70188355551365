import React from 'react';
import { connect } from 'react-redux';
// import { WithPermissions, withDataProvider } from 'react-admin';
import { WithPermissions } from 'react-admin';
import { updatePhotosByCampaign } from '../../../../../../../data/actions';
import CampaignSubmissionPhotosPresenterWithUserContext from './campaignSubmissionPhotos.presenter';

const mapStateToProps = (state, ownProps) => ({
  photosByCampaign: state.photosByCampaign,
  campaign: state.admin.resources.campaigns.data[ownProps.id],
});

const mapDispatchToProps = dispatch => ({
  onPhotosByCampaign(...args) {
    dispatch(updatePhotosByCampaign(...args));
  },
});

const CampaignSubmissionPhotos = props => (
  <WithPermissions
    render={({ permissions }) => (
      <CampaignSubmissionPhotosPresenterWithUserContext {...props} permissions={permissions} />
    )}
  />
);

const CampaignSubmissionPhotosContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignSubmissionPhotos);
// )(withDataProvider(CampaignSubmissionPhotos));

export default CampaignSubmissionPhotosContainer;
