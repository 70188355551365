import fan, { Method } from '../../../services/fan';

const DownloadService = {
  config: {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  },

  getFileNameFromContentDispositionHeader(header) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(header);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }

    return null;
  },

  downloadFile(blob, fileName) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.testId = 'downloadZipFileHiddenLink';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  },

  async postDataToServer(payload, campaignID) {
    const result = await fan.post(`/campaigns/${campaignID}/photos/zip`, payload, this.config);
    return result;
  },

  async downloadAndZipPhotos(payload, method, url) {
    try {
      let result;

      if (method === Method.POST) {
        result = await fan.post(url, payload, this.config);
      }

      if (method === Method.GET) {
        result = await fan.get(url, this.config);
      }

      const responseHeaders = result.headers;

      const fileName = this.getFileNameFromContentDispositionHeader(
        responseHeaders['content-disposition'],
      );

      if (!fileName) {
        throw Error(
          'The service cannot process this request at the moment. Please contact support. [content-disposition: file_name===null]',
        );
      }

      const blob = new Blob([result.data], { type: result.headers['content-type'] });

      if (blob) {
        this.downloadFile(blob, fileName);
      }
    } catch (error) {
      if (error.status === 403) {
        throw Error('CampaignPhotoDownloadIsNotAllowed');
      }
      throw Error(error.message);
    }
  },
};

export default DownloadService;
