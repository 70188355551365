import React, { Children, cloneElement } from 'react';
import ReactTable from 'react-table';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './table-layout.css';

import EmptyLogo from '../../../../assets/images/logos/Empty.png';

const style = {
  tableField: { color: 'rgba(0,0,0,0.87', fontSize: '12px' },
  table: {
    fontSize: '12px',
    fontFamily: 'roboto, sans-serif',
    textTransform: 'lowercase',
    border: 'unset',
    marginLeft: '25px',
    marginRight: '25px',
    height: '65vh',
  },
  cursor: 'pointer',
  empty: {
    height: 150,
  },
};

export class ReactTableDatagridPresenter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSorted: [
        {
          id: props.currentSort.field,
          desc: props.currentSort.order === 'DESC',
        },
      ],
    };
  }

  setColumn = child => {
    const { props } = child;
    let column = {
      Header: props.label || props.title ? props.label || props.title : '',
      accessor: props.sourceValue ? props.sourceValue : props.source,
      resizable: true,
    };
    if (props.width) {
      column = { ...column, width: props.width };
    }
    if (props.fixed) {
      column = { ...column, fixed: props.fixed };
    }
    column = {
      ...column,
      Cell: item =>
        cloneElement(Children.only(child), {
          record: item.original,
          id: item.original.id,
        }),
    };
    return column;
  };

  updateSort = sort => {
    const { setSort } = this.props;
    const field = sort[0].id;
    this.setState({
      currentSorted: [
        {
          id: field,
          desc: sort[0].desc,
        },
      ],
    });
    return setSort(field);
  };

  render() {
    const { data, ids, loadedOnce, isLoading, perPage, children, rowClick, history } = this.props;
    const { currentSorted } = this.state;
    if (loadedOnce === false) {
      return null;
    }
    return (
      <ReactTable
        testid="table"
        data={loadedOnce === true ? ids.map(id => data[id]) : Object.values(data)}
        minRows={10}
        className={rowClick ? classnames('-highlight', '-striped') : '-striped'}
        loading={isLoading}
        pageSize={perPage}
        showPagination={false}
        style={rowClick ? { ...style.table, cursor: 'pointer' } : style.table}
        multiSort={false}
        sorted={currentSorted}
        onSortedChange={sort => this.updateSort(sort)}
        columns={children.map(child => this.setColumn(child))}
        PadRowComponent={<a href> &nbsp; </a>}
        noDataText={
          <div>
            <img src={EmptyLogo} alt="" style={style.empty} />
          </div>
        }
        getTdProps={(state, rowInfo) => ({
          onClick: (e, handleOriginal) => {
            if (handleOriginal) {
              handleOriginal();
            }
            if (rowClick && rowInfo) {
              history.push(`/campaigns/${rowInfo.original.id}/show`);
            }
          },
        })}
      />
    );
  }
}

ReactTableDatagridPresenter.propTypes = {
  data: PropTypes.shape({}).isRequired,
  ids: PropTypes.shape([]).isRequired,
  loadedOnce: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSort: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  rowClick: PropTypes.bool,
  history: PropTypes.func.isRequired,
};

ReactTableDatagridPresenter.defaultProps = {
  rowClick: false,
};

export default withRouter(ReactTableDatagridPresenter);
