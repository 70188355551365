import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import mailRequest from './sendCampaignTypeMail.service';
import { Event } from '../../../../setup/analytics';

import SendLogo from '../../../../assets/images/logos/Send.png';

const styles = {
  root: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'grey',
  },
  checkedLogo: {
    height: 74,
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#33475b',
  },
  content: {
    color: '#33475b',
    fontSize: '14px',
  },
  button: {
    width: '121px',
    height: '41px',
    borderRadius: '4px',
    backgroundColor: '#00c7fc',
    fontSize: '12px',
    color: '#fff',
    textTransform: 'unset',
  },
  loader: {
    width: '13%',
    height: 'auto',
    color: 'white',
  },
  textField: {
    width: '100%',
  },
  warning: {
    color: '#f38c63',
    margin: 10,
    textAlign: 'center',
  },
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={SendLogo} alt="" style={styles.checkedLogo} testid="checkedLogo" />
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function SendMail({
  open,
  closeSendRequest,
  campaignType,
  content,
  isWithInput,
  extraInfo,
  mailBody,
  mailTitle,
  title,
  newOrder,
  inputPlaceholder,
  onOpenSuccesDialog,
}) {
  const [isLoading, setLoading] = React.useState(false);
  const [clientInput, setClientInput] = React.useState(null);
  const [isError, setErrorMessage] = React.useState(null);

  const handleSendMail = async () => {
    Event('Mailing', 'Send request, Build own', ` ${campaignType}`);
    setLoading(true);
    try {
      await mailRequest({ clientInput, campaignType, extraInfo, mailTitle, mailBody, newOrder });
      setLoading(false);
      if (onOpenSuccesDialog) {
        onOpenSuccesDialog(true);
      }
      setErrorMessage(false);
      closeSendRequest();
    } catch (e) {
      setLoading(false);
      Sentry.captureException(e);
      setErrorMessage(true);
    }
  };

  const handleChange = event => {
    setClientInput(event.target.value);
  };

  return (
    <div>
      <Dialog
        testid="dialog"
        onClose={closeSendRequest}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeSendRequest} />
        <MuiDialogContent style={styles.root}>
          <Typography gutterBottom style={styles.title}>
            {title}
          </Typography>
          <Typography gutterBottom style={styles.content} testid="content">
            {content}
          </Typography>
          {isWithInput ? (
            <TextField
              testid="inputField"
              id="standard-multiline-static"
              placeholder={inputPlaceholder}
              multiline
              rows="4"
              onChange={handleChange}
              value={clientInput}
              margin="normal"
              variant="outlined"
              style={styles.textField}
            />
          ) : null}

          {newOrder ? (
            <TextField
              testid="inputField"
              id="standard-multiline-static"
              label="Additional comments"
              multiline
              rows="4"
              onChange={handleChange}
              value={clientInput}
              margin="normal"
              variant="outlined"
              style={styles.textField}
            />
          ) : null}
        </MuiDialogContent>
        <MuiDialogActions style={{ justifyContent: 'center' }}>
          <Button
            testid="confirm"
            autoFocus
            color="primary"
            style={styles.button}
            onClick={handleSendMail}
          >
            {isLoading ? (
              <Grid container justify="center" alignItems="center">
                Sending...
                <CircularProgress testid="loader" style={styles.loader} />
              </Grid>
            ) : (
              'Continue'
            )}
          </Button>
        </MuiDialogActions>
        {isError === true ? (
          <Typography style={styles.warning}>
            We are experiencing a network issue, please try again later or contact us
          </Typography>
        ) : null}
      </Dialog>
    </div>
  );
}

SendMail.propTypes = {
  open: PropTypes.bool.isRequired,
  closeSendRequest: PropTypes.func.isRequired,
  campaignType: PropTypes.string,
  content: PropTypes.string.isRequired,
  isWithInput: PropTypes.bool,
  extraInfo: PropTypes.string,
  mailTitle: PropTypes.string.isRequired,
  mailBody: PropTypes.string.isRequired,
  title: PropTypes.string,
  newOrder: PropTypes.shape({}),
  inputPlaceholder: PropTypes.string,
  onOpenSuccesDialog: PropTypes.func,
};

SendMail.defaultProps = {
  isWithInput: false,
  extraInfo: null,
  campaignType: null,
  title: 'Send your request to the Snooper team',
  newOrder: null,
  inputPlaceholder:
    'Please specify the questions you’d like to see answered and the key selling period you’re interested in (e.g. Christmas, Easter, Boxing Day, Valentine’s Day)',
  onOpenSuccesDialog: null,
};
