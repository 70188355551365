import { storeTypePriceImpact, completionStrategyPriceImpact } from '../../variables/variables';

export const calculateFeeVisitsPriceImpact = storeTypes =>
  Math.max(
    0,
    ...storeTypePriceImpact
      .filter(storeType => storeTypes.includes(storeType.value))
      .map(storeType => storeType.impact),
  );

export const calculateCompletionStrategyImpact = completionStrategy =>
  Math.max(
    0,
    ...completionStrategyPriceImpact.filter(s => completionStrategy === s.value).map(s => s.impact),
  );
export default null;
