import React from 'react';
import { addField } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined } from '@material-ui/icons';

const styles = {
  root: {
    height: '35px',
    width: '180px',
  },
};

const CampaignFilter = addField(({ input, classes }) => (
  <TextField
    {...input}
    variant="outlined"
    placeholder="Name"
    margin="normal"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchOutlined />
        </InputAdornment>
      ),
      classes: { input: classes.root },
    }}
  />
));

export default withStyles(styles)(CampaignFilter);
