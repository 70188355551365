import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import NumberFormatField from '../../../../components/numberFormatField/numberFormatField';

const styles = {
  error: { color: '#f6675e' },
  marginLocations: { marginBottom: 10 },
  storeSelection: {
    marginLeft: '15px',
  },
};

export default function NumberOfRespondents({
  quoteOrder,
  everythingFilledIn,
  calculateIsClicked,
  handleChangeVisits,
}) {
  const { multipleVisitPerStore } = quoteOrder;
  return (
    <Grid container alignItems="center" style={styles.marginLocations}>
      <Grid item xs={4} style={styles.storeSelection}>
        <Typography testid="title">Number of respondents per locations: </Typography>
      </Grid>
      <Grid item>
        <FormControl>
          <NumberFormatField
            testid="numberOfRespondents"
            label={null}
            value={multipleVisitPerStore}
            onChange={handleChangeVisits('multipleVisitPerStore', 'numberOfLocations')}
            variant="outlined"
            error={multipleVisitPerStore <= 0 && !everythingFilledIn && calculateIsClicked}
            InputLabelProps={{
              shrink: true,
              height: '2.18rem',
            }}
            fullWidth
            margin="normal"
          />
          {multipleVisitPerStore <= 0 && !everythingFilledIn && calculateIsClicked ? (
            <FormHelperText testid="error" style={styles.error} id="component-error-text">
              Please fill in
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
    </Grid>
  );
}

NumberOfRespondents.propTypes = {
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  everythingFilledIn: PropTypes.bool.isRequired,
  calculateIsClicked: PropTypes.bool.isRequired,
  handleChangeVisits: PropTypes.func.isRequired,
};
