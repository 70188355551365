export const calculateReportMembershipPrice = (price, membership) => {
  switch (membership) {
    case 'Ad-hoc':
      return price;
    case 'Silver':
      return formatAsPercentage(price, 5);
    case 'Gold':
      return formatAsPercentage(price, 10);
    case 'Platinum':
      return formatAsPercentage(price, 15);
    default:
      return price;
  }
};

const formatAsPercentage = (value, percent) => value - (value * percent) / 100;

export const getPackDiscount = membership => {
  switch (membership) {
    case 'Ad-hoc':
      return 'n.a.';
    case 'Silver':
      return '-5%';
    case 'Gold':
      return '-10%';
    case 'Platinum':
      return '-15%';
    default:
      return 'n.a.';
  }
};

export default null;
