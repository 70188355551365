import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
    right: 0,
    height: 60,
    border: '0.5px solid rgb(200, 211, 225)',
  },
  buttonPrevious: {
    width: '121px',
    height: '40px',
    borderRadius: '4px',
    fontSize: '12px',
    textTransform: 'unset',
    marginRight: 20,
  },
  buttonNext: {
    width: '121px',
    height: '40px',
    borderRadius: '4px',
    fontSize: '12px',
    color: '#fff',
    textTransform: 'unset',
    marginRight: 20,
  },
};

export default function ContinueFooter({ onGotoNextStep, onGotoPreviousStep }) {
  return (
    <Grid container justify="flex-end" alignItems="center" style={styles.container} spacing={8}>
      <Grid item>
        <Button
          testid="buttonPrevious"
          variant="outlined"
          color="secondary"
          style={styles.buttonPrevious}
          onClick={onGotoPreviousStep}
        >
          Previous
        </Button>
      </Grid>
      <Grid item>
        <Button
          testid="buttonNext"
          variant="contained"
          color="secondary"
          style={styles.buttonNext}
          onClick={onGotoNextStep}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
}

ContinueFooter.propTypes = {
  onGotoNextStep: PropTypes.func.isRequired,
  onGotoPreviousStep: PropTypes.func.isRequired,
};
