import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PropTypes } from 'prop-types';
import { withStyles, Divider, Typography, Grid } from '@material-ui/core';
import Checked from '../../../../../assets/images/checked.png';
import Cancel from '../../../../../assets/images/cancel.png';
import MedalIcon from '../../../../../assets/images/Medal.png';
import RocketIcon from '../../../../../assets/images/Rocket.png';
import StarIcon from '../../../../../assets/images/Star.png';
import FlashIcon from '../../../../../assets/images/Flash.png';
import MissionPackRow from '../missionPackRow/missionPackRow';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: 'unset !important',
  },
  tableHeadHeight: {
    height: '10vh',
  },
  logo: {
    maxHeight: '25px',
  },
  membership: {
    fontSize: '19px',
    fontWeight: '600',
  },
  price: {
    fontSize: '19px',
    fontWeight: '600',
  },
  subscriptionTerm: {
    fontSize: '10px',
    opacity: '0.8',
  },
  free: {
    color: '#4bb78a',
    textTransform: 'uppercase',
  },
  percentage: {
    fontSize: '19px',
    fontWeight: '600',
  },
  center: {
    textAlign: 'center',
    marginBottom: '10px',
    height: '15px',
  },
};

class PackageTable extends React.PureComponent {
  renderIcon = isValue =>
    isValue ? (
      <img testid="MedalIcon" src={Checked} alt="" style={styles.logo} />
    ) : (
      <img testid="MedalIcon" src={Cancel} alt="" style={styles.logo} />
    );

  renderOmnibus = isValue =>
    isValue ? (
      <Typography testid="omnibus"> {isValue} </Typography>
    ) : (
      <img testid="MedalIcon" src={Cancel} alt="" style={styles.logo} />
    );

  renderPackIcon = pack => {
    switch (pack) {
      case 'Ad-hoc':
        return <img testid="FlashIcon" src={FlashIcon} alt="" style={styles.logo} />;
      case 'Silver':
        return <img testid="StarIcon" src={StarIcon} alt="" style={styles.logo} />;
      case 'Gold':
        return <img testid="MedalIcon" src={MedalIcon} alt="" style={styles.logo} />;
      case 'Platinum':
        return <img testid="RocketIcon" src={RocketIcon} alt="" style={styles.logo} />;
      default:
        return null;
    }
  };

  renderHeader = () => (
    <Grid direction="column">
      <Typography style={styles.membership}>{this.props.packageType.membership}</Typography>
      {this.renderPackIcon(this.props.packageType.membership)}
      <Typography testid="price" style={styles.price}>
        {this.props.packageType.membership === 'Ad-hoc'
          ? '-'
          : `$${this.props.packageType.price.toLocaleString()}`}
      </Typography>
      <Typography style={styles.subscriptionTerm}>
        ({this.props.packageType.subscriptionTerm})
      </Typography>
    </Grid>
  );
  renderPercentage = isValue =>
    isValue > 0 ? (
      <Typography style={styles.percentage}> -{isValue}%</Typography>
    ) : (
      <Typography style={styles.percentage}> - </Typography>
    );

  render() {
    const { classes, packageType, packSelected, onSelectPack } = this.props;
    return (
      <React.Fragment>
        <div style={styles.center}>
          <Typography>
            {packageType.membership === 'Ad-hoc' ? 'Your current plan' : 'Upgrade'}
          </Typography>
        </div>
        <Paper>
          <Table
            testid="table"
            size="small"
            style={{
              border: packSelected ? '1px solid #03c7fc' : 'none',
            }}
            onClick={onSelectPack(packageType)}
          >
            <TableHead>
              <TableRow selected classes={{ root: classes.tableHeadHeight }}>
                <TableCell classes={{ root: classes.tableCell }} align="center">
                  {this.renderHeader()}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <MissionPackRow>{} </MissionPackRow>
              <MissionPackRow>{this.renderPercentage(packageType.feePerAudit)} </MissionPackRow>
              <MissionPackRow>
                {this.renderIcon(packageType.realTimeOnlineDashboard)}
              </MissionPackRow>
              <MissionPackRow>{this.renderIcon(packageType.digitalOnlineLibrary)}</MissionPackRow>
              <MissionPackRow>{this.renderIcon(packageType.rawDataExtract)} </MissionPackRow>
              <MissionPackRow>{this.renderIcon(packageType.chartsExtract)} </MissionPackRow>
              <MissionPackRow>
                {packageType.fastTrackFee === 1 ? (
                  <Typography style={styles.free}> free </Typography>
                ) : (
                  <Typography> ${packageType.fastTrackFee} </Typography>
                )}
              </MissionPackRow>
              <MissionPackRow>${packageType.insightPresentation.toLocaleString()}</MissionPackRow>
              <MissionPackRow>
                <Divider light variant="middle" />
              </MissionPackRow>
              <MissionPackRow>{this.renderIcon(packageType.bestDisplay)} </MissionPackRow>
              <MissionPackRow style={classes.tableCell}>
                {this.renderPercentage(packageType.allSyndicatedReports)}
              </MissionPackRow>
              <MissionPackRow>{this.renderOmnibus(packageType.omnibus)} </MissionPackRow>
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

PackageTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  packageType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    membership: PropTypes.string.isRequired,
    subscriptionTerm: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    feePerAudit: PropTypes.number.isRequired,
    realTimeOnlineDashboard: PropTypes.bool.isRequired,
    digitalOnlineLibrary: PropTypes.bool.isRequired,
    rawDataExtract: PropTypes.bool.isRequired,
    chartsExtract: PropTypes.bool.isRequired,
    fastTrackFee: PropTypes.number.isRequired,
    insightPresentation: PropTypes.number.isRequired,
    bestDisplay: PropTypes.bool.isRequired,
    allSyndicatedReports: PropTypes.number.isRequired,
    omnibus: PropTypes.string.isRequired,
  }).isRequired,
  packSelected: PropTypes.bool.isRequired,
  onSelectPack: PropTypes.func.isRequired,
};

export default withStyles(styles)(PackageTable);
