import React from 'react';
import PropTypes from 'prop-types';
import { ShowButton } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { Event } from '../../../../../../../setup/analytics';

const styles = {
  card: {
    borderRadius: '4px',
    boxShadow: '0 0 11px 5px rgba(234, 240, 246, 0.5)',
    backgroundColor: '#ffffff',
    height: '440px',
    width: '280px',
    padding: '15px',
  },
  button: {
    width: '121px',
    height: '41px',
    borderRadius: '4px',
    backgroundColor: '#00c7fc',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#33475b',
  },
  type: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#00c7fc',
  },
  gridHeader: {
    height: '80px',
  },
  evenChip: {
    borderRadius: '10px',
    backgroundColor: '#107783',
    height: '21px',
    color: '#fff',
  },
  oddChip: {
    borderRadius: '10px',
    backgroundColor: '#ef3d79',
    height: '21px',
    color: '#fff',
  },
  strong: {
    fontWeight: 600,
    color: '#33475b',
  },
  content: {
    height: '180px',
  },
};
class ReportCard extends React.PureComponent {
  renderNoWrapParagraph = input => {
    if (input.length > 250) {
      return `${input.substr(0, 250)}...`;
    }
    return input;
  };

  renderOpenButton = () => {
    const { industryReportType, basePath } = this.props;
    if (industryReportType.hasBeenBought) {
      return (
        <ShowButton
          testid="openCampaign"
          label="Open"
          color="primary"
          variant="contained"
          resource="campaigns"
          basePath="/campaigns"
          style={styles.button}
          record={industryReportType}
        />
      );
    }
    return (
      <ShowButton
        testid="showReportButton"
        label="Read more"
        icon={null}
        color="primary"
        variant="contained"
        onClick={() =>
          Event('Navigation', `Select: ${industryReportType.name}`, 'Explore, Industry reports')
        }
        resource="industry-reports"
        style={styles.button}
        basePath={basePath}
        record={industryReportType}
      />
    );
  };

  renderChips = () => {
    const { industryReportType } = this.props;
    const { types } = industryReportType;
    return types.map((type, index) => (
      <Grid item>
        <Chip
          testid="type"
          label={type}
          style={index % 2 === 0 ? styles.evenChip : styles.oddChip}
        />
      </Grid>
    ));
  };

  render() {
    const { industryReportType } = this.props;
    return (
      <Grid
        container
        spacing={16}
        style={styles.card}
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid item style={styles.gridHeader}>
          <Typography testid="title" style={styles.header}>
            {industryReportType.name}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={8}>
          {this.renderChips()}
        </Grid>
        <Grid item>
          <Typography testid="industry">
            <span style={styles.strong}> Category:</span> {industryReportType.industry}
          </Typography>
          <Typography testid="topics">
            <span style={styles.strong}> Topics:</span>{' '}
            {industryReportType.topics ? industryReportType.topics.join(', ') : '-'}
          </Typography>
        </Grid>

        <Grid item style={styles.content}>
          <Typography testid="content">
            {this.renderNoWrapParagraph(industryReportType.shortDescription)}
          </Typography>
        </Grid>
        <Grid item container justify="center" alignItems="flex-end">
          <Grid>{this.renderOpenButton()}</Grid>
        </Grid>
      </Grid>
    );
  }
}

ReportCard.propTypes = {
  industryReportType: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    industry: PropTypes.string,
    topics: PropTypes.array,
    types: PropTypes.string,
    category: PropTypes.string,
    price: PropTypes.number,
    imageUrl: PropTypes.number,
    shortDescription: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    childrenIds: PropTypes.array,
    hasBeenBought: PropTypes.bool,
  }),
  basePath: PropTypes.string.isRequired,
};

ReportCard.defaultProps = {
  industryReportType: PropTypes.objectOf({
    id: 0,
    name: '',
    industry: '',
    syndicatedReportTopic: '',
    types: '',
    category: '',
    shortDescription: '',
    imageUrl: '',
    startDate: '',
    endDate: '',
    childrenIds: null,
  }),
};

export default ReportCard;
