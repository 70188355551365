import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportCard from './components/reportCard/reportCard';
import EmptyList from './components/emptyList/emptyList';

const styles = {
  middle: {
    position: 'fixed',
    top: '45%',
    left: '45%',
    width: '15%',
  },
  container: { padding: '4%', backgroundColor: '#f5f8fa' },
};

const ReportCardList = ({ ids, data, basePath, total, isLoading, ReportCardComponent }) => {
  const renderReportList = () =>
    total === 0 ? (
      <EmptyList testid="emptyList" />
    ) : (
      <Grid container alignItems="center" spacing={32} style={styles.container}>
        {ids.map(id => (
          <Grid item xs={12} md={3}>
            <ReportCardComponent
              key={id}
              testid="reportCard"
              industryReportType={data[id]}
              basePath={basePath}
            />
          </Grid>
        ))}
      </Grid>
    );

  return isLoading ? (
    <div style={styles.middle}>
      <CircularProgress testid="loader" />
    </div>
  ) : (
    renderReportList()
  );
};

ReportCardList.propTypes = {
  data: PropTypes.shape(PropTypes.any),
  basePath: PropTypes.string,
  ids: PropTypes.arrayOf(PropTypes.any),
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ReportCardComponent: PropTypes.elementType,
};

ReportCardList.defaultProps = {
  data: {},
  ids: [],
  basePath: '',
  ReportCardComponent: ReportCard,
};

export default ReportCardList;
