import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const styles = {
  topBottom: { fontSize: '11px', color: 'rgba(0, 0, 0, 0.54)' },
  sort: { fontSize: '11px', color: 'rgba(0, 0, 0, 0.54)', marginLeft: 4 },
};

export default function SortByCreatedAt({ isMostRecentAtBottom, onHandleChange, onApplyFilter }) {
  const onSortByCreatedAt = async () => {
    await onHandleChange('isMostRecentAtBottom', !isMostRecentAtBottom);
    onApplyFilter();
  };

  return (
    <React.Fragment>
      <ListItem>
        <h4 style={styles.font}> Sort by </h4>
        <TableSortLabel
          testid="sort"
          active
          direction={isMostRecentAtBottom ? 'desc' : 'asc'}
          onClick={onSortByCreatedAt}
        >
          <span style={styles.sort}>most recent</span>
        </TableSortLabel>
        {isMostRecentAtBottom ? (
          <span style={styles.topBottom}> bottom </span>
        ) : (
          <span style={styles.topBottom}> top </span>
        )}
      </ListItem>
    </React.Fragment>
  );
}

SortByCreatedAt.propTypes = {
  isMostRecentAtBottom: PropTypes.bool.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
};
