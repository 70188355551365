import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  infoIcon: { maxHeight: 15, color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' },
  bold: {
    fontWeight: 500,
  },
  italic: {
    fontStyle: 'italic',
  },
  padding: {
    padding: 8,
  },
  background: {
    backgroundColor: '#f9fbfc',
  },
  transparant: {
    opacity: '1 !important',
  },
};

const CompletionMethodologyTitle = () => (
  <Grid style={styles.background}>
    <Typography gutterBottom style={styles.padding}>
      <span style={styles.bold}> Random sample (Recommended): </span> our community can visit any
      store within the channels you selected until your target is reached. This maximises completion
      speed and minimises any selection bias. This is your most cost efficient option.
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      <span style={styles.bold}> Predefined list of stores: </span> our community must visit a
      specific list of stores within the channels selected. This will have an impact on the cost of
      your mission.
    </Typography>
    <Typography gutterBottom style={styles.padding}>
      <span style={styles.bold}> Full network: </span> our community must visit all locations within
      the selected channels. This will have an impact on the cost of your mission.
    </Typography>
  </Grid>
);

export const ChannelsTitle = () => (
  <Grid style={styles.background}>
    <Typography testid="channelsTitle" gutterBottom style={styles.listItems}>
      We can provide lists of locations based on your needs. However, if you prefer to use your own
      list, we can easily use the one you will provide.
    </Typography>
  </Grid>
);

const ScopeDataTitle = () => (
  <Grid style={styles.background}>
    <Typography gutterBottom style={styles.listItems}>
      A <span style={styles.italic}> ‘Fast track’ </span> fee applies when the start of your mission
      is within 2 days of your request.
    </Typography>
    <Typography gutterBottom style={styles.listItems}>
      A <span style={styles.italic}> ‘Fast completion’ </span> fee applies when the difference
      between the start and the end of your mission is less than 7 days.
    </Typography>
  </Grid>
);

const CustomTooltip = ({ title }) => (
  <StyledTooltip disableTouchListener title={title} interactive>
    <InfoIcon style={styles.infoIcon} />
  </StyledTooltip>
);

CustomTooltip.propTypes = {
  title: PropTypes.shape(PropTypes.any),
};

CustomTooltip.defaultProps = {
  title: '',
};

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f9fbfc',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  popper: {
    opacity: '1',
  },
}))(Tooltip);

export const CompletionMethodologyTooltip = () => (
  <CustomTooltip testid="tooltip" title={<CompletionMethodologyTitle />} />
);
export const ScopeDataTooltip = () => <CustomTooltip title={<ScopeDataTitle />} />;
export const ChannelsTooltip = () => <CustomTooltip title={<ChannelsTitle />} />;
