import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { Event } from '../../../../setup/analytics';

const styles = {
  card: {
    maxWidth: '250px',
    height: '380px',
    borderRadius: '4px',
    boxShadow: '0 0 11px 5px rgba(234, 240, 246, 0.5)',
    backgroundColor: '#ffffff',
    padding: 10,
  },
  button: {
    width: '121px',
    height: '41px',
    borderRadius: '4px',
    backgroundColor: '#00c7fc',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#33475b',
  },
  logo: {
    width: '64.7px',
    height: '64.7px',
  },
  content: { height: '35%' },
};

export default function ModulesCard({ content }) {
  const handleClick = () => {
    localStorage.setItem('visited', 'yes');
    Event('Navigation', 'Choose campaign', 'Explore all');
  };
  return (
    <Grid
      item
      xs={12}
      md={3}
      container
      direction="column"
      style={styles.card}
      alignItems="center"
      justify="space-evenly"
    >
      <Grid item>
        <Typography>
          <img testid="logo" src={content.logo} alt="" style={styles.logo} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography testid="header" style={styles.header}>
          {content.title}
        </Typography>
      </Grid>
      <Grid item style={styles.content}>
        <Typography testid="div">{content.content}</Typography>
      </Grid>
      <Grid item>
        <Button
          testid="exploreButton"
          style={styles.button}
          href={content.link}
          onClick={handleClick}
        >
          Explore
        </Button>
      </Grid>
    </Grid>
  );
}

ModulesCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    logo: PropTypes.any,
    link: PropTypes.string,
  }).isRequired,
};
