import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

const styles = {
  font: { marginBottom: '-10px' },
};

export default function LocationFilter({ locationQuery, onHandleChange, onApplyFilter }) {
  const onHandleChangeStoreName = ({ target }) => {
    onHandleChange('locationQuery', target.value);
    onApplyFilter();
  };

  return (
    <React.Fragment>
      <ListItem>
        <h4 style={styles.font}> Search by mission</h4>
      </ListItem>
      <ListItem>
        <TextField
          testid="filterMission"
          id="standard-search"
          label="Location"
          value={locationQuery}
          type="search"
          margin="none"
          onChange={onHandleChangeStoreName}
          InputLabelProps={{
            style: {
              fontSize: '11px',
            },
          }}
        />
      </ListItem>
    </React.Fragment>
  );
}

LocationFilter.propTypes = {
  locationQuery: PropTypes.string.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
};
