import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { sendInvoice } from '../../services/order.service';

const styles = theme => ({
  buttonContinue: { width: '15vw' },
  dialogMail: { zIndex: 10, position: 'absolute' },
  buttonInterestInfo: { fontSize: 10 },
  error: { color: theme.palette.error.dark },
  title: {
    textAlign: 'center',
    fontSize: '19px',
  },
  contact: {
    marginTop: '20px',
  },
  loader: {
    width: '14%',
  },
});

function RequestMailOrderPresenter({ classes, ...props }) {
  const [values, setValues] = React.useState({
    open: false,
    email: '',
    openText: '',
    isClicked: false,
    phoneNumber: '',
    isLoading: false,
  });

  function handleClickOpen() {
    setValues({ open: true });
  }

  function handleClose() {
    setValues({ open: false });
  }

  async function handleSendMail() {
    setValues(prevState => ({ ...prevState, isClicked: true }));
    if (values.email && values.email.includes('@')) {
      const orderItems = Object.values(props.order);
      const newOrder = {
        openText: values.openText,
        emailInput: values.email,
        to: null,
        phoneNumber: values.phoneNumber,
        clientId: localStorage.getItem('clientId'),
        orderItems: orderItems.map(orderItem => orderItem.orderItem),
      };
      setValues(prevState => ({ ...prevState, isLoading: true }));
      await sendInvoice(newOrder);
      props.onHandleMessage(true);
      handleClose();
    }
    return null;
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div>
      <Grid container direction="column" spacing={8}>
        <Grid item xs={3}>
          <Button
            testid="placeOrder"
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            className={classes.buttonContinue}
          >
            Send purchase order
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.buttonInterestInfo}>
            (This is equivalent to sending us an official Purchase Order. An invoice will be created
            and send to you by email.)
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        testid="dialog"
        open={values.open}
        onClose={handleClose}
        className={classes.dialogMail}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          Request order
        </DialogTitle>
        <DialogContent>
          <TextField
            testid="mailForm"
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            onChange={handleChange('email')}
            fullWidth
            required
            variant="outlined"
          />
          {(values.isClicked && values.email && !values.email.includes('@')) ||
          (values.isClicked && !values.email) ? (
            <FormHelperText
              testid="isValidError"
              className={classes.error}
              id="component-error-text"
            >
              Please fill in valid email
            </FormHelperText>
          ) : null}
          <TextField
            testid="phoneNumber"
            margin="dense"
            id="name"
            label="Phone number"
            type="phoneNumber"
            onChange={handleChange('phoneNumber')}
            fullWidth
            variant="outlined"
          />
          <TextField
            testid="openText"
            margin="dense"
            id="name"
            label="Open field text"
            type="openText"
            onChange={handleChange('openText')}
            fullWidth
            variant="outlined"
          />
          <DialogContentText className={classes.contact}>
            A member of our sales team will contact you as soon as possible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justify="center" spacing={8}>
            <Grid item xs={5}>
              <Button testid="cancel" onClick={handleClose} color="primary" variant="contained">
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                testid="orderButton"
                onClick={handleSendMail}
                color="primary"
                variant="contained"
                disabled={values.isLoading}
              >
                {values.isLoading ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                    style={styles.loader}
                  >
                    <Grid item>Sending...</Grid>
                    <Grid item className={classes.loader}>
                      <CircularProgress testid="loader" style={{ width: '14px', height: 'auto' }} />
                    </Grid>
                  </Grid>
                ) : (
                  'Request your order'
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RequestMailOrderPresenter.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  order: PropTypes.shape(PropTypes.any).isRequired,
  onHandleMessage: PropTypes.func.isRequired,
};

export default withStyles(styles)(RequestMailOrderPresenter);
