import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import checkedLogo from '../../../../../assets/images/logos/checked.png';

const styles = {
  container: {
    backgroundColor: '#fff3ee',
    padding: '14px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
  },
  content: {
    fontSize: '14px',
  },
  info: {
    fontSize: '13px',
    fontWeight: 500,
  },
  checkedLogo: {
    width: '20.4px',
    height: '20.4px',
  },
};

export default function ModulesInfo({ infoExploreCampaignTypes }) {
  return (
    <Grid container justify="space-between" style={styles.container}>
      <Grid item xs={12} md={10} lg={10} container spacing={16} justify="center">
        <Grid item xs={11}>
          <Typography style={styles.title} testid="title">
            {infoExploreCampaignTypes.title}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography testid="content" style={styles.content}>
            {infoExploreCampaignTypes.content}
          </Typography>
        </Grid>
        <Grid item xs={11} container direction="row" alignItems="center" spacing={8}>
          {infoExploreCampaignTypes.infoList &&
            infoExploreCampaignTypes.infoList.map(info => (
              <Grid item lg={3} md={4} sm={5} container spacing={8}>
                <Grid item>
                  <img src={checkedLogo} alt="" style={styles.checkedLogo} testid="checkedLogo" />
                </Grid>
                <Grid item>
                  <Typography testid="info" style={styles.info}>
                    {info}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

ModulesInfo.propTypes = {
  infoExploreCampaignTypes: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    infoList: PropTypes.any,
  }).isRequired,
};
