import React from 'react';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SendMail from '../../../../../explore/components/sendMail';

const styles = {
  backButton: { color: '#03a6e9' },
  sneakPeakButton: {
    height: '41px',
    width: '151px',
    borderRadius: '4px',
    fontSize: '12px',
    textTransform: 'unset',
  },
  fullReportButton: {
    height: '41px',
    width: '151px',
    borderRadius: '4px',
    backgroundColor: '#4add3e',
    color: '#ffffff',
    fontSize: '13px',
    textTransform: 'Capitalize',
  },
  container: { backgroundColor: '#fff3ee', padding: 10 },
};

// eslint-disable-next-line no-shadow
export const TitlePresenter = ({ goBack, record }) => {
  const [open, setOpen] = React.useState(false);
  const openSendRequest = () => setOpen(true);
  const closeSendRequest = () => setOpen(false);

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={styles.container}
    >
      <Grid item xs={9} md={7} lg={8} container alignItems="center">
        <IconButton testid="backButton" onClick={goBack}>
          <ArrowBackIos style={styles.backButton} />
        </IconButton>
        <Typography testid="industryReportType" variant="h5">
          {record.name}
        </Typography>
      </Grid>
      <Grid item xs={3} md={5} lg={4} container alignItems="center" justify="flex-end" spacing={16}>
        {record.sneakPeekPdfUrl ? (
          <Grid item xs={12} md={4} lg={5}>
            <Button
              testid="sneakPeak"
              style={styles.sneakPeakButton}
              href={record.sneakPeekPdfUrl}
              color="primary"
              variant="contained"
              target="_blank"
            >
              Sneak peek
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12} md={4} lg={5}>
          <Button style={styles.fullReportButton} onClick={openSendRequest}>
            Get full report
          </Button>
        </Grid>
      </Grid>
      <SendMail
        closeSendRequest={closeSendRequest}
        open={open}
        campaignType="Industry Report"
        content="One of our customer success managers will get in touch to discuss your needs and to give access to this Industry Report"
        mailTitle="Client Industry report request"
        mailBody={`A client requested access to an Industry Report: ${record.name} - ${
          record.id
        }. Contact Client to give access.`}
        extraInfo={`NAME: ${record.name}, ID:  ${record.id}`}
        isWithInput
        inputPlaceholder="Please share any additional information relevant to the Industry Report"
      />
    </Grid>
  );
};

TitlePresenter.propTypes = {
  goBack: PropTypes.func.isRequired,
  record: PropTypes.shape({
    name: PropTypes.string,
  }),
};

TitlePresenter.defaultProps = {
  record: null,
};

export default connect(
  null,
  {
    goBack,
  },
)(TitlePresenter);
