import { createMuiTheme } from '@material-ui/core/styles';

export const simpleSnooperTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0071bc',
    },
    secondary: {
      main: '#33475b',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0071bc',
    },
    secondary: {
      main: '#00C7FC',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    color: '#33475b',
    fontFamily: ['AvenirNext-Regular', 'sans-serif'].join(','),
  },
  overrides: {
    MuiPaper: {
      root: {
        background: '#fff',
        backgroundColor: '#fff',
      },
    },
    MuiTabs: {
      fixed: {
        overflow: 'hidden',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
      },
    },
    MuiDrawer: {
      paper: {
        overflowY: 'hidden',
        zIndex: 'unset',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
      containedPrimary: {
        color: '#fff',
        width: '100%',
        backgroundColor: '#00C7FC',
      },
    },
    MuiStepIcon: {
      text: {
        fill: '#fff',
      },
    },
    MuiTypography: {
      h6: {
        fontSize: '15px',
        color: '#32465a',
        fontWeight: '500',
      },
      h5: {
        fontSize: '17px',
        color: '#32465a',
        fontWeight: '700',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '6.5px 14px',
        fontSize: '12px',
      },
    },
  },
});

export default theme;
