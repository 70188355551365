import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import debounce from 'debounce';
// import { GET_ONE } from 'react-admin';
import { getPhotos, getCampaigns } from '../../../../services/filterService';
import ErrorSnackbar from './components/errorSnackbar/errorSnackbar';
import Filters from './components/filters/index';
import PhotosPerSubmissions from './components/photosPerSubmissions/photosPerSubmissions';

import {
  selectSegmentsByCategory,
  filterPhoto,
  extractPhotoCheckpointShortDescriptions,
} from './services/photoService';
import { trackSegmentEvents } from '../../../../../components/segment/segmentEventTrack';
import { UserContext } from '../../../../../../../context/UserContext';

export class CampaignSubmissionPhotosPresenter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      segmentsByCategories: [],
      selectedSegmentsByCategories: {},
      selectedCheckpointsByCategories: [],
      photoCheckpointShortDescription: [],
      photoCheckpointShortDescriptionSelected: [],
      locationQuery: '',
      isLoading: true,
      isMostRecentAtBottom: true,
      checkpoints: [],
      sharesOfVisibilityFilters: {},
      isErrorLoadingPhotos: false,
      sharesOfVisibilitySelectedValues: {},
      photosTagsFilters: {},
      photosTagsSelectedValues: {},
      cyclesFilter: [],
      cyclesFilterSelected: [],
      isError: false,
      errorMessage: '',
      filteredPhotos: null,
      isFiltering: false,
    };
  }

  async componentDidMount() {
    // const { dataProvider } = this.props;
    // const user = (await dataProvider(GET_ONE, 'users', { id: localStorage.getItem('clientId') }))
    //   .data;
    window.addEventListener('scroll', this.photoScrollRef);

    let response = [];
    try {
      response = await getPhotos(this.props.id);
      this.props.onPhotosByCampaign(this.props.campaign.id, response);
    } catch (e) {
      Sentry.captureException(e);
      this.setState({ isLoading: false, isErrorLoadingPhotos: true });
    }

    if (response.length > 0) {
      let linkedCampaigns = [];
      try {
        linkedCampaigns = await this.getLinkedCampaigns();
      } catch (e) {
        Sentry.captureException(e);
      }

      let checkpointsOrder = [];
      try {
        /* TODO This is a HACK. Should use the data provider but doing so creates issues with testing this:
           Tests work locally but not in pipeline.

           Also note that the role is in localStorage. See https://snooper.atlassian.net/browse/SNOOP-1326
           FIXME Re-write. Can do this when we re-write the gallery. */
        // const { dataProvider } = this.props;
        // const user = await dataProvider(GET_ONE, 'users', { id: localStorage.getItem('clientId') });
        const role = localStorage.getItem('role');
        const clientId = localStorage.getItem('clientId');
        const user = { clientId, role };
        checkpointsOrder = this.getCheckpointsOrder(linkedCampaigns, user);
      } catch (e) {
        Sentry.captureException(e);
      }

      let segmentsByCategories = [];
      try {
        const segmentsByMission = response.map(value => value.segments);
        segmentsByCategories = selectSegmentsByCategory(segmentsByMission);
      } catch (e) {
        Sentry.captureException(e);
      }

      let photoCheckpointShortDescription = [];
      try {
        photoCheckpointShortDescription = extractPhotoCheckpointShortDescriptions(response);
      } catch (e) {
        Sentry.captureException(e);
      }
      let checkpoints = [];
      try {
        /* TODO Some spaghetti code here. :( And no tests, no documentation on the filters and what they do. Hard to read.
           The following seems to go through the responses with answers and maps to checkpoints with the
           short descriptions that in turn match the elements in 'checkpointsOrder'. */
        checkpoints = checkpointsOrder
          .map(check =>
            response
              .map(resp =>
                (resp.checkpoints || []).filter(checkp => checkp.shortDescription === check),
              )
              .filter(c => c.length > 0)
              .flat(),
          )
          .filter(it => it != null && it.length > 0)
          .map(it => it[0])
          .filter(it => {
            if (it === undefined) {
              /* A bit odd to assume and log an error case based on a value here. :/ What if 'it' is undefined for another reason?  */
              Sentry.captureMessage(`Checkpoint added after campaign launch with no answer`, {
                level: 'error',
              });
            }
            return it !== undefined;
          })
          .flat();
      } catch (e) {
        Sentry.captureException(e);
      }

      let selectedCheckpointsByCategories = [];
      try {
        selectedCheckpointsByCategories = checkpoints
          ? checkpoints.map(it => [it.shortDescription, []])
          : [];
      } catch (e) {
        Sentry.captureException(e);
      }

      let sharesOfVisibilityFilters = {};

      try {
        const sharesOfVisibility = response
          .filter(submission => submission.sharesOfVisibility)
          .map(value => value.sharesOfVisibility.map(raw => raw))
          .flat();

        if (sharesOfVisibility.length > 0) {
          sharesOfVisibilityFilters = {
            locations: [...new Set(sharesOfVisibility.map(share => share.location))],
            labels: [...new Set(sharesOfVisibility.map(share => share.label))].sort((a, b) =>
              a.localeCompare(b),
            ),
            categories: [...new Set(sharesOfVisibility.map(share => share.category))].sort((a, b) =>
              a.localeCompare(b),
            ),
            subCategories: [
              ...new Set(
                sharesOfVisibility
                  .map(share => share.subCategory)
                  .filter(value => value !== undefined && value !== 'NA'),
              ),
            ].sort((a, b) => a.localeCompare(b)),
            packGroups: [
              ...new Set(
                sharesOfVisibility
                  .map(share => share.packGroup)
                  .filter(value => value !== undefined && value !== 'NA'),
              ),
            ].sort((a, b) => a.localeCompare(b)),
            promoPacks: [
              ...new Set(
                sharesOfVisibility
                  .map(share => share.promoPack)
                  .filter(value => value !== undefined && value !== 'NA'),
              ),
            ].sort((a, b) => a.localeCompare(b)),
            manufacturers: [
              ...new Set(
                sharesOfVisibility
                  .map(share => share.manufacturer)
                  .filter(value => value !== undefined && value !== 'NA'),
              ),
            ].sort((a, b) => a.localeCompare(b)),
            brands: [
              ...new Set(
                sharesOfVisibility
                  .map(share => share.brand)
                  .filter(value => value !== undefined && value !== 'NA'),
              ),
            ].sort((a, b) => a.localeCompare(b)),
            productSegments: [
              ...new Set(sharesOfVisibility.map(sov => sov.segment).filter(value => !!value)),
            ],
          };
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      let cyclesFilter = [];
      try {
        cyclesFilter = [
          ...new Set(response.map(submission => submission.cycle).filter(cycle => cycle != null)),
        ];
      } catch (e) {
        Sentry.captureException(e);
      }

      const photosTagsFilters = {};

      try {
        const allTags = response
          .map(submission => submission.photosPerSubmission)
          .flatMap(photos => photos.flatMap(photo => photo.tags))
          .filter(value => value != null);
        allTags.forEach(tag => {
          if (!Object.keys(photosTagsFilters).includes(tag.name)) {
            photosTagsFilters[tag.name] = new Set();
          }
          photosTagsFilters[tag.name].add(tag.value);
        });
      } catch (e) {
        Sentry.captureException(e);
      }
      this.onApplyFilter();
      this.setState({
        segmentsByCategories,
        photoCheckpointShortDescription,
        selectedCheckpointsByCategories,
        checkpoints,
        sharesOfVisibilityFilters,
        photosTagsFilters,
        isLoading: false,
        cyclesFilter,
      });
      this.props.onGalleryIsNotEmpty(true);
    } else {
      this.props.onGalleryIsNotEmpty(false);
      this.props.onGalleryIsLoaded(true);
      this.setState({ isLoading: false, isErrorLoadingPhotos: false });
      this.props.onPhotosByCampaign(this.props.campaign.id, []);
    }
  }

  componentDidUpdate = prevProps => {
    const { photosByCampaign, id } = this.props;
    if (photosByCampaign[id] !== prevProps.photosByCampaign[id]) {
      this.applyFilter();
    }
  };

  onTrackEvent = (type, params) => {
    const { name, inAppName, id } = this.props.campaign;
    const { user, customerSubscriptions } = this.props;

    trackSegmentEvents(
      type,
      {
        ...params,
        campaignId: id,
        campaignName: name,
        inAppName,
      },
      user,
      customerSubscriptions,
    );
  };

  onApplyFilter = () => {
    this.setState({ isFiltering: true });
    this.applyFilter();
  };

  onClearFilters = () => {
    this.setState(
      prevState => ({
        locationQuery: '',
        photoCheckpointShortDescriptionSelected: [],
        selectedCheckpointsByCategories: prevState.selectedCheckpointsByCategories.map(seg => [
          seg[0],
          [],
        ]),
        selectedSegmentsByCategories: {},
        photosTagsSelectedValues: {},
        sharesOfVisibilitySelectedValues: {},
        cyclesFilterSelected: [],
      }),
      this.onApplyFilter,
    );
  };

  onHandleChange = (key, valueFromChild) => {
    this.setState({ [key]: valueFromChild });
    this.onApplyFilter();
  };

  onHandleError = errorMessage => {
    this.setState({ isError: true, errorMessage });
  };

  getLinkedCampaigns = async () => {
    const { campaign } = this.props;
    if (campaign && campaign.linkedCampaigns) {
      const results = campaign.linkedCampaigns.map(async campaignId => {
        const result = await getCampaigns(campaignId);
        return result;
      });
      return Promise.all(results);
    }
    return [];
  };

  /* TODO Questionable naming. Checkpoints order? Why? What? No documentation! */
  getCheckpointsOrder = (linkedCampaigns, user = undefined) => {
    const { campaign } = this.props;

    if (campaign && campaign.checkpoints) {
      return campaign.checkpoints
        .filter(checkpoint => ['YesNo', 'Checklist', 'Rating'].includes(checkpoint.checkpointType))
        .filter(checkpoint => doesTheUserHavePermissionToSeeTheCheckpoint(user, checkpoint))
        .map(c => c.shortDescription);
    }
    if (campaign && linkedCampaigns !== []) {
      const mergedCheckpoints = linkedCampaigns.map(linkedCampaign => linkedCampaign.checkpoints);
      const mergedCheckpointsOrder = mergedCheckpoints
        .flat()
        .filter(checkpoint => ['YesNo', 'Checklist', 'Rating'].includes(checkpoint.checkpointType))
        .filter(checkpoint => doesTheUserHavePermissionToSeeTheCheckpoint(user, checkpoint))
        .map(c => c.shortDescription);
      return [...new Set(mergedCheckpointsOrder)];
    }
    return [];
  };

  applyFilter = debounce(() => {
    const { isMostRecentAtBottom } = this.state;
    const filteredPhotos = filterPhoto(
      this.state.selectedSegmentsByCategories,
      this.props.photosByCampaign[this.props.id],
      this.state.locationQuery,
      this.state.selectedCheckpointsByCategories,
      this.state.photoCheckpointShortDescriptionSelected,
      this.state.sharesOfVisibilitySelectedValues,
      this.state.photosTagsSelectedValues,
      this.state.cyclesFilterSelected,
    );
    this.setState({
      numberOfSubmissions: filteredPhotos.length,
      filteredPhotos: isMostRecentAtBottom ? filteredPhotos : filteredPhotos.reverse(),
      isFiltering: false,
    });
  }, 600);

  renderFilterList() {
    const {
      locationQuery,
      isMostRecentAtBottom,
      selectedSegmentsByCategories,
      segmentsByCategories,
      checkpoints,
      selectedCheckpointsByCategories,
      sharesOfVisibilityFilters,
      sharesOfVisibilitySelectedValues,
      photosTagsFilters,
      photosTagsSelectedValues,
      photoCheckpointShortDescription,
      photoCheckpointShortDescriptionSelected,
      cyclesFilter,
      cyclesFilterSelected,
      numberOfSubmissions,
    } = this.state;
    const { permissions, drawerHeight } = this.props;
    return (
      <Filters
        testid="filter"
        locationQuery={locationQuery}
        isMostRecentAtBottom={isMostRecentAtBottom}
        onSortByCreatedAt={this.onSortByCreatedAt}
        selectedSegmentsByCategories={selectedSegmentsByCategories}
        segmentsByCategories={segmentsByCategories}
        checkpoints={checkpoints}
        selectedCheckpointsByCategories={selectedCheckpointsByCategories}
        sharesOfVisibilityFilters={sharesOfVisibilityFilters}
        sharesOfVisibilitySelectedValues={sharesOfVisibilitySelectedValues}
        photosTagsFilters={photosTagsFilters}
        photosTagsSelectedValues={photosTagsSelectedValues}
        photoCheckpointShortDescription={photoCheckpointShortDescription}
        photoCheckpointShortDescriptionSelected={photoCheckpointShortDescriptionSelected}
        permissions={permissions}
        cyclesFilter={cyclesFilter}
        cyclesFilterSelected={cyclesFilterSelected}
        onHandleChange={this.onHandleChange}
        numberOfSubmissions={numberOfSubmissions}
        onApplyFilter={this.onApplyFilter}
        drawerHeight={drawerHeight}
        onClearFilters={this.onClearFilters}
        filteredPhotos={this.state.filteredPhotos}
        campaignId={this.props.campaign.id}
        onTrackEvent={this.onTrackEvent}
      />
    );
  }

  render() {
    const {
      isLoading,
      isErrorLoadingPhotos,
      isError,
      sortByCreatedAt,
      filteredPhotos,
      isFiltering,
      errorMessage,
    } = this.state;
    const { photosByCampaign, id, record, drawerHeight, onPhotosByCampaign } = this.props;
    if (isErrorLoadingPhotos) {
      return (
        <Grid container justify="center" alignItems="center">
          Sorry, there is an error loading the photo gallery. Please try again later or contact us
          if the issue persists.
        </Grid>
      );
    }
    if (isLoading) {
      return (
        <Grid container justify="center" alignItems="center">
          <CircularProgress testid="loader" />
        </Grid>
      );
    }
    if (!photosByCampaign && photosByCampaign[id].length === 0) {
      return (
        <Grid container justify="center" alignItems="center">
          No photos submitted yet
        </Grid>
      );
    }
    return (
      <>
        <Grid container>
          {this.renderFilterList()}
          <PhotosPerSubmissions
            testid="photosPerSubmission"
            record={record}
            photosByCampaign={photosByCampaign}
            id={id}
            onPhotosByCampaign={onPhotosByCampaign}
            drawerHeight={drawerHeight}
            sortByCreatedAt={sortByCreatedAt}
            onHandleChange={this.onHandleChange}
            filteredPhotos={filteredPhotos}
            isFiltering={isFiltering}
            onHandleErrorMessage={this.onHandleError}
            onApplyFilter={this.onApplyFilter}
            onTrackEvent={this.onTrackEvent}
          />
        </Grid>
        <ErrorSnackbar
          isOpen={isError}
          onHandleErrorMessage={this.onHandleChange}
          message={errorMessage}
        />
      </>
    );
  }
}

CampaignSubmissionPhotosPresenter.propTypes = {
  id: PropTypes.string.isRequired,
  drawerHeight: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onGalleryIsLoaded: PropTypes.func.isRequired,
  onGalleryIsNotEmpty: PropTypes.func.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    inAppName: PropTypes.string,
  }).isRequired,
  onPhotosByCampaign: PropTypes.func.isRequired,
  photosByCampaign: PropTypes.shape({}).isRequired,
  permissions: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  customerSubscriptions: PropTypes.shape({}).isRequired,
  // dataProvider: PropTypes.func.isRequired, /* Commented out for now. */
};

/**
 * Returns true if the specified user has access to the specified checkpoint. This
 * is determined based on the 'hiddenToClients' flag of the checkpoint, and whether
 * the user is a CLIENT. The return value is true if the user is either an ADMIN,
 * or the user is a CLIENT and hiddenToClients if false.
 *
 * @param {Object} user
 * @param {*} checkpoint
 *
 * @returns true if the user has access to the checkpoint, otherwise false.
 */
export const doesTheUserHavePermissionToSeeTheCheckpoint = (user, checkpoint) => {
  if (!user || !user.clientId || !user.role || !['ADMIN', 'CLIENT'].includes(user.role)) {
    throw Error('You do not have access. Please log out and log back in to retry.');
  }

  const userIsClient = user.role === 'CLIENT';
  const userIsAdmin = user.role === 'ADMIN';

  return userIsAdmin || (userIsClient && String(checkpoint.hiddenToClients) !== 'true');
};

const CampaignSubmissionPhotosPresenterWithUserContext = props => {
  const userContext = useContext(UserContext);

  const { user, customerSubscriptions } = userContext;

  return (
    <>
      <CampaignSubmissionPhotosPresenter
        user={user}
        customerSubscriptions={customerSubscriptions}
        {...props}
      />
    </>
  );
};

export default CampaignSubmissionPhotosPresenterWithUserContext;
