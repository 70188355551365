import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import { CompletionMethodologyTooltip } from '../../../components/tooltips/tooltips';
import {
  selectCompletionStrategy,
  completionStrategyPriceImpact,
} from '../../../../variables/variables';
import HASQUOTE from '../../../../../../setup/featureFlipping';

const styles = {
  error: { color: '#f6675e' },
  marginLocations: { marginBottom: 10 },
  methodology: {
    marginLeft: '15px',
  },
  completionMethodologyTooltip: {
    position: 'relative',
    top: '2px',
  },
  specify: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: 5,
    fontSize: 10,
  },
  added: {
    fontSize: '12px',
    color: '#8c8c8c',
    marginLeft: 10,
  },
};

export default function CompletionStrategy({
  quoteOrder,
  everythingFilledIn,
  calculateIsClicked,
  handleChangeCompletionStrategy,
}) {
  const { completionStrategy } = quoteOrder;

  const getStoreImpact = () => {
    const completionStrategyImpact = completionStrategyPriceImpact.find(
      impact => impact.value === completionStrategy,
    ).impact;
    if (completionStrategyImpact !== 0) {
      return `+$${completionStrategyImpact}`;
    }
    return null;
  };

  return (
    <Grid container alignItems="center" style={styles.marginLocations}>
      <Grid item xs={4} style={styles.methodology}>
        <Typography testid="title">
          Completion methodology:
          <span style={styles.completionMethodologyTooltip}>
            <CompletionMethodologyTooltip />
          </span>
        </Typography>
        <Typography style={styles.specify}>(recommended: `Random Sample`)</Typography>
      </Grid>
      <Grid item xs={6} container alignItems="center">
        <Grid item>
          <TextField
            select
            testid="completionField"
            variant="outlined"
            value={completionStrategy}
            onChange={handleChangeCompletionStrategy}
            error={completionStrategy.trim() === '' && !everythingFilledIn && calculateIsClicked}
            margin="normal"
            fullWidth
          >
            {selectCompletionStrategy.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {HASQUOTE ? (
          <Grid item>
            <span style={styles.added}> {getStoreImpact()}</span>
          </Grid>
        ) : null}
        {completionStrategy.trim() === '' && !everythingFilledIn && calculateIsClicked ? (
          <FormHelperText testid="error" style={styles.error} id="component-error-text">
            Please select
          </FormHelperText>
        ) : null}
      </Grid>
    </Grid>
  );
}

CompletionStrategy.propTypes = {
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  everythingFilledIn: PropTypes.bool.isRequired,
  calculateIsClicked: PropTypes.bool.isRequired,
  handleChangeCompletionStrategy: PropTypes.func.isRequired,
};
