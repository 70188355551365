import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { storeTypePriceImpact } from '../../../../variables/variables';
import HASQUOTE from '../../../../../../setup/featureFlipping';

const styles = {
  specify: {
    marginLeft: 5,
    fontSize: 10,
    color: '#f38c63',
    marginTop: 4,
  },
  added: {
    fontSize: '12px',
    color: '#8c8c8c',
    marginLeft: 10,
  },
  subSelection: { marginLeft: '7%' },
};
class CheckboxQuote extends React.PureComponent {
  getStoreImpact = banner => {
    const storeImpact = storeTypePriceImpact.find(impact => impact.value === banner).impact;
    if (storeImpact !== 0) {
      return `+$${storeImpact}`;
    }
    return null;
  };
  checkIfAllSubSelectionsAreChecked = option => {
    if (option.values) {
      return option.values.every(value => value.isChecked === true);
    }
    return false;
  };

  handleChangeCheckbox = checkboxIndex => () => {
    const newOptions = this.props.selectOptions.map((selectOption, index) => {
      if (checkboxIndex === index) {
        return {
          ...selectOption,
          isChecked: !selectOption.isChecked,
        };
      }
      return selectOption;
    });
    this.props.onHandleChangeCheckbox(newOptions);
  };

  handleChangeCheckboxValue = (index, valueIndex) => () => {
    const newOptions = [...this.props.selectOptions];
    newOptions[valueIndex].values[index].isChecked = !newOptions[valueIndex].values[index]
      .isChecked;
    this.props.onHandleChangeCheckbox(newOptions);
  };

  renderSubSelections = (values, valueIndex) =>
    values.map((value, index) => (
      <Grid key={value.banners} container direction="row" style={styles.subSelection}>
        <Checkbox
          testid="subSelection"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checked={value.isChecked}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          value={value.banners}
          onChange={this.handleChangeCheckboxValue(index, valueIndex)}
        />
        <p>
          {value.banners} ({value.amount.toLocaleString()} locations)
        </p>
      </Grid>
    ));

  render() {
    const { selectOptions } = this.props;
    return selectOptions.map((option, index) => (
      <Grid container key={option.banners} direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              testid="checkbox"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checked={option.isChecked}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              value={option.banners}
              onChange={this.handleChangeCheckbox(index)}
              indeterminate={option.isChecked && !this.checkIfAllSubSelectionsAreChecked(option)}
              inputProps={{
                'aria-label': 'indeterminate checkbox',
              }}
            />
          }
          label={option.banners}
        />
        {option.isChecked && HASQUOTE ? (
          <Typography>
            <span style={styles.added}> {this.getStoreImpact(option.banners)}</span>
          </Typography>
        ) : null}

        {option.banners === 'Other' && option.isChecked ? (
          <Typography style={styles.specify}>
            (Please specify in the ‘Additional information’ section here below. This is an estimated
            quote. For ‘Other’ channels, the exact price will be defined once we receive your
            briefing.)
          </Typography>
        ) : null}
        {option.values.length > 0 && option.isChecked
          ? this.renderSubSelections(option.values, index)
          : null}
      </Grid>
    ));
  }
}

CheckboxQuote.propTypes = {
  onHandleChangeCheckbox: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CheckboxQuote;
