import React from 'react';
import { Grid } from '@material-ui/core';

import { infoExploreCampaignTypes, excellencePrograms } from '../../variables/variables';
import ModulesInfo from '../modules/components/modulesInfo';
import ModulesListFooter from '../modules/components/modulesListFooter';
import ExcellenceProgramCard from './components/excellenceProgramCard';

import SendMail from '../components/sendMail';

const styles = {
  container: { padding: 10, marginBottom: 30 },
};

function ExcellencePrograms() {
  const [open, setOpen] = React.useState(false);
  const openSendRequest = () => setOpen(true);
  const closeSendRequest = () => setOpen(false);

  return (
    <React.Fragment>
      <Grid>
        <ModulesInfo
          testid="modulesInfo"
          infoExploreCampaignTypes={infoExploreCampaignTypes.excellence}
          openSendRequest={openSendRequest}
        />
      </Grid>

      <Grid container style={styles.container}>
        {excellencePrograms.map(excellenceProgram => (
          <ExcellenceProgramCard
            testid="excellenceCards"
            excellencePrograms={excellenceProgram}
            campaignType="Excellence program"
          />
        ))}
      </Grid>
      <ModulesListFooter
        title="Ready to launch your own Excellence Program?"
        titleButton="Contact us"
        openSendRequest={openSendRequest}
        variant="contained"
        buttonStyle={{
          button: {
            width: '121px',
            height: '41px',
            borderRadius: '4px',
            backgroundColor: '#4add3e',
            color: '#ffffff',
            marginRight: 20,
          },
        }}
      />
      <SendMail
        closeSendRequest={closeSendRequest}
        open={open}
        campaignType="Excellence program"
        content="One of our customer success managers will get in touch to discuss your needs and take the first steps towards designing your very own Excellence Program."
        mailTitle="Client custom excellence program request"
        mailBody="A client would like to build an Excellence Program."
        isWithInput
        inputPlaceholder="Please share any additional information relevant to your Excellence Program"
      />
    </React.Fragment>
  );
}

export default ExcellencePrograms;
