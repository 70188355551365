import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ContentCreate from '@material-ui/icons/Create';
import Avatar from '@material-ui/core/Avatar';
import EYE_BASE_URL from './reviewersPlatformConstants';

const ReviewersPhotoLink = ({ id, photoUrl }) => (
  <Tooltip title={`View/review this photo for submission ${id}`}>
    <span>
      <Button
        testid="link"
        href={`${EYE_BASE_URL}/${id}/deeplink?photoUrl=${encodeURIComponent(photoUrl)}`}
        target="_blank"
        label=""
      >
        <Avatar>
          <ContentCreate />
        </Avatar>
      </Button>
    </span>
  </Tooltip>
);

ReviewersPhotoLink.propTypes = {
  id: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
};

export default ReviewersPhotoLink;
