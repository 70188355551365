import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import MainKpi from '../../mainKpi/mainKpi';
import MetabasePreview from './metabasePreview/metabasePreview';
import { Event } from '../../../../../../setup/analytics';

import HASQUOTE from '../../../../../../setup/featureFlipping';

const styles = {
  fullDashboard: {
    color: 'white',
  },
  link: { textDecoration: 'none', color: '#00c7fc', fontSize: '12px', marginLeft: 10 },
  contentBackground: {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 0 11px 5px rgba(234, 240, 246, 0.5)',
    height: '80%',
    overflowY: 'auto',
    marginTop: '20px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: 15,
  },
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
  },
  iframe: {
    height: '100%',
    width: '100%',
  },
};

class FullScreenDialog extends React.PureComponent {
  state = {
    indexCurrentCheckpoint: 0,
  };

  handlePanelChange = panelID => {
    const indexCurrentCheckpoint = this.props.keepPerformanceIndicators.findIndex(
      keepPerformanceIndicator => keepPerformanceIndicator.id === panelID,
    );
    this.setState({ indexCurrentCheckpoint });
  };

  renderKpis = () => {
    const { keepPerformanceIndicators } = this.props;

    return (
      <Grid item xs={12} md={5} container style={styles.container}>
        <Grid item xs={12}>
          <Typography color="primary" style={styles.title}>
            KPIs included in this module
          </Typography>
        </Grid>
        <Grid item xs={12} style={styles.contentBackground}>
          <MainKpi
            testid="mainKpi"
            keepPerformanceIndicators={keepPerformanceIndicators}
            handlePanelChange={this.handlePanelChange}
          />
        </Grid>
      </Grid>
    );
  };

  renderDashboardPreview = () => {
    const { keepPerformanceIndicators } = this.props;
    const { indexCurrentCheckpoint } = this.state;
    return (
      <Grid item xs={12} md={5} container style={styles.container}>
        <Grid item xs={12}>
          <Typography color="primary" style={styles.title}>
            Dashboard preview{' '}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://metabase.snooper-app.com/public/dashboard/885e8a52-d0ed-4242-9355-766e455242aa"
                style={styles.link}
                onClick={() =>
                  Event('Navigation', 'Full dashboard preview', 'Explore modules, insights preview')
                }
              >
                See full dashboard
              </a>
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} style={styles.contentBackground}>
          <MetabasePreview
            testid="metabasePreview"
            metabasePreviewIDs={keepPerformanceIndicators[indexCurrentCheckpoint].metabase}
          />
        </Grid>
      </Grid>
    );
  };

  renderUseCases = () => {
    const { useCaseLink } = this.props;
    return useCaseLink[0] ? (
      <Grid testid="useCase" item xs={12} md={5} container style={styles.container}>
        <Grid item xs={12}>
          <Typography color="primary" style={styles.title}>
            Use case
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={useCaseLink}
                style={styles.link}
                download
              >
                download
              </a>
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} style={styles.contentBackground}>
          <iframe
            testid="pdf"
            src={useCaseLink}
            title="pdf"
            style={styles.iframe}
            frameBorder={0}
          />
        </Grid>
      </Grid>
    ) : null;
  };

  render() {
    return (
      <Grid container justify="space-around" style={styles.root}>
        {this.renderKpis()}
        {HASQUOTE ? this.renderDashboardPreview() : this.renderUseCases()}
      </Grid>
    );
  }
}

FullScreenDialog.propTypes = {
  keepPerformanceIndicators: PropTypes.arrayOf(PropTypes.any).isRequired,
  useCaseLink: PropTypes.string.isRequired,
};

export default withStyles(styles)(FullScreenDialog);
