import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import InfoIcon from '@material-ui/icons/Info';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Event } from '../../../../../setup/analytics';

const styles = {
  mainKPI: {
    margin: 20,
  },
  infoIcon: { maxHeight: 15, color: 'rgba(0, 0, 0, 0.38)', marginLeft: 20, marginTop: 10 },
  expansion: {
    background: '#f5f8fa',
    height: '43px',
  },
  title: { fontWeight: '600' },
};

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null,
  };

  onPanelOpen = panel => (event, expanded) => {
    Event('Navigation', `Select KPI: ${panel}`, 'Explore modules, insights preview');
    this.setState({
      expanded: expanded ? panel : false,
    });
    this.props.handlePanelChange(panel);
  };

  render() {
    const { keepPerformanceIndicators } = this.props;
    const { expanded } = this.state;

    return (
      <div style={styles.mainKPI}>
        <ExpansionPanel expanded={false} disabled style={styles.expansion} />
        {keepPerformanceIndicators.map(kpi => (
          <ExpansionPanel
            key={kpi.id}
            testid="expansionPanel"
            expanded={expanded === kpi.id}
            onChange={this.onPanelOpen(kpi.id)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={styles.title}>{kpi.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column">
                <Typography paragraph variant="h6">
                  {kpi.question}
                </Typography>
                <Typography gutterBottom>{kpi.context} </Typography>
                {kpi.restriction ? (
                  <Typography>
                    <InfoIcon style={styles.infoIcon} />
                    <span> {kpi.restriction}</span>
                  </Typography>
                ) : null}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  keepPerformanceIndicators: PropTypes.arrayOf(PropTypes.any).isRequired,
  handlePanelChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);
