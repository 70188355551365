import { packs } from '../../../../../variables/variables';

export const calculateFeePerAudit = (missionPlan, feePerAudit) => {
  switch (missionPlan) {
    case 'Ad-hoc':
      return formatAsPercentage(feePerAudit, 15);
    case 'Silver':
      return formatAsPercentage(feePerAudit, 15) * (1 - 5 / 100);
    case 'Gold':
      return formatAsPercentage(feePerAudit, 15) * (1 - 10 / 100);
    case 'Platinum':
      return feePerAudit;
    default:
      return 15;
  }
};

const formatAsPercentage = (value, percent) => value + (value * percent) / 100;

export const calculateQuotePerMissionPlan = (missionPlan, order) => {
  const {
    feePerAudit,
    fastCompletionFee,
    fastTrackFee,
    multipleVisitPerStore,
    numberOfLocations,
  } = order;

  const totalSurveys = multipleVisitPerStore * numberOfLocations;
  switch (missionPlan) {
    case 'Ad-hoc':
      return (
        calculateFeePerAudit('Ad-hoc', feePerAudit) * totalSurveys +
        packs
          .filter(pack => pack.membership === 'Ad-hoc')
          .map(value => value.designDataFee)
          .shift() +
        (fastTrackFee ? 500 : 0) +
        (fastCompletionFee ? 500 : 0)
      );
    case 'Silver':
      return calculateFeePerAudit('Silver', feePerAudit) * totalSurveys;
    case 'Gold':
      return calculateFeePerAudit('Gold', feePerAudit) * totalSurveys;
    case 'Platinum':
      return calculateFeePerAudit('Platinum', feePerAudit) * totalSurveys;
    default:
      return (
        calculateFeePerAudit('Ad-hoc', 15) * totalSurveys +
        packs
          .filter(pack => pack.membership === 'Ad-hoc')
          .map(value => value.designDataFee)
          .shift() +
        (fastTrackFee ? 500 : 0) +
        (fastCompletionFee ? 500 : 0)
      );
  }
};

export default null;
