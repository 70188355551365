import fan from '../../../../../../../../../services/fan';

const jsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

async function resetPhotoToOriginal(submissionId, checkpointId, key) {
  const result = await fan.put(
    `/submissionsNew/${submissionId}/checkpoints/${checkpointId}/photos/resetToOriginal`,
    key,
    jsonConfig,
  );
  return result.data;
}

export default resetPhotoToOriginal;
