export const checkFreeTrialStatus = customerSubscriptions => {
  const result = {
    isSubscribe: false,
    hasValidSubscriptions: undefined,
    hasSubscriptionExpired: undefined,
    hasSubscriptionNotStarted: undefined,
    startDate: undefined,
    endDate: undefined,
  };

  if (!customerSubscriptions || customerSubscriptions.length === 0) return result;

  const { startDate, endDate, type: customerSubscriptionType } = customerSubscriptions[0];
  const currentDate = new Date();

  return {
    ...result,
    isSubscribe: true,
    hasValidSubscriptions:
      startDate && endDate
        ? currentDate >= new Date(startDate) && currentDate <= new Date(endDate)
        : undefined,
    hasSubscriptionExpired: endDate ? currentDate > new Date(endDate) : undefined,
    hasSubscriptionNotStarted: startDate ? currentDate < new Date(startDate) : undefined,
    type: customerSubscriptionType || undefined,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
  };
};

export const selectSegmentUserSettings = (user, customerSubscriptions) => {
  if (!user) {
    return undefined;
  }

  const { firstName, lastName, email, createdAt, userName, role, id } = user;

  const freeTrailStatus = checkFreeTrialStatus(customerSubscriptions);

  const settings = {
    firstName: firstName || undefined,
    lastName: lastName || undefined,
    objectId: id || undefined,
    email: email || undefined,
    role: role || undefined,
    userName: userName || undefined,
    created_at: createdAt ? new Date(createdAt).getTime() : undefined,
    freeTrial: freeTrailStatus,
    snooper_ri_user: true,
  };

  return settings;
};

export default selectSegmentUserSettings;
