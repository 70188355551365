import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tabs, Tab, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import CampaignsList from '../campaigns/scenes/campaignsList/campaignsList.presenter';
import { isOrderingReleased } from '../../../setup/featureFlipping';

const styles = {
  tabSelected: {
    borderBottom: '1px solid #017d9e',
  },
  wrapper: {
    height: '48px',
  },
  tabs: {
    height: '48px',
  },
  tabsFixed: {
    flexGrow: 0,
  },
  tabPanelRoot: {
    flexGrow: 1,
  },
  root: { height: '100%', display: 'flex', flexDirection: 'column' },
};

export class MySnooper extends React.PureComponent {
  state = {
    value: 'All',
  };

  componentDidMount = () => {
    const { value } = this.state;
    const tabSelected = this.getTabSelected();
    if (tabSelected !== value) {
      this.setState({ value: tabSelected });
    }
  };

  componentDidUpdate = () => {
    const { value } = this.state;
    const tabSelected = this.getTabSelected();
    if (tabSelected !== value) {
      this.setState({ value: tabSelected });
    }
  };

  getTabSelected = () => {
    const { location } = this.props;
    if (location.search.includes('Industry')) {
      return 'Industry reports';
    }
    if (location.search.includes('Excellence')) {
      return 'Excellence programs';
    }
    if (location.search.includes('Module')) {
      return 'Modules';
    }
    if (location.search.includes('Data')) {
      return 'Data series';
    }
    return 'All';
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div style={styles.root}>
        {isOrderingReleased ? (
          <Paper style={styles.tabsFixed}>
            <Tabs
              testid="tabs"
              value={value}
              onChange={this.handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              centered
              classes={{
                root: classes.tabsRoot,
              }}
            >
              <Tab
                label="All"
                classes={{
                  selected: classes.tabSelected,
                  wrapper: classes.wrapper,
                }}
                value="All"
                component={Link}
                to="?order=DESC&page=1&perPage=10&sort=startDate"
              />
              <Tab
                label="Quick trackers"
                classes={{
                  selected: classes.tabSelected,
                  wrapper: classes.wrapper,
                }}
                value="Modules"
                component={Link}
                to={`?filter={"campaignTypes":["Module"]}&order=DESC&page=1&perPage=10&sort=startDate`}
              />
              <Tab
                testid="excellence"
                label="My excellence programs"
                classes={{
                  selected: classes.tabSelected,
                  wrapper: classes.wrapper,
                }}
                value="Excellence programs"
                component={Link}
                to={`?filter={"campaignTypes":["Excellence program"]}&order=DESC&page=1&perPage=10&sort=startDate`}
              />
              <Tab
                testid="industry"
                label="My industry reports"
                classes={{
                  selected: classes.tabSelected,
                  wrapper: classes.wrapper,
                }}
                value="Industry reports"
                component={Link}
                to={`?filter={"campaignTypes":["Industry Report"]}&order=DESC&page=1&perPage=10&sort=startDate`}
              />
              <Tab
                label="My data series"
                classes={{
                  selected: classes.tabSelected,
                  wrapper: classes.wrapper,
                }}
                value="Data series"
                component={Link}
                to={`?filter={"campaignTypes":["Data serie"]}&order=DESC&page=1&perPage=10&sort=startDate`}
              />
              <CampaignButton testid="newCampaign" label="Launch new" href="/#/explore/all">
                Launch new
              </CampaignButton>
            </Tabs>
          </Paper>
        ) : null}
        <Grid style={styles.tabPanelRoot}>
          <CampaignsList {...this.props} campaignType={value} />
        </Grid>
      </div>
    );
  }
}

MySnooper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

const CampaignButton = styled(Button)`
  && {
    background-color: #4add3e;
    color: white;
    margin: 9px 15px;
    height: 40px;
    width: 101px;
    border: 1px solid white;
    opacity: 1;
    font-size: 12px;
    border-radius: 8px;
  }
`;

export default withStyles(styles)(MySnooper);
