import excellenceLogo from '../../../assets/images/logos/Excellence programs.png';
import modulesLogo from '../../../assets/images/logos/modules.png';
import industryLogo from '../../../assets/images/logos/Industry reports.png';
import dataSeriesLogo from '../../../assets/images/logos/Data series.png';

import NPDTrackerLogo from '../../../assets/images/NPDtracker.png';
import SpecialOfferImage from '../../../assets/images/ordering/image 1.jpg';
import FreeShopperInsightsImage from '../../../assets/images/ordering/image 2.jpg';
import DataSeriesImage from '../../../assets/images/ordering/image 3.jpg';

export const offersHomePage = [
  {
    id: 0,
    title: 'Special offer',
    content: `Get a <strong> 20% discount! </strong> on any in-store execution mission (from our list of modules) ordered before the end of the month (to be launched at any time – e.g. order this month and launch next month)`,
    campaignType: '',
    link: '',
    buttonText: 'Show interest',
    imageUrl: FreeShopperInsightsImage,
  },
  {
    id: 1,
    title: 'FREE shopper insights',
    content: `Send us 3 questions you would like to see answered by our community during your next key selling period. We will then share the insights in a short infographics available for FREE. Hurry, this offer is limited to 5 participants only.`,
    campaignType: '',
    link: '',
    buttonText: 'Participate',
    imageUrl: SpecialOfferImage,
  },
  {
    id: 2,
    title: 'New data series available',
    content: `Discover the Snooper <strong> ‘share of visibility’ </strong> report and get access to a weekly analyses of the share of category (including sub-categories, manufacturers, pack types and promotions) on gondola ends and displays at Woolies and Coles.`,
    campaignType: '',
    link: '#/explore/data-series/1',
    buttonText: 'View more',
    imageUrl: DataSeriesImage,
  },
];

export const campaignTypesInformation = {
  modules: {
    title: 'Quick trackers',
    content: `Choose from a list of 14 modules designed by our experts to provide you with a 360 view of
  your execution in store by tracking on-shelf and off-location KPIs.`,
    logo: modulesLogo,
    link: '#/explore/quick-trackers',
  },
  excellence: {
    title: 'Excellence programs',
    content: `Whether you want to roll out a global program or design your own, our team works with you to design a perfect store program in line with your specific business challenges.`,
    logo: excellenceLogo,
    link: '#/explore/excellence-programs',
  },
  industryReports: {
    title: 'Industry reports',
    content: `A 12-20 pages insights reports answering important questions about your category.`,
    logo: industryLogo,
    link: '#/explore/industry-reports',
  },
  dataSeries: {
    title: 'Data series',
    content: `Recurring data reports tracking a specific KPI of your category received on a weekly or monthly basis.`,
    logo: dataSeriesLogo,
    link: '#/explore/data-series',
  },
};

export const excellencePrograms = [
  {
    id: 0,
    title: 'Mars petcare perfect store',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    link: '',
  },
  {
    id: 1,
    title: 'Puma compliance program',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    link: '',
  },
];

export const dataSeries = [
  {
    id: 0,
    title: 'Hot to win Father’s Day',
    industry: 'Liquor',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    link: '#/explore/data-series/1',
  },
];

export const IndustryReportOrder = {
  id: null,
  campaignId: null,
  clientId: localStorage.getItem('clientId'),
  reportName: null,
  shortDescription: null,
  syndicatedReportIndustry: null,
  syndicatedReportTopic: null,
  syndicatedReportType: null,
  industryReportPrice: null,
  startDate: null,
  endDate: null,
};

export const campaignOrder = {
  mission: null,
  startDate: null,
  endDate: null,
  fastTrackFee: false,
  fastCompletionFee: false,
  storeTypes: [],
  banners: [],
  multipleVisitPerStore: 1,
  completionStrategy: 'Random sample',
  numberOfLocations: null,
  feePerAudit: null,
  price: null,
  dataFee: null,
  membership: null,
  additionalInformation: '',
};

export const order = {
  openText: null,
  emailInput: '',
  phoneNumber: null,
  to: null,
  clientId: localStorage.getItem('clientId'),
  orderItems: [],
};

export const IndustryReportsTypes = [
  {
    id: 0,
    type: 'Best activations of the month',
    text:
      'Each month, our community reports the best in store activations. Check how your brand performs vs your competitors.',
    price: '$250/report or from $100/month',
  },
  {
    id: 1,
    type: 'Key selling periods',
    text:
      'Each month, our community reports the best in store activations. Check how your brand performs vs your competitors.',
    price: '$250/report',
  },
  {
    id: 2,
    type: 'Omnibus',
    text:
      'Each month, our community reports the best in store activations. Check how your brand performs vs your competitors.',
    price: '$250/report',
  },
  {
    id: 3,
    type: 'Share of Gondola',
    text:
      'Each month, our community reports the best in store activations. Check how your brand performs vs your competitors.',
    price: '$250/report',
  },
];

export const casualPack = {
  id: '0',
  membership: 'Ad-hoc',
  subscriptionTerm: 'Pay as you go',
  price: 0,
  feePerAudit: 0,
  designDataFee: 1000,
  realTimeOnlineDashboard: true,
  digitalOnlineLibrary: true,
  rawDataExtract: true,
  chartsExtract: true,
  fastTrackFee: 500,
  fastCompletionFee: 500,
  insightPresentation: 3000,
  bestDisplay: false,
  allSyndicatedReports: 0,
  omnibus: '',
};

export const silverPack = {
  id: '1',
  membership: 'Silver',
  subscriptionTerm: '$30,000 credits',
  price: 20000,
  feePerAudit: 5,
  designDataFee: 0,
  realTimeOnlineDashboard: true,
  digitalOnlineLibrary: true,
  rawDataExtract: true,
  chartsExtract: true,
  fastTrackFee: 0,
  fastCompletionFee: 0,
  insightPresentation: 2500,
  bestDisplay: false,
  allSyndicatedReports: 5,
  omnibus: '2x FREE',
};

export const goldPack = {
  id: '2',
  membership: 'Gold',
  subscriptionTerm: '$50,000 credits',
  price: 50000,
  feePerAudit: 10,
  designDataFee: 0,
  realTimeOnlineDashboard: true,
  digitalOnlineLibrary: true,
  rawDataExtract: true,
  chartsExtract: true,
  fastTrackFee: 0,
  fastCompletionFee: 0,
  insightPresentation: 2000,
  bestDisplay: true,
  allSyndicatedReports: 10,
  omnibus: '6x FREE',
};

export const platinumPack = {
  id: '3',
  membership: 'Platinum',
  subscriptionTerm: '$100,000 credits',
  price: 100000,
  feePerAudit: 15,
  designDataFee: 0,
  realTimeOnlineDashboard: true,
  digitalOnlineLibrary: true,
  rawDataExtract: true,
  chartsExtract: true,
  fastTrackFee: 0,
  fastCompletionFee: 0,
  insightPresentation: 1500,
  bestDisplay: true,
  allSyndicatedReports: 15,
  omnibus: '12x FREE',
};

export const packs = [casualPack, silverPack, goldPack, platinumPack];

export const header = [
  { step: '1', message: 'What do you want to track?' },
  { step: '2', message: 'Define your campaign settings' },
  { step: '3', message: 'Quote' },
  { step: '4', message: 'Summary' },
];

export const infoExploreCampaignTypes = {
  modules: {
    title: 'The Snooper modules',
    content: `Choose from a list of 14 modules to provide you with a 360 view of your execution in store
    by tracking on-shelf and off-location KPIs.`,
    infoList: [
      'Designed by experts',
      'Best value for money',
      'Ad hoc or Recurring',
      'Quick & Easy to launch',
    ],
  },
  excellence: {
    title: 'Excellence programs',
    content: `Our team of consultants works with you to design the ‘perfect store’ program in line with your specific business objectives.`,
    infoList: ['Consulting approach', '360 excellence KPIs', 'Recurring (recommended)'],
  },
  industryReports: {
    title: 'Industry reports',
    content: `A 12-20 pages insights reports answering important questions about your category.`,
    infoList: ['Designed by experts', 'Best value for money', 'Ad hoc or Recurring'],
  },
  dataSeries: {
    title: 'Data series',
    content: `Recurring data reports tracking a specific KPI of your category received on a weekly or monthly basis.`,
    infoList: ['Consulting approach', '360 excellence KPIs', 'Recurring (recommended)'],
  },
};

export const initialCampaignTypes = [
  {
    id: 1,
    type: 'NPD tracker',
    imageUrl: '',
    text: 'Track your perfect launch (Distribution, On-shelf availability, Planogram etc.)',
    price: 12.5,
    isSelected: false,
    buildOwnMission: false,
  },
  {
    id: 2,
    type: 'Promo display tracker',
    imageUrl: '',
    text: 'Track the execution of your promotional displays over time',
    price: 12.5,
    isSelected: false,
    buildOwnMission: false,
  },
  {
    id: 3,
    type: 'Custom mission',
    imageUrl: '',
    text: `You didn't find what you were looking for in our use case missions?
    Send us a short briefing and one of our team members will be in touch to discuss your objectives and provide you a quote.`,
    price: 0.0,
    isSelected: false,
    buildOwnMission: true,
  },
];

export const selectSegmentTrack = [
  {
    value: 'State',
    label: 'State',
  },
  {
    value: 'Banner',
    label: 'Banner',
  },
];

export const selectCompletionStrategy = [
  {
    value: 'Random sample',
  },
  {
    value: 'Predefined list of stores',
  },
  {
    value: 'Full network',
  },
];

export const initialAddOn = [
  {
    id: 1,
    name: 'Ad-hoc',
    support: 'x',
    report: 'x',
    fastTrackFee: 1000,
    fastCompletionFee: 1000,
    insightReport: 1000,
    addOn: 0,
    isSelected: true,
  },
  {
    id: 2,
    name: 'Silver member',
    support: 'v',
    report: 'x',
    fastTrackFee: 750,
    fastCompletionFee: 750,
    insightReport: 750,
    addOn: 0,
    isSelected: false,
  },
  {
    id: 3,
    name: 'Gold member',
    support: 'v',
    report: 'v',
    fastTrackFee: 500,
    fastCompletionFee: 500,
    insightReport: 500,
    addOn: 0,
    isSelected: false,
  },
  {
    id: 4,
    name: 'Platinum',
    support: 'v',
    report: 'v',
    fastTrackFee: 0,
    fastCompletionFee: 0,
    insightReport: 0,
    addOn: 0,
    isSelected: false,
  },
];

export const selectInsightReport = [{ value: 'yes', label: 'yes' }, { value: 'no', label: 'no' }];

export const storeTypePriceImpact = [
  {
    value: 'Supermarket',
    impact: 0,
  },
  {
    value: 'Pharmacies',
    impact: 2,
  },
  {
    value: 'Petrol & convenience',
    impact: 2,
  },
  {
    value: 'Liquor store',
    impact: 2,
  },
  {
    value: 'Other',
    impact: 2,
  },
];

export const segmentTrackPriceImpact = [
  {
    value: 'Banner',
    impact: 0,
  },
  {
    value: 'State',
    impact: 0,
  },
];

export const completionStrategyPriceImpact = [
  {
    value: 'Random sample',
    impact: 0,
  },
  {
    value: 'Predefined list of stores',
    impact: 2,
  },
  {
    value: 'Full network',
    impact: 4,
  },
];

export const selectStoreOptions = [
  {
    isChecked: false,
    banners: 'Supermarket',
    values: [
      { isChecked: true, banners: 'Coles', amount: 801 },
      { isChecked: true, banners: 'Woolworths', amount: 1019 },
      { isChecked: true, banners: 'IGA', amount: 1083 },
      { isChecked: true, banners: 'Aldi', amount: 513 },
    ],
  },
  {
    isChecked: false,
    banners: 'Pharmacies',
    values: [
      { isChecked: true, banners: 'Chemist warehouse', amount: 380 },
      { isChecked: true, banners: 'Priceline', amount: 175 },
      { isChecked: true, banners: 'Terry white', amount: 112 },
    ],
  },
  {
    isChecked: false,
    banners: 'Petrol & convenience',
    values: [
      { isChecked: true, banners: 'Coles express', amount: 528 },
      { isChecked: true, banners: 'Woolworth petrol', amount: 298 },
      { isChecked: true, banners: 'Caltex Woolworth', amount: 173 },
      { isChecked: true, banners: 'Caltex', amount: 654 },
      { isChecked: true, banners: '7-Eleven', amount: 274 },
    ],
  },
  {
    isChecked: false,
    banners: 'Liquor store',
    values: [
      { isChecked: true, banners: 'BWS', amount: 1328 },
      { isChecked: true, banners: 'Liqourland', amount: 731 },
      { isChecked: true, banners: 'Dan Murphys', amount: 226 },
      { isChecked: true, banners: 'Vintage cellars', amount: 82 },
      { isChecked: true, banners: 'Thirsty camel', amount: 254 },
      { isChecked: true, banners: 'Liqour legends', amount: 143 },
      { isChecked: true, banners: 'IGA ritchies', amount: 64 },
    ],
  },
  {
    isChecked: false,
    banners: 'Other',
    values: [],
  },
];

export const keepPerformanceIndicatorsNPDTracker = [
  {
    id: 'panel1',
    title: 'Distribution',
    question: 'In which percentage of the retail network is your new SKU ranged?',
    context: '',
    metabase: {
      ids: [5901, 5967, 6029, 5968, 5969],
      photos: [],
      insights: [
        'The average distribution at Coles reaches 10% more than at Woolworths at almost 85%. ',
      ],
    },
    restriction: 'Check up to 3 SKUs per mission.',
  },
  {
    id: 'panel2',
    title: 'Availability',
    question: 'Is your new SKU available on shelf? (in stock vs. out of stock)',
    context: '',
    metabase: {
      ids: [5903, 5951, 6030, 5910, 5969],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission.',
  },
  {
    id: 'panel3',
    title: 'Non-discounted price',
    question: 'What is the non-discounted price of your new SKU?',
    context: '',
    metabase: {
      ids: [5915, 5970, 5981],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission.',
  },
  {
    id: 'panel4',
    title: 'Discounted price',
    question: 'If on promotion, what is the discounted price of your new SKU?',
    context: '',
    metabase: {
      ids: [5917, 5924, 5972, 5973, 5996, 5998],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission.',
  },
  {
    id: 'panel5',
    title: 'Position on shelf material',
    question: 'Which POS is displayed next to your new SKU?',
    context:
      'Before launching your mission you will be asked to send us a list of POS and visuals (e.g. aisle fin, wobblers, stickers)',
    metabase: {
      ids: [5925, 5926, 5975, 5976, 6002, 6004],
      photos: [],
      insights: [],
    },
    restriction: 'Check POS around your NPD in 1 store area of your choice.',
  },
  {
    id: 'panel6',
    title: 'Pictures',
    question: 'Get access to a photo library of your NPDs as displayed in store.',
    context: 'Includes close up photos of your NPDs and wide-angle photos of the category.',
    metabase: {
      ids: [],
      photos: [
        'https://s3.ap-southeast-2.amazonaws.com/snooper-photos/campaigns/cFgGGl7E5E/missions/XYtdCE3p18/checkpoints/ffEWXYAh0n/users/U3VinLNGy7/photo_0.jpg',
        'https://s3.ap-southeast-2.amazonaws.com/snooper-photos/campaigns/cFgGGl7E5E/missions/fKuilrM2Nt/checkpoints/xGilxgIiTe/users/3PFoZnyI1j/photo_2.jpg',
        'https://s3.ap-southeast-2.amazonaws.com/snooper-photos/campaigns/cFgGGl7E5E/missions/QvfKEVeogH/checkpoints/9AqSeQWask/users/OsRuhhDAMO/photo_0.jpg',
      ],
    },
    restriction: '',
  },
];

export const keepPerformanceIndicatorsDisplayTracker = [
  {
    id: 'panel1',
    title: 'Display location',
    question: 'Where is your display located in store?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel2',
    title: 'SKUs availability on display',
    question: 'Which SKUs are available on your display?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel3',
    title: 'Level of stock on display',
    question: 'Are any of your key SKUs on display low on stock?',
    context:
      'Provide your MDQ (Minimum Display Quantity) per SKU to track availability issues and optimise volume forecast on future displays.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel4',
    title: 'Position on shelf',
    question: 'Which POS elements are available on display?',
    context:
      'Before launching your mission you will be asked to send us a list of POS and visuals (e.g. aisle fin, wobblers, stickers)',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of your display in store.',
    context:
      'Includes close up photos of your display and wide angle photos showing your display in store.',
    metabase: {
      ids: [],
      photos: [],
    },
    restriction: '',
  },
];

export const keepPerformanceIndicatorsDisplayTrackerSI = [
  {
    id: 'panel1',
    title: 'Display location',
    question: 'Where is your display located in store?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel2',
    title: 'SKUs availability on display',
    question: 'Which SKUs are available on your display?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel3',
    title: 'Level of stock on display',
    question: 'Are any of your key SKUs on display low on stock?',
    context:
      'Provide your MDQ (Minimum Display Quantity) per SKU to track availability issues and optimise volume forecast on future displays.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel4',
    title: 'Position on shelf',
    question: 'Which POS elements are available on display?',
    context:
      'Before launching your mission you will be asked to send us a list of POS and visuals (e.g. aisle fin, wobblers, stickers)',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of your display in store.',
    context:
      'Includes close up photos of your display and wide angle photos showing your display in store.',
    metabase: {
      ids: [],
      photos: [],
    },
    restriction: '',
  },
  {
    id: 'panel6',
    title: 'Shopper insights - Easiness to find',
    question: 'On a scale of 1 to 5, how easy was it to find the display in store?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel7',
    title: 'Shopper insights - Purchase habits',
    question: 'Do you usually buy this product/brand/category of products?',
    context:
      'Snooper will segment your results to understand if your display helps recruit new category buyers or helps drive higher sales within your current shopper base.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel8',
    title: 'Shooper insights - Purchase intent',
    question:
      'On a scale of 1 to 5, how likely would you be to purchase a product from this display?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel9',
    title: 'Shopper insights - Open text',
    question:
      'Explain what you like / dislike about this display; how visible it is in store; why you would or would not buy from this display etc…',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
];

export const keepPerformanceIndicatorsPromoTracker = [
  {
    id: 'panel1',
    title: 'Promoted SKUs',
    question: 'Are your key SKUs clearly identified as being part of the promotion?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission',
  },
  {
    id: 'panel2',
    title: 'POS material',
    question: 'Which POS elements are displayed around your promotion?',
    context:
      'Before launching your mission you will be asked to send us a list of POS and visuals (e.g. aisle fin, wobblers, stickers)',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check POS around your NPD in 1 store area of your choice.',
  },
  {
    id: 'panel3',
    title: 'Discounted price',
    question: 'What is the discounted price of your promo SKUs?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission',
  },
  {
    id: 'panel4',
    title: 'Level of stock',
    question: 'Are any of your hero SKUs out of stock or almost out of stock?',
    context:
      'Provide your MDQ (Minimum Display Quantity) per SKU to assess current stock levels and act upon stores where your promo is at risk or increase sales forecasts of your next promotion.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of your promo in store.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
];

export const keepPerformanceIndicatorsPromoTrackerSI = [
  {
    id: 'panel1',
    title: 'Promoted SKUs',
    question: 'Are your key SKUs clearly identified as being part of the promotion?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission',
  },
  {
    id: 'panel2',
    title: 'POS material',
    question: 'Which POS elements are displayed around your promotion?',
    context:
      'Before launching your mission you will be asked to send us a list of POS and visuals (e.g. aisle fin, wobblers, stickers)',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check POS around your NPD in 1 store area of your choice.',
  },
  {
    id: 'panel3',
    title: 'Discounted price',
    question: 'What is the discounted price of your promo SKUs?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission',
  },
  {
    id: 'panel4',
    title: 'Level of stock',
    question: 'Are any of your hero SKUs out of stock or almost out of stock?',
    context:
      'Provide your MDQ (Minimum Display Quantity) per SKU to assess current stock levels and act upon stores where your promo is at risk or increase sales forecasts of your next promotion.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs per mission',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of your promo in store.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel6',
    title: 'Shopper insights - Purchase habits',
    question: 'Do you usually buy this product/brand/category of products?',
    context:
      'Snooper will help you identify shopper acquisition opportunities among non category buyers, brand switching opportunities within your category or even cannibalisation risks among your own shopper base.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel7',
    title: 'Shopper insights - Visibility',
    question: 'On a scale of 1 to 5, how visible is this promotion on shelf?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel8',
    title: 'Shooper insights - Purchase intent',
    question:
      'On a scale of 1 to 5, how likely would you be to purchase a product from this range if it was not on promotion?On a scale of 1 to 5, how likely would you be to purchase a product from this range if it was not on promotion?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel8',
    title: 'Shooper insights - Purchase intent with promo',
    question:
      'On a scale of 1 to 5, how likely would you be to purchase a product from this range with the promotion offered today?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel9',
    title: 'Shopper insights - Open text',
    question:
      'Explain why you would or would not purchase a product from the promoted range with this promotion.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
];

export const keepPerformanceIndicatorsOutOfStockTracker = [
  {
    id: 'panel1',
    title: 'Level of stock per SKU',
    question:
      'Is the level of stock on your key SKUs below, equal or above your MDQ (Minimum Display Quantity)?',
    context:
      'Provide your MDQ (Minimum Display Quantity) per SKU to track availability issues and optimise volume forecast and share of shelf.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 5 SKUs per mission in 1 store area of your choice.',
  },
  {
    id: 'panel2',
    title: 'Pictures',
    question: 'Get access to a photo library of your products and their level of stock',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photos of your 5 key SKUs and their level of stock and wide angle photos of your brand in shelf.',
  },
];

export const keepPerformanceIndicatorsDistributionTracker = [
  {
    id: 'panel1',
    title: 'Distribution per SKU',
    question: 'Are your hero SKUs ranged in store?',
    context: `You're not only limited to your own SKUs. Why not also check your competitors' SKUs to know where you have distribution gaps?`,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 10 SKUs in 1 store area of your choice.',
  },
  {
    id: 'panel2',
    title: 'Pictures',
    question: 'Get access to a photo library of your key SKUs on shelf.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photos of your 10 key SKUs  and wide angle photos of your brand on shelf.',
  },
];

export const keepPerformanceIndicatorsShareOfShelf = [
  {
    id: 'panel1',
    title: 'PART 1: Share of category',
    question: 'What is the share of shelf of your sub-category within the overall category?',
    context: `Example: Share of colas vs. water vs. energy drinks within beverage category.`,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 3 sub-categories within 1 category.',
  },
  {
    id: 'panel2',
    title: 'PART 2: Share of brand',
    question: 'What is the share of shelf of your brand vs competitors within your sub-category?',
    context: `Example: Share of Redbull vs. V vs. Monster within energy drinks.
      `,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 3 brands or 3 products.',
  },
  {
    id: 'panel3',
    title: 'Pictures',
    question: 'Get access to a photo library of the entire category.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes wide angle photos of each bay and a panorama picture of the entire category.',
  },
];

export const keepPerformanceIndicatorsPlanogramTracker = [
  {
    id: 'panel1',
    title: 'Position on shelf',
    question: 'On which shelves is your product range displayed?',
    context: ``,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track 1 product range',
  },
  {
    id: 'panel2',
    title: 'Facings',
    question: 'How many facing are allocated to your product range?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track 1 product range',
  },
  {
    id: 'panel3',
    title: 'Adjacent brands',
    question: 'Which product ranges are adjacent to yours?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel4',
    title: 'Brand blocking',
    question: 'Is your product range displayed as 1 block?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question:
      'Get access to a photo library of your product range on shelf, as well as its adjacent products.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Includes wide angle photos of your product range and adjacent bays.',
  },
];

export const keepPerformanceIndicatorsBrandedAssetMapping = [
  {
    id: 'panel1',
    title: 'Penetration',
    question: 'Are any of your branded assets available in store?',
    context: `Measure the overall penetration of your brand in the retail network and maintain a list of stores where your assets are located.`,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 3 branded assets per store.',
  },
  {
    id: 'panel2',
    title: 'Type',
    question: 'Which types of branded assets can be found in store? ',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 3 branded assets per store.',
  },
  {
    id: 'panel3',
    title: 'Serial number',
    question: 'Report the serial number of each branded asset found in store.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 3 branded assets per store.',
  },
  {
    id: 'panel4',
    title: 'Quality & Maintenance',
    question: 'Do your branded assets need replacement or maintenance?',
    context:
      'Our community will go through a checklist (e.g. out of order, lights not working, needs cleaning) or can even have a discussion with the staff to know more about the condition of your branded assets.',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of your branded assets.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photo of your branded assets, serial number and wide angle photos covering the main areas of the store.',
  },
];

export const keepPerformanceIndicatorsTouchpointsTracker = [
  {
    id: 'panel1',
    title: 'Touchpoint types',
    question: 'In which store locations (= touchpoints) is your category available?',
    context: `Example: in ambient aisle, on gondola ends, on display stand, at cash registers, at self-checkouts`,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel2',
    title: 'Number of touchpoints',
    question: 'In how many locations in store does your category have a touchpoint?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel3',
    title: 'Your brands share of touchpoint',
    question: 'In how many of these touchpoints was your brand available?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel4',
    title: 'Main competitor',
    question: 'In how many of these touchpoints was your main competitor available?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Select 1 competitor',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of all touchpoints for your category.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photos of category touchpoints and wide angle photos of main areas in store.',
  },
];

export const keepPerformanceIndicatorsPriceCheck = [
  {
    id: 'panel1',
    title: 'Baseline price',
    question: 'What is the baseline (non-discounted) price of product X?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Track up to 10 SKUs (own SKU or competitors SKUs)',
  },
  {
    id: 'panel2',
    title: 'Pictures',
    question: 'Get access to a photo library of all products.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photos showing product and price tag and wide angle photos of product bay when product is not available.',
  },
];

export const keepPerformanceIndicatorsVMtracker = [
  {
    id: 'panel1',
    title: 'Visual merchandising types',
    question: 'Which ones of your visual merchandising elements are available in store?',
    context: 'e.g. window decal, poster on wall, floor decal, sticker, shelf tags, shelf wobblers',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 5 VM elements',
  },
  {
    id: 'panel2',
    title: 'Quality',
    question: 'Are any of your visual merchandising elements damaged and need to be replaced?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 5 VM elements',
  },
  {
    id: 'panel3',
    title: 'Competitor brands',
    question: 'Do any of your competitors have visual merchandising in store?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 competitors',
  },
  {
    id: 'panel4',
    title: 'Competitors VM type',
    question: 'Which type of VM do your competitors have in store',
    context: 'e.g. window decal, poster on wall, floor decal, sticker, shelf tags, shelf wobblers',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of all visual merchandising available in store',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photos of all visual merchandising of brand A and wide angle photos of key areas in store.',
  },
];

export const keepPerformanceIndicatorsVMtrackerSI = [
  {
    id: 'panel1',
    title: 'Visual merchandising types',
    question: 'Which ones of your visual merchandising elements are available in store?',
    context: 'e.g. window decal, poster on wall, floor decal, sticker, shelf tags, shelf wobblers',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 5 VM elements',
  },
  {
    id: 'panel2',
    title: 'Quality',
    question: 'Are any of your visual merchandising elements damaged and need to be replaced?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 5 VM elements',
  },
  {
    id: 'panel3',
    title: 'Competitor brands',
    question: 'Do any of your competitors have visual merchandising in store?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 competitors',
  },
  {
    id: 'panel4',
    title: 'Competitors VM type',
    question: 'Which type of VM do your competitors have in store',
    context: 'e.g. window decal, poster on wall, floor decal, sticker, shelf tags, shelf wobblers',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel5',
    title: 'Pictures',
    question: 'Get access to a photo library of all visual merchandising available in store',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close up photos of all visual merchandising of brand A and wide angle photos of key areas in store.',
  },
  {
    id: 'panel6',
    title: 'Shopper insights - VM Visibility own brand',
    question: `On a scale of 1 to 5, how visible is brand A's visual merchandising compared to other brands?`,
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel7',
    title: 'Shopper insights - VM message',
    question: `Why is brand A VM more or less visible than other brands VM?`,
    context:
      'Shoppers will select from a list of predfined options (eg: creativity, location, size)',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel8',
    title: 'Shopper insights - Open text',
    question: `Comment your answers to the 2 previous questions`,
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
];
export const keepPerformanceIndicatorsParallelDistribution = [
  {
    id: 'panel1',
    title: 'Product distribution',
    question: 'Are your key SKUs available in store?',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Check up to 3 SKUs',
  },
  {
    id: 'panel2',
    title: 'Parallel distribution',
    question: 'Do any of your key SKUs come from a parallel distribution channel?',
    context: `Examples:
    - products suspected to be imported from another country
    - stand alone SKUs sold by the unit while marked as 'not for individual sales' `,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel3',
    title: 'Pictures',
    question: 'Get access to a photo library of key SKUs',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: 'Includes close-up photos of key SKUs.',
  },
];

export const keepPerformanceIndicatorsExpiryDateChecks = [
  {
    id: 'panel1',
    title: 'Expiry date',
    question: 'What is the expiry date on your key SKU?',
    context:
      'Shoppers will  report the expiry date of up to 10 units randomly selected on shelf (at the front and at the back of the shelf).',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel2',
    title: 'Volume expired',
    question: 'How many units of your key SKU display an expiry date in the past?',
    context: ``,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel3',
    title: 'Volume about to expire',
    question: 'How many units of your key SKU will expire within 30 days?',
    context: ``,
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction: '',
  },
  {
    id: 'panel4',
    title: 'Pictures',
    question: 'Get access to a photo library expiry dates on your key SKU.',
    context: '',
    metabase: {
      ids: [],
      photos: [],
      insights: [],
    },
    restriction:
      'Includes close-up photos of 10 units of your key SKU and a wide angle photo of your category.',
  },
];

export const modules = [
  {
    id: 1,
    name: 'NPD tracker',
    imageUrl: NPDTrackerLogo,
    context: `Follow your product launch day after day. Monitor the execution in store and help your field team prioritise their efforts to maximise the ROI on your above and below the line investments and turn every NPD into a success.`,
    price: 9,
    types: ['Execution'],
    useCaseLink: 'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/NPD-tracker.pdf',
    restriction: `Up to 3 SKUs, within one range
      Primary location in store`,
    KPIs: keepPerformanceIndicatorsNPDTracker,
  },
  {
    id: 2,
    name: 'Display tracker',
    imageUrl: NPDTrackerLogo,
    context: `With 75% of shopper making their purchasing decision at the point of sale, having a well executed out-of-shelf strategy is key to win the battle in store. Track your display compliance and understand how it impacting your sales.`,
    price: 9,
    types: ['Execution', 'Shopper insights'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Display-tracker.pdf',
    restriction: `Up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsDisplayTrackerSI,
  },

  {
    id: 3,
    name: 'Display tracker',
    imageUrl: NPDTrackerLogo,
    context: `With 75% of shopper making their purchasing decision at the point of sale, having a well executed out-of-shelf strategy is key to win the battle in store. Track your display compliance and understand how it impacting your sales.`,
    price: 9,
    types: ['Execution'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Display-tracker.pdf',
    restriction: `Up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsDisplayTracker,
  },
  {
    id: 4,
    name: 'Promo tracker',
    imageUrl: NPDTrackerLogo,
    context: `Because promotional activity is usually tied to a strict calendar, its success relies on excellent execution. Whether it's in shelf or off-location, get you real-time visibility on your promo execution and act while it matters.`,
    price: 9,
    types: ['Execution', 'Shopper insights'],
    useCaseLink: null,
    restriction: `Up to 5 SKUs
      1 location`,
    KPIs: keepPerformanceIndicatorsPromoTrackerSI,
  },
  {
    id: 5,
    name: 'Promo tracker',
    imageUrl: NPDTrackerLogo,
    context: `Because promotional activity is usually tied to a strict calendar, its success relies on excellent execution. Whether it's in shelf or off-location, get you real-time visibility on your promo execution and act while it matters.`,
    price: 9,
    types: ['Execution'],
    useCaseLink: null,
    restriction: `Up to 10 SKUs
      Primary location only
      `,
    KPIs: keepPerformanceIndicatorsPromoTracker,
  },
  {
    id: 6,
    name: 'Out of stock tracker',
    imageUrl: NPDTrackerLogo,
    context: `Out of stock is the #1 annoyance for shoppers and can be caused by planogram changes, peak trading hours or suboptimal sales forecasts during key activations. This module gives you the keys to track where and when your brand is at risk.`,
    price: 12,
    types: ['Execution'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Out-of-stock-tracker.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsOutOfStockTracker,
  },
  {
    id: 7,
    name: 'Distribution tracker',
    imageUrl: NPDTrackerLogo,
    context: `Having a 'Top 10' strategy to ensure your hero SKUs are optimally distributed across channels is key to reach your full sales potential. Get a granular assessment (i.e. at store level) of your key SKUs distribution to help you prioritise your field team efforts.`,
    price: 12,
    types: ['Execution'],
    useCaseLink: null,
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsDistributionTracker,
  },
  {
    id: 8,
    name: 'Share of shelf',
    imageUrl: NPDTrackerLogo,
    context: `Declining shelf space can have a negative impact on your performance. With more and more pressure on shelf and the difficulty to maintain control of your own assets (e.g. fridges), keeping track of your share of shelf is key to win the battle in store.`,
    price: 12,
    types: ['Execution'],
    useCaseLink: null,
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsShareOfShelf,
  },
  {
    id: 9,
    name: 'Planogram tracking',
    imageUrl: NPDTrackerLogo,
    context: `With 75% of consumers purchase decision happening in front of the shelf, achieving the 'Perfect Shelf' is your most significant battleground. Get a full picture of your execution on shelf including 'Position on shelf', 'Number of facings', 'Brand blocking' and more.`,
    price: 12,
    types: ['Execution', 'Shopper insights'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Planogram-tracker.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsPlanogramTracker,
  },
  {
    id: 10,
    name: 'Branded asset mapping',
    imageUrl: NPDTrackerLogo,
    context: `Branded assets (e.g. fridges, merchandising) have a huge impact on brand awareness and product distribution across the entire retail network but it can represent a significant amount on your balance sheet. We help you track these assets and prioritise the efforts of your field team in real-time.`,
    price: 12,
    types: ['Execution'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Branded-asset-mapping.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsBrandedAssetMapping,
  },
  {
    id: 11,
    name: 'Touchpoint mapping',
    imageUrl: NPDTrackerLogo,
    context: `Having a 'Multi touchpoints' strategy in store can be really useful to increase visibility or drive impulse purchase. This module gives you the keys to track all your category touchpoints, identify gaps versus competitors and helps you make data-driven decisions to maximise your touchpoints ROI.`,
    price: 12,
    types: ['Execution'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Touchpoint-mapping.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsTouchpointsTracker,
  },
  {
    id: 12,
    name: 'Price checks tracker',
    imageUrl: NPDTrackerLogo,
    context: `With private labels and hard discounter putting more and more pressure on prices and EDLP strategies negatively impacting sales, keeping track of your baseline price is key to design the perfect pricing strategy.`,
    price: 12,
    types: ['Execution'],
    useCaseLink: null,
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsPriceCheck,
  },
  {
    id: 13,
    name: 'VM tracker',
    imageUrl: NPDTrackerLogo,
    context: `A well executed 'Visual Merchandising' (VM) strategy can have a great impact on brand awareness and visibility at the point of purchase. Track your VM compliance and benchmark it with your competitors.`,
    price: 12,
    types: ['Execution', 'Shopper insights'],
    useCaseLink: 'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/VM-tracker.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsVMtrackerSI,
  },
  {
    id: 14,
    name: 'VM tracker',
    imageUrl: NPDTrackerLogo,
    context: `A well executed 'Visual Merchandising' (VM) strategy can have a great impact on brand awareness and visibility at the point of purchase. Track your VM compliance and benchmark it with your competitors.`,
    price: 12,
    types: ['Execution'],
    useCaseLink: 'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/VM-tracker.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsVMtracker,
  },
  {
    id: 15,
    name: 'Parallel distribution',
    imageUrl: NPDTrackerLogo,
    context: `Track distribution of parallel stock (e.g. 'not for individual sales') or foreign imports that have a direct impact on your own sales and reprioritise your field teams efforts to have an impact where it matters.`,
    price: 12,
    types: ['Execution'],
    useCaseLink:
      'https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/Parallel-distribution.pdf',
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsParallelDistribution,
  },
  {
    id: 16,
    name: 'Expiry date tracker',
    imageUrl: NPDTrackerLogo,
    context: `Make sure all stock on shelf complies with Health standards and regulations.`,
    price: 12,
    types: ['Execution'],
    useCaseLink: null,
    restriction: `1 category
      Up to 3 sub-categories
      Up to 3 brands or up to 3 SKUs`,
    KPIs: keepPerformanceIndicatorsExpiryDateChecks,
  },
];

export default null;
