import fan from '../../../../../../../../../services/fan';

const jsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

async function tagPhotoAnswer(submissionId, checkpointId, photoIndex, tagName, tagValue) {
  const result = await fan.put(
    `/submissions/${submissionId}/checkpoints/${checkpointId}/photos/${photoIndex}/tags/${tagName}`,
    JSON.stringify(tagValue),
    jsonConfig,
  );
  return result.data;
}

export default tagPhotoAnswer;
