import React from 'react';
import PropTypes from 'prop-types';
import CampaignSubmissionPhotosContainer from '../photoGallery/campaignSubmissionPhotos.container';

export const getMetabaseDashboardUrl = (metabaseId, metabaseDashboardURL) => {
  if (metabaseDashboardURL) {
    return metabaseDashboardURL;
  }

  if (metabaseId) {
    return `https://metabase.snooper-app.com/public/dashboard/${metabaseId}#bordered=false&titled=false`;
  }

  return null;
};

const MetaBaseGraph = props => {
  const metabaseSrc = getMetabaseDashboardUrl(
    props.record.metabaseId,
    props.record.metabaseDashboardUrl,
  );

  if (metabaseSrc !== null)
    return (
      <iframe
        title="iframe1"
        src={metabaseSrc}
        frameBorder="0"
        width="100%"
        style={{ height: 'calc(87vh - 73px)' }}
      />
    );

  return (
    <CampaignSubmissionPhotosContainer
      {...props}
      testid="gallery"
      id={props.record.id}
      renderDrawer={false}
      drawerHeight="75vh"
      record={props.record}
    />
  );
};

MetaBaseGraph.propTypes = {
  record: PropTypes.shape({
    metabaseId: PropTypes.string,
    metabaseDashboardUrl: PropTypes.string,
    id: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
};

MetaBaseGraph.defaultProps = {
  record: null,
};

export default MetaBaseGraph;
