import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import UseCaseCard from './components/useCaseCard/useCaseCard';
import EmptyList from '../../../industryReports/components/reportCardList/components/emptyList/emptyList';

const styles = {
  container: {
    padding: '4%',
  },
  middle: {
    position: 'fixed',
    top: '45%',
    left: '45%',
    width: '15%',
  },
};

const UseCaseList = ({ ids, data, basePath, total, isLoading }) => {
  const renderList = () =>
    total === 0 ? (
      <EmptyList testid="emptyList" />
    ) : (
      ids.map(id => (
        <Grid key={id} item xs={3}>
          <UseCaseCard testid="reportCard" record={data[id]} basePath={basePath} />
        </Grid>
      ))
    );
  return (
    <Grid container spacing={16} justify="space-evenly" style={styles.container}>
      {isLoading ? (
        <div style={styles.middle}>
          <CircularProgress testid="loader" />
        </div>
      ) : (
        renderList()
      )}
    </Grid>
  );
};

UseCaseList.propTypes = {
  data: PropTypes.shape(PropTypes.any),
  basePath: PropTypes.string,
  ids: PropTypes.arrayOf(PropTypes.any),
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

UseCaseList.defaultProps = {
  data: {},
  ids: [],
  basePath: '',
};

export default UseCaseList;
