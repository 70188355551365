import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import PhotoIcons from '../photoIcons/photoIcons.container';
import photoLogo from '../../../../../../../../../../../assets/images/photos.png';

const styles = {
  image: {
    maxWidth: '100%',
    width: '145px',
    height: '100%',
    objectFit: 'cover',
    position: 'relative',
    cursor: 'pointer',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  imageLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    position: 'absolute',
    width: 'inherit',
    top: 0,
  },
  platformLogo: {
    width: '35.4px',
    height: '35.4px',
  },
};

function imageLoaded(src) {
  const image = document.querySelectorAll(`img[src='${src}']`);
  const img = image[0];
  if (img && !img.complete) {
    return false;
  }
  return true;
}

class Photo extends React.PureComponent {
  state = {
    isLoading: true,
  };

  onImageReload = () => {
    const { checkpointAnswerPhoto, onForceReloadPhoto } = this.props;
    onForceReloadPhoto(checkpointAnswerPhoto.url);
  };

  handleImageLoading = () => {
    const { checkpointAnswerPhoto, handleImageChange } = this.props;
    this.setState({
      isLoading: !imageLoaded(checkpointAnswerPhoto.thumbnailUrl || checkpointAnswerPhoto.url),
    });
    handleImageChange();
  };

  render = () => {
    const { isLoading } = this.state;
    const {
      permissions,
      campaignId,
      openLightbox,
      classes,
      onHandleErrorMessage,
      checkpointAnswerPhoto,
      index,
    } = this.props;
    return (
      <React.Fragment>
        <img
          style={styles.image}
          testid="image"
          src={checkpointAnswerPhoto.thumbnailUrl || checkpointAnswerPhoto.url}
          alt={checkpointAnswerPhoto.shortDescription}
          onClick={openLightbox(index)}
          role="presentation"
          onLoad={this.handleImageLoading}
          onError={e => {
            e.target.src = checkpointAnswerPhoto.url;
            e.target.onerror = null;
          }}
        />
        {isLoading ? (
          <div style={styles.imageLoading}>
            {' '}
            <img testid="logo" src={photoLogo} alt="" style={styles.platformLogo} />
          </div>
        ) : null}
        {permissions ? (
          <GridListTileBar
            testid="hideIcons"
            classes={{
              root: classes.titleBar,
            }}
            actionIcon={
              <PhotoIcons
                photo={checkpointAnswerPhoto}
                submissionId={checkpointAnswerPhoto.userMissionId}
                campaignId={campaignId}
                onHandleErrorMessage={onHandleErrorMessage}
                onImageReload={this.onImageReload}
              />
            }
          />
        ) : null}
      </React.Fragment>
    );
  };
}

Photo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  permissions: PropTypes.bool.isRequired,
  campaignId: PropTypes.string.isRequired,
  openLightbox: PropTypes.func.isRequired,
  onHandleErrorMessage: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  checkpointAnswerPhoto: PropTypes.shape({
    url: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }).isRequired,
  handleImageChange: PropTypes.func.isRequired,
  onForceReloadPhoto: PropTypes.func.isRequired,
};

export default withStyles(styles)(Photo);
