import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = {
  errorMessage: { background: '#ea6960' },
};

export default function ErrorSnackbar({ isOpen, onHandleErrorMessage, message }) {
  return (
    <Snackbar
      testid="snackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={2000}
      onClose={() => onHandleErrorMessage('isError', false)}
      message={message}
      ContentProps={{
        style: styles.errorMessage,
      }}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => onHandleErrorMessage('isError', false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}

ErrorSnackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHandleErrorMessage: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
