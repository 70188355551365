import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PropTypes } from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '../../../../../assets/images/infoIcon.png';
import MissionPackRow from '../missionPackRow/missionPackRow';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: 'unset',
  },
  tableHeadHeight: {
    '@media (min-width: 700px)': {
      height: '15vh',
    },
    '@media (min-width: 1500px)': {
      height: '10vh',
    },
  },
  fontBold: {
    fontWeight: 500,
  },
  listItem: {
    display: 'list-item',
    marginLeft: 20,
  },
  logo: {
    maxHeight: '12px',
    opacity: '0.7',
  },
  table: {
    marginTop: '25px',
  },
};

class PackageTableHead extends React.PureComponent {
  renderInfoIcon = () => (
    <TableCell
      classes={{ root: this.props.classes.tableCell }}
      align="right"
      component="th"
      scope="row"
    >
      <Tooltip disableFocusListener disableTouchListener title="Info about ...">
        <img testid="infoIcon" src={InfoIcon} alt="" style={styles.logo} />
      </Tooltip>
    </TableCell>
  );
  render() {
    const { classes } = this.props;
    return (
      <Table testid="table" size="small" style={styles.table}>
        <TableHead>
          <TableRow classes={{ root: classes.tableHeadHeight }}>
            <TableCell classes={{ root: classes.tableCell }} align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          <MissionPackRow align="left">
            <Typography style={styles.fontBold}> Store audits/ Shopper insights </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography testid="feePerAudit"> Fee per audit/respondent </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Real-time online dashboard </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Digital online library </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Raw data extract (.xls,.pdf) </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography testid="chartsExtract" style={styles.listItem}>
              Charts extracts (.xls,.pdf)
            </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Fast track fee </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Insights presentation </Typography>
          </MissionPackRow>
          <MissionPackRow align="left">
            <Typography style={styles.fontBold}> Industry reports </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Best display of the month </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> All syndicated reports </Typography>
          </MissionPackRow>
          <MissionPackRow infoIcon={this.renderInfoIcon()} align="left">
            <Typography style={styles.listItem}> Omnibus </Typography>
          </MissionPackRow>
        </TableBody>
      </Table>
    );
  }
}

PackageTableHead.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(PackageTableHead);
