import React from 'react';
import { Filter, TextInput, SelectArrayInput } from 'react-admin';
import optionRenderer from '../../../services/optionRender.service';
import { topics, industries, types } from '../../../config';

const styles = {
  filterWidth: {
    minWidth: 100,
  },
  toolbar: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    background: 'white',
  },
};

const IndustryReportFilter = props => (
  <Filter {...props} style={styles.toolbar}>
    <TextInput label="name" source="name" alwaysOn />
    <SelectArrayInput
      testid="syndicatedReportType"
      label="type"
      source="type"
      choices={types.map(id => ({ id, name: id }))}
      style={styles.filterWidth}
      options={{ renderValue: optionRenderer('items'), autoWidth: true, variant: 'outlined' }}
      alwaysOn
    />
    <SelectArrayInput
      testid="syndicatedReportIndustry"
      label="industry"
      source="industry"
      choices={industries.map(id => ({ id, name: id }))}
      style={styles.filterWidth}
      options={{ renderValue: optionRenderer('items'), autoWidth: true, variant: 'outlined' }}
      alwaysOn
    />
    <SelectArrayInput
      testid="syndicatedReportTopic"
      label="topic"
      source="topic"
      choices={topics.map(id => ({ id, name: id }))}
      style={styles.filterWidth}
      options={{ renderValue: optionRenderer('items'), autoWidth: true, variant: 'outlined' }}
      alwaysOn
    />
  </Filter>
);

export default IndustryReportFilter;
