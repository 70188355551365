import React, { cloneElement } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import WiserLogo from '../../../../assets/images/logos/wiser-logo-dark-background.png';
import { isOrderingReleased } from '../../../../setup/featureFlipping';

const styles = {
  logo: {
    marginLeft: 5,
    width: '100%',
    height: '64px',
    maxHeight: '100%',
  },
  appBar: {
    zIndex: 10,
  },
  logout: {
    fontSize: '12px',
  },
  accountCircle: { color: 'white' },
  tabsRoot: {
    color: 'white',
    marginRight: '10px',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabRoot: {
    color: '#fff',
  },
  tabSelected: {
    marginLeft: 10,
    backgroundColor: 'rgba(0, 170, 255, 0.5);',
  },
  shoppingCart: {
    color: 'white',
    height: 24,
  },
  labelIcon: {
    minHeight: 'unset',
  },
  textColorInherit: {
    opacity: 1,
  },
  wrapper: {
    height: '64px',
  },
  noDisplay: {
    display: 'none',
  },
};

const Logo = () => (
  <a href="#/">
    <img src={WiserLogo} alt="Wiser" style={styles.logo} />
  </a>
);

class MyAppBar extends React.PureComponent {
  state = {
    tabSelected: '',
    menuItem: null,
  };

  componentDidMount = () => {
    const tabSelected = this.getTabSelected();
    if (tabSelected !== this.state.tabSelected) {
      this.setState({ tabSelected });
    }
    document.title = 'Retail Intelligence - Dashboard';
  };

  componentDidUpdate = () => {
    const tabSelected = this.getTabSelected();
    if (tabSelected !== this.state.tabSelected) {
      this.setState({ tabSelected });
    }
  };

  getTabSelected = () => {
    const { router } = this.props;
    if (router === '/campaigns') {
      return 'campaigns';
    }
    if (router.includes('/explore') && !router.includes('industry-reports/explore')) {
      return 'explore';
    }
    if (router === '/home') {
      return 'home';
    }
    if (router === '/shopping-cart') {
      return 'shopping-cart';
    }
    if (router === '/home') {
      return '';
    }
    return 'campaigns';
  };

  getMyMissionSelected = () => {
    const { tabSelected } = this.state;
    if (tabSelected === 0) {
      return true;
    }
    return false;
  };

  handleChange = () => {
    const tabSelected = this.getTabSelected();
    this.setState({ tabSelected });
  };

  handleMenu = event => {
    this.setState({ menuItem: event.currentTarget });
  };

  render() {
    const { classes, logout } = this.props;
    const { tabSelected, menuItem } = this.state;

    return (
      <React.Fragment>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Grid item container alignItems="center">
              <Grid item xs={3} md={1} lg={1} container justify="flex-start">
                <Logo testid="logo" id="react-admin-title" />
              </Grid>
              <Grid item xs={9} lg={11}>
                <Tabs
                  testid="tabs"
                  value={tabSelected}
                  onChange={this.handleChange}
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                  }}
                >
                  <Grid item xs={10}>
                    {isOrderingReleased ? (
                      <Tab
                        label="Home"
                        href="/#/home"
                        value="campaigns"
                        selected={tabSelected === 'home'}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                          wrapper: classes.wrapper,
                        }}
                      />
                    ) : null}
                    <Tab
                      label="Campaigns"
                      href="/#/campaigns?order=DESC&page=1&perPage=10&sort=startDate"
                      value="campaigns"
                      selected={tabSelected === 'campaigns'}
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                        wrapper: classes.wrapper,
                      }}
                    />
                    {isOrderingReleased ? (
                      <Tab
                        label="Explore"
                        href="/#/explore/all"
                        value="explore"
                        selected={tabSelected === 'explore'}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                          wrapper: classes.wrapper,
                        }}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={1} lg={2} container alignItems="center" justify="flex-end">
                    <Tooltip title="Profile">
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                      >
                        <AccountCircle testid="profile" style={styles.accountCircle} />
                      </Button>
                    </Tooltip>

                    <Menu
                      id="simple-menu"
                      anchorEl={menuItem}
                      keepMounted
                      open={Boolean(menuItem)}
                      onClose={() => this.setState({ menuItem: null })}
                    >
                      <MenuItem testid="logout" onClick={() => this.setState({ menuItem: null })}>
                        {cloneElement(logout, { style: styles.logout })}
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Tabs>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

MyAppBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  logout: PropTypes.shape(PropTypes.any).isRequired,
  router: PropTypes.string.isRequired,
};

export const AppBarPresenter = withStyles(styles)(MyAppBar);

const getRoutePath = state => ({
  router: state.router.location.pathname,
});

export default connect(getRoutePath)(AppBarPresenter);
