import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = {
  divider: { margin: '18px 0px' },
  marginLocations: { marginBottom: 10 },
};

export default class AdditionalInformation extends React.PureComponent {
  handleChangeOpenText = event => {
    const { quoteOrder, onQuoteOrderChange } = this.props;

    const order = { ...quoteOrder, additionalInformation: event.target.value };
    return onQuoteOrderChange(order);
  };
  render() {
    const { quoteOrder } = this.props;
    const { additionalInformation } = quoteOrder;

    return (
      <React.Fragment>
        <Grid item container justify="space-between">
          <Typography variant="h6" testid="title">
            Additional information
          </Typography>
        </Grid>
        <Grid item container direction="column">
          <Grid container alignItems="center" style={styles.marginLocations}>
            <Grid item xs={12}>
              <TextField
                testid="openText"
                id="outlined-full-width"
                label="Please share any additional information relevant to your campaign"
                placeholder="context, channel, brand, product, etc"
                onChange={this.handleChangeOpenText}
                value={additionalInformation}
                fullWidth
                margin="normal"
                variant="outlined"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

AdditionalInformation.propTypes = {
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  onQuoteOrderChange: PropTypes.func.isRequired,
};
