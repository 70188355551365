import fan from '../../../services/fan';

async function mailRequest(campaignRequestWithContent) {
  await fan.post(`/order/campaign`, JSON.stringify(campaignRequestWithContent), {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'campaign-type': 'request',
    },
  });
}

export default mailRequest;
