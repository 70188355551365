import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import Empty from '../../../../../../assets/images/Empty.png';

const styles = {
  emptyBasket: { marginLeft: 20 },
  logo: {
    height: 50,
  },
  middle: {
    position: 'fixed',
    top: '45%',
    left: '45%',
    width: '15%',
  },
};

const ShoppingCartEmpty = () => (
  <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    spacing={8}
    style={styles.middle}
  >
    <Grid item>
      <Typography testid="emptyBasket" style={styles.emptyBasket}>
        Your shopping cart is empty
      </Typography>
    </Grid>
    <Grid item>
      <img testid="logo" src={Empty} alt="" style={styles.logo} />
    </Grid>
  </Grid>
);

export default ShoppingCartEmpty;
