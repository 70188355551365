import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = {
  image: {
    height: 'auto',
    width: '100%',
  },
  header: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#33475b',
  },
};

export default function GalleryPreview({ images }) {
  return (
    <React.Fragment>
      <Typography testid="title" gutterBottom paragraph style={styles.header}>
        Photo gallery previews
      </Typography>
      <Grid container spacing={16}>
        {images.map(image => (
          <Grid item xs={12} md={8} lg={6}>
            <img testid="image" src={image} alt="" style={styles.image} />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}

GalleryPreview.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};
