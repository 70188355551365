import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AUTH_LOGIN } from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import login, { readEncodedUserFromLocalStorage } from '../../authentication/AuthProvider';
import Authentication from './authentication.presenter';
import { trackSegmentEvents } from '../components/segment/segmentEventTrack';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    color: 'white',
  },
  logInLink: {
    marginTop: theme.spacing.unit * 2,
  },
  forgot: {
    fontSize: '11px',
    marginTop: 1,
  },
});

const getErrorMessage = (name, value) => {
  if (!value) {
    return 'Required';
  }
  if (name === 'password' && value.length < 4) {
    return 'Password length must be superior to 4 characters';
  }
  if (name === 'username' && value.length < 1) {
    return 'Username is not valid';
  }
  return undefined;
};

export class MyLoginPage extends PureComponent {
  state = {
    username: undefined,
    password: undefined,
    usernameError: undefined,
    passwordError: undefined,
    serverError: undefined,
    isLoading: false,
  };

  onInputChange = event => {
    const { value, name } = event.target;
    const error = getErrorMessage(name, value);
    let payload = {
      [name]: value,
    };
    if (!error) {
      payload = {
        [name]: value,
        [`${name}Error`]: undefined,
      };
    }
    this.setState(payload);
  };

  onBlur = event => {
    const { name } = event.target;
    this.setState(prevState => ({
      [`${name}Error`]: getErrorMessage(name, prevState[name]),
    }));
  };

  onSubmit = async event => {
    const { username, password } = this.state;
    const { history } = this.props;
    event.preventDefault();
    const fields = Object.keys(this.state).filter(f => !f.endsWith('Error') && f !== 'isLoading');
    const errorMessages = fields.map(f => getErrorMessage(f, this.state[f]));
    const canSubmit = errorMessages.every(e => e == null);

    if (!canSubmit) {
      const payload = fields.reduce(
        (acc, name) => ({
          ...acc,
          [`${name}Error`]: getErrorMessage(name, this.state[name]),
        }),
        {},
      );
      this.setState(payload);
      return;
    }
    try {
      this.setState({
        isLoading: true,
      });
      await login(AUTH_LOGIN, { username, password });
      this.setState({
        isLoading: false,
      });
      history.push({
        pathname: '/',
      });
      const { email, userName, id } = readEncodedUserFromLocalStorage();
      trackSegmentEvents('loginSuccess', { email, userName, id });
    } catch (error) {
      this.setState({
        isLoading: false,
        serverError: error.message,
      });
      const loginErrorExceptionText =
        'com.insightquest.snooper.fan.core.users.exceptions.FailedLoginException';
      if (error.body && error.body.type === loginErrorExceptionText) {
        trackSegmentEvents('loginFail', { email: username });
      }
    }
  };

  clearServerError = () => {
    this.setState(() => ({
      serverError: undefined,
    }));
  };

  renderButton() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return <CircularProgress className={classes.submit} />;
    }
    return (
      <Button
        id="submitButton"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={this.onSubmit}
      >
        Sign In
      </Button>
    );
  }
  render() {
    const { classes } = this.props;
    const { usernameError, username, password, passwordError, serverError } = this.state;

    return (
      <Authentication title="Retail Intelligence">
        <form className={classes.form} id="form">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <TextField
                onChange={this.onInputChange}
                value={username}
                helperText={usernameError}
                error={!!usernameError}
                inputProps={{
                  onBlur: this.onBlur,
                }}
                autoComplete="fname"
                name="username"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.onInputChange}
                value={password}
                helperText={passwordError}
                error={!!passwordError}
                inputProps={{
                  onBlur: this.onBlur,
                }}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Link className={classes.forgot} href="#/reset-password" variant="body2">
                Forgot your password ?
              </Link>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.logInLink}>
            <Grid item />
          </Grid>
          {this.renderButton()}
          <Snackbar
            testId="serverErrorSnackbar"
            open={!!serverError}
            autoHideDuration={5000}
            disableWindowBlurListener
            onClose={this.clearServerError}
            message={serverError}
          />
        </form>
      </Authentication>
    );
  }
}

MyLoginPage.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};
export default withRouter(withStyles(styles)(MyLoginPage));
