export const GET_PHOTOS_BY_CAMPAIGN = 'GET_PHOTOS_BY_CAMPAIGN';
export const UPDATE_TAG_PHOTO = 'UPDATE_TAG_PHOTO';

export const updatePhotosByCampaign = (campaignId, photos) => ({
  type: GET_PHOTOS_BY_CAMPAIGN,
  photos,
  campaignId,
});

export const updatePhoto = (campaignId, photo) => ({
  type: UPDATE_TAG_PHOTO,
  campaignId,
  photo,
});
