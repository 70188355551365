import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: 'unset !important',
  },
  tableRow: {
    height: '36px',
  },
};

const MissionPackRow = withStyles(styles)(({ classes, children, infoIcon, align }) => (
  <TableRow classes={{ root: classes.tableRow }}>
    <TableCell
      testid="tableCell"
      classes={{ root: classes.tableCell }}
      align={align}
      component="th"
      scope="row"
    >
      {children}
    </TableCell>
    {infoIcon}
  </TableRow>
));

MissionPackRow.propTypes = {
  children: PropTypes.shape(PropTypes.any),
  infoIcon: PropTypes.func,
  align: PropTypes.string,
};

MissionPackRow.defaultProps = {
  infoIcon: null,
  align: 'center',
  children: null,
};

export default MissionPackRow;
