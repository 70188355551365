import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import CheckboxQuote from '../checkboxQuote/checkboxQuote';
import { ChannelsTooltip } from '../../../components/tooltips/tooltips';
import { Event } from '../../../../../../setup/analytics';

const styles = {
  error: { color: '#f6675e' },
  root: {
    backgroundColor: '#ffffff',
    width: '100%',
    padding: 20,
    borderRadius: '4px',
    boxShadow: ' 0 0 11px 5px rgba(234, 240, 246, 0.5)',
    marginTop: 30,
  },
};

class ChannelSelection extends React.PureComponent {
  handleChangeCheckboxStores = storeSelections => {
    const {
      onSelectStoreTypeChange,
      onQuoteOrderChange,
      quoteOrder,
      getOrderIfFullNetworkCompletion,
    } = this.props;

    const { completionStrategy } = quoteOrder;

    const order = getOrderIfFullNetworkCompletion(completionStrategy);
    let storeTypes = [];
    let banners = [];
    const getStoreSelections = storeSelections.filter(storeSelection => storeSelection.isChecked);
    onSelectStoreTypeChange(storeSelections);

    if (getStoreSelections.length > 0) {
      storeTypes = getStoreSelections.map(value => value.banners);
      banners = getStoreSelections[0].values.map(value => {
        if (value.isChecked) {
          return value.banners;
        }
        return null;
      });
    }
    Event('Editing', `Channel selection: ${storeTypes}`, `Explore modules, scope`);

    onQuoteOrderChange({ ...order, storeTypes, banners });
  };

  renderHelperText = () => {
    const { quoteOrder, calculateIsClicked } = this.props;
    const { storeTypes } = quoteOrder;

    if (storeTypes.length > 0) {
      return null;
    }

    if (calculateIsClicked) {
      return (
        <FormHelperText testid="error" style={styles.error} id="component-error-text">
          Please select one
        </FormHelperText>
      );
    }
    return null;
  };

  render = () => {
    const { storeTypes } = this.props;
    return (
      <Grid container style={styles.root} spacing={16}>
        <Grid item container alignItems="center">
          <Grid item>
            <Typography variant="h6" testid="title">
              Which channels do you want to audit?
            </Typography>
          </Grid>
          <Grid item>
            <ChannelsTooltip testid="tooltip" />
          </Grid>
        </Grid>
        {this.renderHelperText()}
        <Grid item container direction="column">
          <FormControl required>
            <Grid item>
              <CheckboxQuote
                testid="checkboxQuote"
                onHandleChangeCheckbox={this.handleChangeCheckboxStores}
                selectOptions={storeTypes}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    );
  };
}

ChannelSelection.propTypes = {
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  onQuoteOrderChange: PropTypes.func.isRequired,
  calculateIsClicked: PropTypes.bool.isRequired,
  storeTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSelectStoreTypeChange: PropTypes.func.isRequired,
  getOrderIfFullNetworkCompletion: PropTypes.func.isRequired,
};

export default ChannelSelection;
