import React from 'react';
import PropTypes from 'prop-types';
import { TextField, ShowButton } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { CardContent, CardHeader, CardMedia, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  price: {
    color: '#00C7FC',
  },
  cardHeader: {
    height: '50px',
    display: 'inline-block',
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
  },
  type: {
    marginLeft: '10px',
    textTransform: 'uppercase',
  },
  message: {
    height: '20%',
  },
  logo: {
    maxHeight: '35px',
  },
  cardStyle: {
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    padding: '3px',
  },
  center: { textAlign: 'center' },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  listItem: {
    display: 'list-item',
    marginLeft: '15px',
    fontSize: '11px',
  },
  shortDescription: {
    color: 'grey',
    fontSize: '12px',
    height: 200,
  },
  chip: {
    position: 'absolute',
    top: '10px',
    color: 'rgba(253, 251, 251, 0.87)',
    borderRadius: '2px',
    backgroundColor: '#53bf70',
  },
  topicWeight: { fontWeight: '500' },
  topicsContent: {
    height: 65,
  },
  item: { color: 'grey' },
};
class UseCaseCard extends React.PureComponent {
  onOpen = event => {
    const { record, open } = this.props;
    event.preventDefault();
    open(record.link);
  };

  render() {
    const { classes, record, basePath } = this.props;
    return (
      <Card className={classes.cardStyle}>
        <Chip testid="chip" label={record.industry} classes={{ root: classes.chip }} />
        <CardMedia testid="image" className={classes.media} image={record.imageUrl} title="" />
        <CardHeader
          testid="header"
          title={<TextField record={record} source="name" className={classes.title} />}
          className={classes.cardHeader}
        />
        <CardContent>
          <TextField
            testid="content"
            record={record}
            source="shortDescription"
            className={classes.shortDescription}
          />
        </CardContent>
        <CardContent className={classes.topicsContent}>
          <p testid="typeContent">
            <span testid="typeContent" style={styles.topicWeight}>
              Type:
            </span>
            &nbsp;
            {record.type}
          </p>
          <p testid="topicContents">
            <span testid="topicContent" style={styles.topicWeight}>
              Topic:
            </span>
            <span style={styles.item}>
              &nbsp;
              {record.topic}
            </span>
          </p>
        </CardContent>
        <CardActions style={styles.center}>
          <ShowButton
            testid="showButton"
            label="Open"
            icon={null}
            color="primary"
            variant="contained"
            resource="industry-reports"
            basePath={basePath}
            record={record}
            onClick={this.onOpen}
          />
        </CardActions>
      </Card>
    );
  }
}

UseCaseCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  record: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    industry: PropTypes.string,
    topics: PropTypes.array,
    types: PropTypes.string,
    link: PropTypes.string,
    imageUrl: PropTypes.number,
    shortDescription: PropTypes.string,
  }),
  basePath: PropTypes.string.isRequired,
  open: PropTypes.func,
};

UseCaseCard.defaultProps = {
  record: PropTypes.objectOf({
    id: 0,
    name: '',
    industry: '',
    syndicatedReportTopic: '',
    types: '',
    category: '',
    shortDescription: '',
    imageUrl: '',
    startDate: '',
    endDate: '',
    childrenIds: null,
  }),
  open: window.open,
};

export default withStyles(styles)(UseCaseCard);
