import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';
import PackageTable from './components/packageTable/packageTable';
import PackageTableHead from './components/packageTableHead/packageTableHead';
import { casualPack, silverPack, goldPack, platinumPack } from '../variables/variables';
import { getCurrentMembership } from '../../services/user.service';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: '0px 10px 1px 10px',
    width: '14%',
  },
  container: { padding: 15 },
  tableHeader: {
    width: '16%',
    borderBottom: 'none',
    padding: '0px 10px 1px 25px',
  },
  tableRoot: {
    padding: 30,
    marginTop: 40,
  },
};

class Packs extends React.PureComponent {
  state = {
    packSelected: '',
  };

  async componentDidMount() {
    const packSelected = await getCurrentMembership();
    this.setState({ packSelected });
  }

  onSelectPack = packSelected => () => {
    this.setState({ packSelected: packSelected.membership });
  };

  render() {
    const { classes } = this.props;
    const { packSelected } = this.state;
    return (
      <div style={styles.container}>
        <Table testid="table" size="small">
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: classes.tableHeader }} component="th" scope="row">
                <PackageTableHead testid="packageTableHead" />
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} component="th" scope="row">
                <PackageTable
                  testid="casualPack"
                  packageType={casualPack}
                  onSelectPack={this.onSelectPack}
                  packSelected={casualPack.membership === packSelected}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} component="th" scope="row">
                <PackageTable
                  testid="silverPack"
                  packageType={silverPack}
                  onSelectPack={this.onSelectPack}
                  packSelected={silverPack.membership === packSelected}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} component="th" scope="row">
                <PackageTable
                  testid="goldPack"
                  packageType={goldPack}
                  onSelectPack={this.onSelectPack}
                  packSelected={goldPack.membership === packSelected}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} component="th" scope="row">
                <PackageTable
                  testid="platinumPack"
                  packageType={platinumPack}
                  onSelectPack={this.onSelectPack}
                  packSelected={platinumPack.membership === packSelected}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

Packs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Packs);
