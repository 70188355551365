import React from 'react';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = {
  backButton: { color: '#03a6e9' },
  sneakPeakButton: {
    height: '41px',
    width: '151px',
    borderRadius: '4px',
    backgroundColor: '#b6bdc3',
    color: '#ffffff',
    textTransform: 'Capitalize',
  },
  fullReportButton: {
    height: '41px',
    width: '151px',
    borderRadius: '4px',
    backgroundColor: '#4add3e',
    color: '#ffffff',
    fontSize: '13px',
    textTransform: 'Capitalize',
  },
  container: { backgroundColor: '#fff3ee', padding: 10 },
};

// eslint-disable-next-line no-shadow
export const TitlePresenter = ({ goBack, record, openSendRequest }) => (
  <Grid
    container
    direction="row"
    justify="space-between"
    alignItems="center"
    style={styles.container}
  >
    <Grid item xs={7} container alignItems="center">
      <IconButton testid="backButton" onClick={goBack}>
        <ArrowBackIos style={styles.backButton} />
      </IconButton>
      <Typography testid="title" variant="h5">
        {record.name}
      </Typography>
    </Grid>
    <Grid item xs={5} container alignItems="center" justify="flex-end" spacing={16}>
      <Grid item>
        <Button testid="fullReport" style={styles.fullReportButton} onClick={openSendRequest}>
          Get full report
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

TitlePresenter.propTypes = {
  goBack: PropTypes.func.isRequired,
  record: PropTypes.shape({
    name: PropTypes.string,
  }),
  openSendRequest: PropTypes.func.isRequired,
};

TitlePresenter.defaultProps = {
  record: null,
};

export default connect(
  null,
  {
    goBack,
  },
)(TitlePresenter);
