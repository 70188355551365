import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import ModulesList from './components/modulesList';
import Ordering from '../../ordering/ordering';

const styles = {
  orderingHeight: {
    height: '98%',
  },
};

class Modules extends PureComponent {
  state = {
    activeStep: 0,
    selectedModule: null,
  };

  onSelectModule = id =>
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
      selectedModule: id,
    }));

  goToStep = step => {
    this.setState({ activeStep: step });
  };

  render() {
    const { activeStep, selectedModule } = this.state;
    const { classes } = this.props;
    switch (activeStep) {
      case 0:
        return (
          <Grid>
            <ModulesList testid="modulesList" onSelectModule={this.onSelectModule} />
          </Grid>
        );
      case 1:
        return (
          <Grid className={classes.orderingHeight}>
            <Ordering
              testid="ordering"
              onGoToStep={this.goToStep}
              selectedModule={selectedModule}
            />
          </Grid>
        );
      default:
        return null;
    }
  }
}

Modules.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Modules);
