import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import NPDTracker from '../../../../../../assets/images/NPDtracker.png';
import { Event } from '../../../../../../setup/analytics';
import HASQUOTE from '../../../../../../setup/featureFlipping';

const styles = {
  container: {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 0 11px 5px rgba(234, 240, 246, 0.5)',
    height: '380px',
    width: '280px',
    padding: '15px',
  },
  logo: {
    height: '40px',
  },
  evenChip: {
    borderRadius: '10px',
    backgroundColor: '#107783',
    height: '21px',
    color: '#fff',
  },
  oddChip: {
    borderRadius: '10px',
    backgroundColor: '#ef3d79',
    height: '21px',
    color: '#fff',
  },
  button: {
    color: '#fff',
    width: '100px',
  },
  useCaseLink: {
    textDecoration: 'unset',
    color: '#00c7fc',
  },
  price: {
    fontSize: '28px',
    fontWeight: 700,
    color: '#33475b',
  },
  dollar: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#33475b',
  },
  fromStore: {
    fontSize: '11px',
    fontWeight: 'unset',
    color: '#33475b',
  },
  context: {
    height: '150px',
  },
  title: {
    width: '200px',
  },
};

class ModuleCard extends React.PureComponent {
  handleSelectCard = () => {
    const { campaign, onSelect } = this.props;
    const { id, name } = campaign;
    Event('Click select', `Choose module: ${name}`, 'Explore modules');
    onSelect(id);
  };
  renderChips = () => {
    const { campaign } = this.props;
    const { types } = campaign;
    return types.map((type, index) => (
      <Grid item>
        <Chip
          testid="type"
          label={type}
          style={index % 2 === 0 ? styles.evenChip : styles.oddChip}
        />
      </Grid>
    ));
  };
  render() {
    const { campaign } = this.props;
    const { name, context, price, useCaseLink } = campaign;
    return (
      <Grid item container alignItems="center" justify="space-evenly" style={styles.container}>
        <Grid item xs={12} container direction="row" alignItems="center" spacing={8}>
          <Grid item>
            <img testid="logo" src={NPDTracker} alt="" style={styles.logo} />
          </Grid>
          <Grid item style={styles.title}>
            <Typography testid="name" variant="h5">
              {name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={8}>
          {this.renderChips()}
        </Grid>
        <Grid item xs={12} style={styles.context}>
          <Typography testid="context" gutterBottom>
            {context}
          </Typography>
        </Grid>
        <Grid item container justify="flex-end" alignItems="center">
          <Grid item xs={12} md={6}>
            {useCaseLink ? (
              <a
                testid="useCase"
                target="_blank"
                rel="noopener noreferrer"
                href={useCaseLink}
                style={styles.useCaseLink}
              >
                view use case
              </a>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} container justify="flex-end" alignItems="center">
            {HASQUOTE ? (
              <Grid item xs={6} container alignItems="flex-start" spacing={8}>
                <Grid item>
                  <Typography testid="price" style={styles.price}>
                    <span style={styles.dollar}>$</span>
                    {price}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={styles.fromStore}>From </Typography>
                  <Typography style={styles.fromStore}>per store</Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid>
              <Button
                testid="select"
                color="secondary"
                onClick={this.handleSelectCard}
                variant="contained"
                style={styles.button}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ModuleCard.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    types: PropTypes.array,
    context: PropTypes.string,
    price: PropTypes.number,
    imageUrl: PropTypes.string,
    useCaseLink: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ModuleCard;
