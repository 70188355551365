import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

const styles = {
  font: { marginBottom: '-10px' },
};

export default function SegmentsFilter({
  selectedSegmentsByCategories,
  segmentsByCategories,
  onHandleChange,
}) {
  const onHandleChangeSegment = segmentName => selectedValues =>
    onHandleChange('selectedSegmentsByCategories', {
      ...selectedSegmentsByCategories,
      [segmentName]: selectedValues,
    });

  return (
    <React.Fragment>
      <ListItem>
        <h4 style={styles.font}> Filter by segment </h4>
      </ListItem>
      {segmentsByCategories.map((segmentByCategory, index) => (
        <ListItem key={index}>
          <FilterSelect
            testid={`segment${index + 1}`}
            segmentValues={[
              [...segmentByCategory[Object.keys(segmentByCategory)[0]]].sort((a, b) =>
                a.toLowerCase().localeCompare(b.toLowerCase()),
              ),
            ]}
            segmentName={Object.keys(segmentByCategory)}
            onHandleChangeFilter={onHandleChangeSegment(Object.keys(segmentByCategory))}
            segmentValue={
              selectedSegmentsByCategories[Object.keys(segmentByCategory)]
                ? selectedSegmentsByCategories[Object.keys(segmentByCategory)]
                : []
            }
          />
        </ListItem>
      ))}
    </React.Fragment>
  );
}

SegmentsFilter.propTypes = {
  segmentsByCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSegmentsByCategories: PropTypes.shape({}).isRequired,
  onHandleChange: PropTypes.func.isRequired,
};
