import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import WiserLogo from '../../../assets/images/logos/wiser-logo-light-background.png';

import { simpleSnooperTheme } from '../../../theme/snooperTheme';

const imageBackground = {
  padding: 0,
  height: '100vh',
  background: 'rgba(0,113,188,0.9)',
};

const styles = theme => ({
  background: {
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  main: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: simpleSnooperTheme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    color: 'white',
  },
  logInLink: {
    marginTop: theme.spacing.unit * 2,
  },
  forgot: {
    fontSize: '11px',
    marginTop: 1,
  },
  logo: {
    flex: 1,
    width: '80%',
    height: 'auto',
  },
});

function Authentication({ children, title, classes }) {
  useEffect(() => {
    document.title = `Retail Intelligence - ${title === 'Retail Intelligence' ? 'Sign in' : title}`;
  }, []);

  return (
    <MuiThemeProvider theme={simpleSnooperTheme}>
      <div className={classes.background} style={imageBackground} testid="backgroundImage">
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <img src={WiserLogo} alt="Wiser" className={classes.logo} testId="wiserLogo" />
            <Typography component="h1" variant="h5" testid="title">
              {title}
            </Typography>
            {children}
          </Paper>
        </main>
      </div>
    </MuiThemeProvider>
  );
}

Authentication.propTypes = {
  classes: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Authentication);
