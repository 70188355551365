export const selectIntercomUserSettings = user => {
  if (!user) {
    return undefined;
  }

  const { firstName, lastName, email, createdAt } = user;

  const settings = {
    name: `${firstName} ${lastName}`,
    email,
    created_at: new Date(createdAt).getTime(),
    snooper_ri_user: true,
    hide_default_launcher: true,
    // vertical_padding: 60,
    alignment: 'left',
  };

  return settings;
};

export default selectIntercomUserSettings;
