import fan from '../../../../services/fan';

export async function sendInvoice(newOrder) {
  await fan.post(`/order`, JSON.stringify(newOrder), {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}
export default null;
