import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import WiserLogo from '../../../../assets/images/logos/wiser-logo-light-background.png';

import { simpleSnooperTheme } from '../../../../theme/snooperTheme';

const styles = theme => ({
  background: {
    padding: 0,
    height: '100vh',
    background: 'rgba(0,113,188,0.9)',
  },
  main: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: simpleSnooperTheme.palette.secondary.main,
  },
  message: {
    marginTop: theme.spacing.unit * 5,
  },
  logo: {
    flex: 1,
    width: '80%',
    height: 'auto',
  },
});

class ResetPasswordDone extends PureComponent {
  componentDidMount = () => {
    document.title = 'Retail Intelligence - Thank you!';
  };

  renderMessage = () => (
    <Fragment>
      <p>
        If an account exists with the email you provided, a password reset link will be sent to this
        email address within the next few minutes.
      </p>
      <p>
        If you don&apos;t receive any email from us within 24h, you can always contact us at:{' '}
        <a href="mailto:info@snooper-app.com">info@snooper-app.com</a>.
      </p>
    </Fragment>
  );

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={simpleSnooperTheme}>
        <div className={classes.background}>
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
              <img src={WiserLogo} alt="Wiser" className={classes.logo} testId="wiserLogo" />
              <Typography component="h1" variant="h5">
                Reset password request sent
              </Typography>
              <Typography spacing={16} className={classes.message}>
                {this.renderMessage()}
              </Typography>
            </Paper>
          </main>
        </div>
      </MuiThemeProvider>
    );
  }
}

ResetPasswordDone.propTypes = {
  classes: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      isVerified: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default withStyles(styles)(ResetPasswordDone);
