import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Alert({
  onConfirm,
  confirmLabel,
  cancelLabel,
  dialogText,
  title,
  open,
  setOpen,
}) {
  function handleConfirm() {
    onConfirm();
    setOpen(false);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        testid="dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button testid="cancel" onClick={handleClose} color="primary">
            {cancelLabel}
          </Button>
          <Button testid="confirm" onClick={handleConfirm} color="secondary" autoFocus>
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Alert.propTypes = {
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  dialogText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};
