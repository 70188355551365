import ReactGA from 'react-ga';

export const initGA = trackingID => {
  ReactGA.initialize(trackingID, { gaOptions: { userId: `${localStorage.getItem('clientId')}` } });
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const Event = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
