import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Show, SimpleShowLayout } from 'react-admin';
import Title from './components/title/title';
import CardInfo from './components/mainInfo/mainInfo';

import { getCurrentMembership } from '../../../../services/user.service';

const styles = {
  body: { fontSize: '13px' },
  background: {
    background: '#f9fbfc',
    height: '100%',
    padding: 'unset',
    paddingTop: 'unset !important',
  },
  progress: { width: '14px', height: 'auto' },
  cardInfo: { height: '100%' },
  showContent: { height: '100%' },
};

const IndustryReportShow = ({ classes, ...props }) => {
  const [values, setValues] = React.useState({
    membership: '',
    respondents: 0,
  });
  useEffect(() => {
    async function getCurrentMember() {
      const result = await getCurrentMembership();
      setValues({ membership: result });
    }
    getCurrentMember();
  }, []);

  return (
    <Show {...props} actions={null} style={styles.showContent}>
      <SimpleShowLayout className={classes.background}>
        <Title testid="title" {...props} />
        <CardInfo
          testid="mainInfo"
          membership={values.membership}
          source="cardInfo"
          className={classes.cardInfo}
        />
      </SimpleShowLayout>
    </Show>
  );
};

IndustryReportShow.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
};
IndustryReportShow.defaultProps = {
  classes: null,
};

export default withStyles(styles)(IndustryReportShow);
