import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Event } from '../../../../../setup/analytics';

const styles = {
  card: {
    borderRadius: '4px',
    boxShadow: '0 0 11px 5px rgba(234, 240, 246, 0.5)',
    backgroundColor: '#ffffff',
    height: '400px',
    width: '280px',
    padding: '15px',
  },
  button: {
    width: '121px',
    height: '41px',
    borderRadius: '4px',
    backgroundColor: '#00c7fc',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#33475b',
  },
  type: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#00c7fc',
  },
  content: {
    height: '180px',
  },
};

export default function CampaignTypeCard({ content }) {
  const renderNoWrapParagraph = input => {
    if (input.length > 250) {
      return `${input.substr(0, 250)}...`;
    }
    return input;
  };

  return (
    <Grid
      container
      direction="column"
      spacing={16}
      style={styles.card}
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item>
        <Typography testid="title" style={styles.header}>
          {content.title}
        </Typography>
        <Typography testid="industry" style={styles.type}>
          {content.industry}
        </Typography>
      </Grid>
      <Grid item>
        <Typography testid="content" style={styles.content}>
          {renderNoWrapParagraph(content.content)}
        </Typography>
      </Grid>
      <Grid item container justify="center">
        <Button
          testid="readMore"
          style={styles.button}
          href={content.link}
          onClick={() => Event('Navigation', `Select: ${content.title}`, 'Explore, Data series')}
        >
          Read more
        </Button>
      </Grid>
    </Grid>
  );
}

CampaignTypeCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    industry: PropTypes.string,
    content: PropTypes.string,
    logo: PropTypes.any,
    link: PropTypes.string,
  }).isRequired,
};
