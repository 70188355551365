import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import snooperTheme from '../../../theme/snooperTheme';
import AppBarContainer from './appBar/appBar';

import Intercom from '../components/intercom/intercomSnippet';
import { readEncodedUserFromLocalStorage } from '../../authentication/AuthProvider';
import { SegmentSessionRoot } from '../components/segment/segmentSnippet';
import fan from '../../services/fan';
// eslint-disable-next-line import/no-named-as-default
import FreeTrialExpireModal from '../components/freeTrial/freeTrialExpireModal';
import HubSpotSnippet from '../components/hubspot/hubspotSnippet';
import { UserProvider } from '../../../context/UserContext';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    height: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f5f8fa',
    marginTop: '64px',
    height: 'calc(100% - 64px)',
  },
};

class MyLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerSubscriptions: [],
      isCustomerSubscriptionsFetched: false,
    };
  }

  componentDidMount = async () => {
    const user = readEncodedUserFromLocalStorage();
    try {
      if (user && user.role !== 'ADMIN') {
        const customerSubscriptions = await fan.get('customers/subscriptions/mine');
        this.setState({
          customerSubscriptions: customerSubscriptions.data.data,
          isCustomerSubscriptionsFetched: true,
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  render() {
    const { classes, children, logout } = this.props;
    const user = readEncodedUserFromLocalStorage();

    return (
      <MuiThemeProvider theme={snooperTheme}>
        <div className={classes.root}>
          <UserProvider>
            <Intercom intercomId="elzqchc8" user={user} />
            {this.state.isCustomerSubscriptionsFetched ? (
              <>
                <FreeTrialExpireModal customerSubscriptions={this.state.customerSubscriptions} />
                <SegmentSessionRoot
                  user={user}
                  customerSubscriptions={this.state.customerSubscriptions}
                />
              </>
            ) : null}
            <HubSpotSnippet url="//js-na1.hs-scripts.com/" trackingCode="24118779" />
            <div className={classes.appFrame}>
              <AppBarContainer logout={logout} />
              <div className={classes.content}>{children}</div>
            </div>
          </UserProvider>
        </div>
      </MuiThemeProvider>
    );
  }
}

MyLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  logout: PropTypes.shape(PropTypes.any).isRequired,
};
export default withStyles(styles)(MyLayout);
