import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Event } from '../../../../../../setup/analytics';

import './react-dates.css';
import { ScopeDataTooltip } from '../../../components/tooltips/tooltips';

const styles = {
  error: { color: '#f6675e' },
  datePickerMargin: {
    backgroundColor: '#ffffff',
    width: '100%',
    padding: 20,
    borderRadius: '4px',
    boxShadow: ' 0 0 11px 5px rgba(234, 240, 246, 0.5)',
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
  },
  warning: {
    color: '#f38c63',
    fontSize: '12px',
    marginLeft: 15,
  },
};

class DatePicker extends React.PureComponent {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  };

  onDatesChange = ({ startDate, endDate }) => {
    Event('Editing', `Change date: ${startDate} - ${endDate}`, 'Explore modules, scope');
    if (startDate && endDate) {
      const newOrder = {
        ...this.props.quoteOrder,
        startDate: startDate.format('l'),
        endDate: endDate.format('l'),
      };
      const completion = endDate.diff(startDate, 'days');
      const track = startDate.diff(moment().valueOf(), 'days');
      if (completion <= 7) {
        newOrder.fastCompletionFee = true;
      } else {
        newOrder.fastCompletionFee = false;
      }
      if (track <= 2) {
        newOrder.fastTrackFee = true;
      } else {
        newOrder.fastTrackFee = false;
      }
      this.props.onQuoteOrderChange(newOrder);
    }
    this.setState({ startDate, endDate });
  };

  renderHelperText = () => {
    const { quoteOrder, calculateIsClicked } = this.props;
    const { startDate, endDate } = quoteOrder;

    if (startDate && endDate) {
      return null;
    }

    if (calculateIsClicked) {
      return (
        <FormHelperText testid="error" style={styles.error} id="component-error-text">
          Please select a date
        </FormHelperText>
      );
    }
    return null;
  };

  renderWarning = () => {
    const { quoteOrder } = this.props;
    const { fastCompletionFee, fastTrackFee } = quoteOrder;

    if (!fastCompletionFee && !fastTrackFee) {
      return null;
    }

    if (fastCompletionFee && fastTrackFee) {
      return (
        <span style={styles.warning}>
          The dates you selected will trigger a Fast completion fee and Fast track fee
        </span>
      );
    }

    if (fastCompletionFee && !fastTrackFee) {
      return (
        <span style={styles.warning}>
          The dates you selected will trigger a Fast completion fee
        </span>
      );
    }

    return <span style={styles.warning}>The dates you selected will trigger a Fast track fee</span>;
  };

  render = () => {
    const { startDate, endDate } = this.state;
    const { quoteOrder } = this.props;
    return (
      <Grid container style={styles.datePickerMargin} spacing={16}>
        <Grid item container alignItems="center">
          <Grid item>
            <Typography variant="h6" testid="title">
              What is your time frame?
            </Typography>
          </Grid>
          <Grid item>
            <ScopeDataTooltip testid="tooltip" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item>
            <DateRangePicker
              testid="dateRangePicker"
              startDate={startDate}
              startDateId="test"
              startDatePlaceholderText={quoteOrder.startDate ? quoteOrder.startDate : 'Start date'}
              endDatePlaceholderText={quoteOrder.endDate ? quoteOrder.endDate : 'End date'}
              endDate={endDate}
              endDateId="test"
              onDatesChange={this.onDatesChange}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              showDefaultInputIcon
            />
          </Grid>
          <Grid item>{this.renderWarning()}</Grid>
        </Grid>
        {this.renderHelperText()}
      </Grid>
    );
  };
}

DatePicker.propTypes = {
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  onQuoteOrderChange: PropTypes.func.isRequired,
  calculateIsClicked: PropTypes.bool.isRequired,
};

export default DatePicker;
