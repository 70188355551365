import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import NumberFormatField from '../../../../components/numberFormatField/numberFormatField';

const styles = {
  error: { color: '#f6675e' },
  marginLocations: { marginBottom: 10 },
  storeSelection: {
    marginLeft: '15px',
  },
  helper: { color: 'grey' },
  specify: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: 5,
    fontSize: 10,
  },
};

export default function NumberOfLocations({
  quoteOrder,
  everythingFilledIn,
  calculateIsClicked,
  handleChangeVisits,
}) {
  const { numberOfLocations, completionStrategy } = quoteOrder;
  return (
    <Grid container alignItems="center" style={styles.marginLocations}>
      <Grid item xs={4} style={styles.storeSelection}>
        <Typography testid="title">Number of locations to visit:</Typography>
        <Typography style={styles.specify}>(recommended: min 200)</Typography>
      </Grid>
      <Grid item>
        <FormControl>
          <NumberFormatField
            id="standard-number"
            testid="numberOfLocations"
            label={null}
            value={numberOfLocations}
            onChange={handleChangeVisits('numberOfLocations', 'multipleVisitPerStore')}
            variant="outlined"
            error={numberOfLocations <= 0 && !everythingFilledIn && calculateIsClicked}
            InputLabelProps={{
              shrink: true,
              height: '2.18rem',
            }}
            fullWidth
            margin="normal"
            disabled={completionStrategy === 'Full network'}
          />
          {!everythingFilledIn && calculateIsClicked && numberOfLocations <= 0 ? (
            <FormHelperText testid="error" style={styles.error} id="component-error-text">
              Please add number of locations
            </FormHelperText>
          ) : null}
          {completionStrategy === 'Full network' ? (
            <FormHelperText style={styles.helper} id="component-error-text">
              Default maximum number of locations
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
    </Grid>
  );
}

NumberOfLocations.propTypes = {
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  everythingFilledIn: PropTypes.bool.isRequired,
  calculateIsClicked: PropTypes.bool.isRequired,
  handleChangeVisits: PropTypes.func.isRequired,
};
