import fan from '../../../services/fan';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const signUp = async client => {
  const payload = JSON.stringify({
    firstName: client.firstName,
    lastName: client.lastName,
    email: client.email,
    password: client.password,
    role: 'CLIENT',
    company: client.company,
  });
  const result = await fan.post(`/users`, payload, config);
  return result.data;
};

export const forgotPassword = async email => {
  const result = await fan.post(`/users/resetPassword`, null, { ...config, params: { email } });
  return result.status;
};

export const verifyEmail = async (userId, token) => {
  const payload = JSON.stringify({
    token,
  });
  await fan.put(`/users/${userId}/email/validation`, payload, config);
};

// stolen from https://stackoverflow.com/a/901144
export function getParameterByName(nameInput, url = window.location.href) {
  const name = nameInput.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
