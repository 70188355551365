import { connect } from 'react-redux';

import { updatePhoto } from '../../../../../../../../../../../data/actions';
import PhotoIconsPresenter from './photoIcons.presenter';

const mapDispatchToProps = dispatch => ({
  onUpdatePhotoTag(...args) {
    dispatch(updatePhoto(...args));
  },
});

const PhotoIconsContainer = connect(
  null,
  mapDispatchToProps,
)(PhotoIconsPresenter);

export default PhotoIconsContainer;
