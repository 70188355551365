import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import ModuleCard from './moduleCard/moduleCard';
import { modules, infoExploreCampaignTypes } from '../../../variables/variables';
import ModulesListFooter from './modulesListFooter';
import ModulesInfo from './modulesInfo';

import SendMail from '../../components/sendMail';
import { Event } from '../../../../../setup/analytics';

const styles = {
  container: { padding: '4%', marginBottom: 30, marginTop: '4px', backgroundColor: '#f5f8fa' },
};

function ModulesList({ onSelectModule }) {
  const [open, setOpen] = React.useState(false);
  const openSendRequest = () => {
    Event('Click open', 'Contact us', 'Explore modules');
    setOpen(true);
  };
  const closeSendRequest = () => setOpen(false);

  return (
    <React.Fragment>
      <ModulesInfo infoExploreCampaignTypes={infoExploreCampaignTypes.modules} />
      <Grid container alignItems="center" spacing={32} style={styles.container}>
        {modules.map(module => (
          <Grid item xs={12} md={3} container justify="center">
            <ModuleCard key={module.id} testid="card" onSelect={onSelectModule} campaign={module} />
          </Grid>
        ))}
      </Grid>
      <ModulesListFooter
        title="You can’t find what you’re looking for here? Request a custom mission"
        titleButton="Contact us"
        openSendRequest={openSendRequest}
        variant="outlined"
      />
      <SendMail
        testid="SendMail"
        closeSendRequest={closeSendRequest}
        open={open}
        campaignType="Module"
        content="Please, explain in a few words what you’d like to achieve. If you’re just not quite sure what you want to track, you can leave this form blank. One of our customer success managers will be in touch to discuss your requirements"
        mailTitle="Client custom module request"
        mailBody="A client requested to build a custom mission."
        isWithInput
        inputPlaceholder=""
      />
    </React.Fragment>
  );
}

ModulesList.propTypes = {
  onSelectModule: PropTypes.func.isRequired,
};

export default ModulesList;
