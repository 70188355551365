import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

const styles = {
  font: { marginBottom: '-10px' },
};

export default function PhotoCheckpointFilter({
  photoCheckpointShortDescription,
  photoCheckpointShortDescriptionSelected,
  onHandleChange,
  onTrackEvent,
}) {
  const onHandleChangePhotoCheckpoint = photoCheckpointShortDescriptionSelect => {
    onHandleChange(
      'photoCheckpointShortDescriptionSelected',
      photoCheckpointShortDescriptionSelect,
    );

    if (onTrackEvent && typeof onTrackEvent === 'function') onTrackEvent('filterPhotoGallery');
  };
  return (
    <React.Fragment>
      <ListItem>
        <h4 style={styles.font}> Only show </h4>
      </ListItem>
      <ListItem>
        <FilterSelect
          testid="photoCheckpoint"
          segmentValues={[photoCheckpointShortDescription.sort((a, b) => a.localeCompare(b))]}
          segmentName={['Photos']}
          onHandleChangeFilter={onHandleChangePhotoCheckpoint}
          isCheckpointFilter
          segmentValue={photoCheckpointShortDescriptionSelected}
        />
      </ListItem>
    </React.Fragment>
  );
}

PhotoCheckpointFilter.propTypes = {
  photoCheckpointShortDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
  photoCheckpointShortDescriptionSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHandleChange: PropTypes.func.isRequired,
  campaign: PropTypes.shape({}).isRequired,
  onTrackEvent: PropTypes.func.isRequired,
};
