import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

export default function CyclesFilter({
  permissions,
  cyclesFilter,
  cyclesFilterSelected,
  onHandleChange,
}) {
  if (cyclesFilter == null || cyclesFilter.length === 0) {
    return null;
  }

  const onHandleChangeCycle = cyclesFilterSelect => {
    const newCycleFilterSelect = cyclesFilterSelect
      .flatMap(cycle => cyclesFilter.filter(c => c.includes(cycle)))
      .filter(c => c !== null);
    return onHandleChange('cyclesFilterSelected', newCycleFilterSelect);
  };

  const cyclesFilterClient = cyclesFilter.map(
    cycleFilter => `starts ${cycleFilter.split('starts ')[1]}`,
  );

  const cyclesFilterSelectedClient = cyclesFilterSelected.map(
    cycleFilter => `starts ${cycleFilter.split('starts ')[1]}`,
  );

  return (
    <ListItem>
      <FilterSelect
        testid="cycles"
        segmentValues={[permissions ? cyclesFilter.sort() : cyclesFilterClient.sort()]}
        segmentName={[`Cycles`]}
        onHandleChangeFilter={onHandleChangeCycle}
        segmentValue={permissions ? cyclesFilterSelected : cyclesFilterSelectedClient}
      />
    </ListItem>
  );
}

CyclesFilter.propTypes = {
  permissions: PropTypes.bool.isRequired,
  cyclesFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  cyclesFilterSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHandleChange: PropTypes.func.isRequired,
};
