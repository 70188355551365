import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import testImage from '../../../../../assets/images/ordering/Idea_OrderingPl.jpeg';
import UseCaseDialog from './useCaseDialog';

const styles = {
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#33475b',
  },
  content: {
    color: '#33475b',
  },
  image: {
    borderRadius: '10px',
    height: '80%',
  },
  containerEven: {
    padding: 30,
  },
  containerOdd: {
    backgroundColor: '#fff',
    padding: 30,
  },
};

export default function ExcellenceProgramCard({ excellencePrograms }) {
  return (
    <Grid
      testid="container"
      container
      justify="center"
      direction={excellencePrograms.id % 2 !== 0 ? 'row-reverse' : 'row'}
      spacing={16}
      style={excellencePrograms.id % 2 !== 0 ? styles.containerOdd : styles.containerEven}
    >
      <Grid item lg={8} md={7} container justify="flex-start" spacing={16}>
        <Grid item lg={12}>
          <Typography testid="title" style={styles.title}>
            {excellencePrograms.title}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography testid="content" style={styles.content}>
            {excellencePrograms.content}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <UseCaseDialog pdf="https://s3.ap-southeast-2.amazonaws.com/snooper-static/use-cases/My-second-use-case.pdf" />
        </Grid>
      </Grid>
      <Grid item lg={4} md={5} container justify="center">
        <img src={testImage} alt="" style={styles.image} testid="image" />
      </Grid>
    </Grid>
  );
}

ExcellenceProgramCard.propTypes = {
  excellencePrograms: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.any,
    id: PropTypes.number,
  }).isRequired,
};
