import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  calculateReportMembershipPrice,
  getPackDiscount,
} from '../../../../industryReports/components/industryReportTypeShow/services/reportPrice.service';
import { getCurrentMembership } from '../../../../../services/user.service';

const styles = {
  header: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#33475b',
  },
  content: {
    fontSize: '13px',
    color: '#47494a',
  },
};

export default function Summary({ content }) {
  const [membership, setMembership] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await getCurrentMembership();
      setMembership(response);
    }
    fetchData();
  });

  return (
    <React.Fragment>
      <Typography testid="title" style={styles.header} gutterBottom paragraph>
        Summary
      </Typography>
      <Typography testid="price" style={styles.content} gutterBottom>
        Price: ${content.price.toLocaleString()}
      </Typography>
      <Typography style={styles.content} gutterBottom>
        Discount: {getPackDiscount(membership)}
      </Typography>
      <Typography style={styles.content} gutterBottom>
        Your price: ${calculateReportMembershipPrice(content.price, membership).toLocaleString()}
      </Typography>
      <Typography testid="type" style={styles.content} gutterBottom>
        Type: {content.type}
      </Typography>
      <Typography testid="topics" style={styles.content} gutterBottom>
        Topics: {content.topics.join(', ')}
      </Typography>
      <Typography testid="period" style={styles.content} gutterBottom>
        Period: {content.period}
      </Typography>
      <Typography testid="respondents" style={styles.content} gutterBottom>
        Number of respondents: {content.respondents}
      </Typography>
    </React.Fragment>
  );
}

Summary.propTypes = {
  content: PropTypes.shape({
    price: PropTypes.number,
    type: PropTypes.string,
    topics: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string,
    respondents: PropTypes.number,
  }).isRequired,
};
