import React from 'react';
import * as Sentry from '@sentry/browser';
import 'typeface-roboto'; // eslint-disable-line
import ReactDOM from 'react-dom';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import JssProvider from 'react-jss/lib/JssProvider';
import fanProvider from './modules/data-provider/fan';
import snooperTheme from './theme/snooperTheme';
import authProvider from './modules/authentication/AuthProvider';
import httpClientAuthorised from './modules/authentication/HttpClientAuthorised';
import CustomLoginPresenter from './modules/pages/authentication/customLogin.presenter';
import Dashboard from './modules/pages/campaigns/scenes/campaignsDashboard/dashboard';
import MyLayout from './modules/pages/layout/myLayout';
import config from './setup/config';
import './assets/css/index.css';
import Ordering from './modules/pages/ordering/ordering';
import Packs from './modules/pages/packs/packs';

import UseCaseList from './modules/pages/useCases/useCasesList';
import IndustryReportShow from './modules/pages/industryReports/components/industryReportTypeShow/industryReportShow';
import ShoppingCartList from './modules/pages/shoppingCart/shoppingCartList';
import classNameHelper from './theme/classNameHelper';
import { initGA, PageView } from './setup/analytics';
import VerifyEmail from './modules/pages/authentication/verifyEmail/verifyEmail.presenter';
import ResetPassword from './modules/pages/authentication/resetPassword/resetPassword';
import ResetPasswordDone from './modules/pages/authentication/resetPassword/resetPasswordSuccess';
import Explore from './modules/pages/explore/explore';
import MySnooperContainer from './modules/pages/mySnooper/mySnooper';
import IndustryReports from './modules/pages/explore/industryReports/industryReports';
import DataSeriesOverview from './modules/pages/explore/dataSeries/components/campaignTypeOverview';
// import Home from './modules/pages/homePage/home';

import photosByCampaignReducer from './data/reducers';
import SegmentSnippet from './modules/pages/components/segment/segmentSnippet';
import ErrorBoundary from './modules/pages/components/errorBoundary/errorBoundary';

const { version } = require('../package.json');

const dataProvider = fanProvider(config.FAN_BACKEND_BASE_URL, httpClientAuthorised);

const ExploreAll = () => <Explore tab={0} />;
const ExploreModule = () => <Explore tab={1} />;
const ExploreExcellencePrograms = () => <Explore tab={2} />;
const ExploreDataSeries = () => <Explore tab={4} />;
const DataSeries1 = () => <DataSeriesOverview />;

initGA('UA-75723220-7');
PageView();

Sentry.init({
  dsn: 'https://be742137a27a4943abbfdb252c30f69d@sentry.io/1782295',
  release: version,
  environment: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'LOCAL',
});

Sentry.configureScope(scope => {
  scope.setUser({ id: localStorage.getItem('clientId') });
});

ReactDOM.render(
  <>
    <SegmentSnippet />
    <ErrorBoundary>
      <JssProvider generateClassName={classNameHelper}>
        <Admin
          appLayout={MyLayout}
          loginPage={CustomLoginPresenter}
          theme={snooperTheme}
          authProvider={authProvider}
          dataProvider={dataProvider}
          customRoutes={[
            <Route exact path="/reset-password" component={ResetPassword} noLayout />,
            <Route exact path="/reset-password-done" component={ResetPasswordDone} noLayout />,
            <Route exact path="/verify-email" component={VerifyEmail} noLayout />,
            <Route exact path="/new_campaign" component={Ordering} />,
            <Route exact path="/explore/all" component={ExploreAll} />,
            <Route exact path="/explore/quick-trackers" component={ExploreModule} />,
            <Route exact path="/explore/data-series" component={ExploreDataSeries} />,
            <Route
              exact
              path="/explore/excellence-programs"
              component={ExploreExcellencePrograms}
            />,
            <Route exact path="/explore/data-series/1" component={DataSeries1} />,
          ]}
          customReducers={{ photosByCampaign: photosByCampaignReducer }}
        >
          {/* <Resource name="home" list={Home} /> */}

          <Resource name="campaigns" list={MySnooperContainer} edit={Dashboard} />
          <Resource name="packs" list={Packs} />
          <Resource
            name="explore/industry-reports"
            list={IndustryReports}
            show={IndustryReportShow}
          />
          <Resource name="use-cases" list={UseCaseList} />
          <Resource name="shopping-cart" list={ShoppingCartList} />
        </Admin>
      </JssProvider>
    </ErrorBoundary>
  </>,
  document.getElementById('root'),
);
