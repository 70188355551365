import React from 'react';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import VerticalLinearStepper from './components/stepper/stepper';
import QuoteType from './quote/quoteType';
import OrderSummary from './summary/summary';
import {
  calculateFeePerAudit,
  calculateQuotePerMissionPlan,
} from './quote/components/plans/services/newCampaignPricing.service';
import {
  calculateFeeVisitsPriceImpact,
  calculateCompletionStrategyImpact,
} from './services/calculations.service';
import {
  initialCampaignTypes,
  campaignOrder,
  selectStoreOptions,
  packs,
  modules,
} from '../variables/variables';
import { Event } from '../../../setup/analytics';
import Plans from './quote/components/plans/plans';
import { mailNewCampaignOrder } from './services/mailService';
import { getMyMembership } from '../../services/user.service';
import CampaignExample from './components/dialog/campaignExample/campaignExample';
import ContinueFooter from './components/continueFooter/continueFooter';

import HASQUOTE from '../../../setup/featureFlipping';

const styles = {
  header: {
    marginTop: '1%',
  },
  stepper: {
    marginTop: '20px',
  },
  audits: {
    fontWeight: '500',
  },
  drawer: {
    marginTop: '63px',
    width: '40%',
    padding: '20px',
    background: '#f5f8fa',
  },

  buildOwn: {
    marginTop: '4%',
  },
  confirmation: {
    '@media (min-height: 800px)': {
      position: 'absolute',
      bottom: '10px',
    },
    marginTop: '10px',
  },
  marginTop: {
    marginTop: 15,
  },
  loader: {
    width: '9%',
    height: 'auto',
  },
  container: {
    height: 'inherit',
  },
};

class Ordering extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      campaignTypes: initialCampaignTypes,
      quoteOrder: {
        ...campaignOrder,
        mission: modules.filter(module => module.id === props.selectedModule)[0].name,
        feePerAudit: modules.filter(m => m.id === props.selectedModule).map(m => m.price)[0],
      },
      openPreview: false,
      isDrawerScope: false,
      storeTypes: selectStoreOptions,
      isDraftMailLoading: false,
      keepPerformanceIndicator: modules
        .filter(m => m.id === props.selectedModule)
        .map(k => k.KPIs)
        .flat(),
      useCaseLink: modules
        .filter(m => m.id === props.selectedModule)
        .map(k => k.useCaseLink)
        .flat(),
    };
  }

  async componentDidMount() {
    const currentMembership = await getMyMembership();
    const newCampaignOrder = JSON.parse(localStorage.getItem('newCampaignOrder'));
    if (!newCampaignOrder) {
      return this.setState(prevState => ({
        quoteOrder: {
          ...prevState.quoteOrder,
          membership: prevState.quoteOrder.membership
            ? prevState.quoteOrder.membership
            : currentMembership,
        },
      }));
    }

    const { membership } = newCampaignOrder.quoteOrder;
    let currentMem = membership;
    if (!currentMem) {
      currentMem = currentMembership;
    }

    if (newCampaignOrder.quoteOrder.mission === this.state.quoteOrder.mission) {
      return this.setState({
        campaignTypes: newCampaignOrder.campaignTypes,
        quoteOrder: { ...newCampaignOrder.quoteOrder, membership: currentMem },
        storeTypes: newCampaignOrder.storeTypes,
        openPreview: newCampaignOrder.openPreview,
      });
    }

    return this.setState(prevState => ({
      quoteOrder: { ...prevState.quoteOrder, membership: currentMem },
    }));
  }

  onSetLocalStorage = () => {
    const newCampaignOrder = { ...this.state };
    localStorage.setItem('newCampaignOrder', JSON.stringify(newCampaignOrder));
  };

  onSelectStoreTypeChange = dataFromChild => {
    this.setState({ storeTypes: dataFromChild });
    this.onSetLocalStorage();
  };

  onQuoteOrderChange = dataFromChild => {
    const baseFeePerAudit = modules
      .filter(m => dataFromChild.mission === m.name)
      .map(m => m.price)[0];

    this.setState({
      quoteOrder: {
        ...dataFromChild,
        feePerAudit:
          baseFeePerAudit +
          calculateFeeVisitsPriceImpact(dataFromChild.storeTypes) +
          calculateCompletionStrategyImpact(dataFromChild.completionStrategy),
        price: calculateQuotePerMissionPlan(dataFromChild.membership, dataFromChild),
        dataFee: dataFromChild.membership === 'Ad-hoc' ? 1000 : null,
      },
    });
    this.onSetLocalStorage();
  };

  onHandleDrawerScope = () => {
    Event('QUOTE', 'edit scope, open drawer', `edit scope button`);
    this.setState(prevState => ({
      isDrawerScope: !prevState.isDrawerScope,
    }));
  };

  onCloseDrawer = () => {
    this.setState({
      isDrawerScope: false,
    });
  };

  onChangeReport = report =>
    this.setState(prevState => ({
      quoteOrder: {
        ...prevState.quoteOrder,
        [report]: !prevState.quoteOrder[report],
      },
    }));

  onQuoteContinue = async () => {
    this.setState({ isDraftMailLoading: true });
    const quoteOrder = {
      ...this.state.quoteOrder,
      feePerAudit: calculateFeePerAudit(
        this.state.quoteOrder.membership,
        this.state.quoteOrder.feePerAudit,
      ),
      price: this.state.quoteOrder.price,
      dataFee: packs
        .filter(pack => pack.membership === this.state.quoteOrder.membership)
        .map(value => value.designDataFee)
        .shift(),
      campaignOrderType: 'Draft',
      membership:
        this.state.quoteOrder.membership === 'Ad-hoc'
          ? 'No plan'
          : this.state.quoteOrder.membership,
    };
    await mailNewCampaignOrder(quoteOrder, '', '');
    this.setState({ isDraftMailLoading: false });
    this.nextStep();
  };

  calculateFeeAddOn = (quoteOrder, quoteType) => {
    let feeAdded = 0;
    if (quoteOrder.fastCompletionFee) {
      feeAdded += quoteType.fastCompletionFee;
    }
    if (quoteOrder.fastTrackFee) {
      feeAdded += quoteType.fastTrackFee;
    }
    if (quoteOrder.monthlyIndustryReport) {
      feeAdded += quoteType.monthlyIndustryReport;
    }
    if (quoteOrder.insightReport) {
      feeAdded += quoteType.insightReport;
    }
    return feeAdded;
  };

  gotoStep = step => {
    const checkOrder = { ...this.state.quoteOrder };
    const startDate = checkOrder.startDate === null;
    const endDate = checkOrder.endDate === null;
    const storeTypes = checkOrder.storeTypes.length <= 0;
    const numberOfLocations = checkOrder.numberOfLocations <= 0;
    const multipleVisitPerStore = checkOrder.multipleVisitPerStore <= 0;
    const completionMethology = checkOrder.completionStrategy.trim() === '';
    if (this.state.activeStep > step) {
      this.setState({
        activeStep: step,
      });
    }
    if (step > this.state.activeStep) {
      if (step === 1 && this.state.quoteOrder.mission !== null && this.state.activeStep === 0) {
        this.setState({
          activeStep: step,
        });
      }
      if (
        !startDate &&
        !endDate &&
        !storeTypes &&
        !numberOfLocations &&
        !multipleVisitPerStore &&
        !completionMethology &&
        !checkOrder.price !== null
      ) {
        this.setState({
          activeStep: step,
        });
      }
    }
  };

  nextStep = () => {
    const { activeStep } = this.state;

    Event('Navigation', `Next step: ${activeStep + 1}`, `Explore modules, ordering steps`);

    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  previousStep = () => {
    const { activeStep } = this.state;
    Event('Navigation', `Previous step: ${activeStep - 1}`, `Explore modules, ordering steps`);

    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  renderQuote = () => (
    <Grid>
      <Plans
        testid="plans"
        quoteOrder={this.state.quoteOrder}
        onQuoteOrderChange={this.onQuoteOrderChange}
        onHandleDrawerScope={this.onHandleDrawerScope}
      />
      <ContinueFooter
        testid="continue"
        onGotoNextStep={this.nextStep}
        onGotoPreviousStep={this.previousStep}
      />
    </Grid>
  );

  renderQuoteDrawer = () => {
    const { isDrawerScope } = this.state;
    return (
      <ClickAwayListener testid="clickAway" onClickAway={this.onCloseDrawer}>
        <Drawer
          testid="drawer"
          variant="persistent"
          anchor="right"
          open={isDrawerScope}
          className={this.props.classes.drawer}
          classes={{
            paperAnchorRight: this.props.classes.drawer,
          }}
        >
          <Grid container justify="flex-start">
            <IconButton onClick={this.onHandleDrawerScope}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>
          <QuoteType
            testid="step2"
            onQuoteOrderChange={this.onQuoteOrderChange}
            quoteOrder={this.state.quoteOrder}
            onGotoNextStep={this.nextStep}
            storeTypes={this.state.storeTypes}
            onSelectStoreTypeChange={this.onSelectStoreTypeChange}
            isDrawerOpen={this.state.isDrawerScope}
          />
        </Drawer>
      </ClickAwayListener>
    );
  };

  renderStep(activeStep) {
    const { onGoToStep } = this.props;
    const { keepPerformanceIndicator, useCaseLink } = this.state;
    switch (activeStep) {
      case 0:
        return onGoToStep(0);
      case 1:
        return (
          <React.Fragment>
            <CampaignExample
              testid="insights"
              keepPerformanceIndicators={keepPerformanceIndicator}
              useCaseLink={useCaseLink}
            />
            <ContinueFooter onGotoNextStep={this.nextStep} onGotoPreviousStep={this.previousStep} />
          </React.Fragment>
        );
      case 2:
        return (
          <div testid="scope" style={styles.header}>
            <QuoteType
              testid="step2"
              onQuoteOrderChange={this.onQuoteOrderChange}
              quoteOrder={this.state.quoteOrder}
              onGotoNextStep={this.nextStep}
              storeTypes={this.state.storeTypes}
              onSelectStoreTypeChange={this.onSelectStoreTypeChange}
              onGotoPreviousStep={this.previousStep}
            />
          </div>
        );
      case 3:
        if (HASQUOTE) {
          return (
            <div testid="quote">
              {this.renderQuote()}
              {this.renderQuoteDrawer()}
            </div>
          );
        }
        return (
          <div testid="summary" style={styles.header}>
            <OrderSummary
              quoteOrder={this.state.quoteOrder}
              onGotoPreviousStep={this.previousStep}
            />
          </div>
        );
      case 4:
        if (HASQUOTE) {
          return (
            <div testid="summary" style={styles.header}>
              <OrderSummary
                quoteOrder={this.state.quoteOrder}
                onGotoPreviousStep={this.previousStep}
              />
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container direction="row" style={styles.container}>
          <Grid item xs={2} style={{ backgroundColor: 'white' }}>
            <div style={styles.stepper}>
              <VerticalLinearStepper
                testid="stepper"
                active={this.state.activeStep}
                onGotoStep={this.gotoStep}
                order={this.state.quoteOrder}
              />
            </div>
          </Grid>
          <Grid testid="renderStepContent" item xs={10}>
            {this.renderStep(this.state.activeStep)}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

Ordering.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToStep: PropTypes.func,
  selectedModule: PropTypes.number,
};

Ordering.defaultProps = {
  onGoToStep: null,
  selectedModule: '',
};

export default withStyles(styles)(Ordering);
