import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, TextField, DateField, Filter, Pagination } from 'react-admin';
import CampaignFilterField from './campaignFilter';
import ReactTable from '../../../components/reactTableDatagrid/reactTableDatagrid';

const styles = {
  pagination: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    right: '2px',
    backgroundColor: '#f9fbfc',
  },
  actions: {
    marginTop: 20,
  },
  card: {
    padding: '20px',
  },
  list: {
    height: '100%',
  },
  filter: { marginBottom: '10px' },
};

const CampaignFilter = props => (
  <Filter {...props} style={styles.filter}>
    <CampaignFilterField label="Campaign's name" source="name" alwaysOn />
  </Filter>
);

const CampaignList = withStyles(styles)(
  ({ classes, filterDefaults, campaignType, permissions, ...props }) => (
    <>
      <List
        {...props}
        filters={<CampaignFilter />}
        sort={{ field: 'startDate', order: 'DESC' }}
        bulkActionButtons={<span />}
        classes={{ header: classes.actions, root: classes.list, card: classes.card }}
        filterDefaultValues={filterDefaults}
        actions={null}
        pagination={
          <Pagination rowsPerPageOptions={[10, 25, 50, 100]} className={classes.pagination} />
        }
      >
        <ReactTable rowClick>
          <TextField source="name" label="Name" />
          <DateField source="startDate" label="Start date" />
          <DateField source="endDate" label="End date" />
        </ReactTable>
      </List>
    </>
  ),
);

CampaignList.propTypes = {
  campaignType: PropTypes.string.isRequired,
};

export default CampaignList;
