import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '../../../../../../../../../assets/images/infoIcon.png';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  logo: {
    height: '18px',
    opacity: '0.7',
    width: '18px',
  },
  tooltip: {
    fontSize: '15px',
  },
  tooltipText: {
    whiteSpace: 'normal',
    display: '-webkit-box',
    lineHeight: '1.6em',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class SegmentSelect extends React.PureComponent {
  handleChange = event => {
    const { onHandleChangeFilter } = this.props;
    if (this.props.isCheckpointFilter === true) {
      return onHandleChangeFilter(event.target.value, this.props.segmentName[0]);
    }
    return onHandleChangeFilter(event.target.value);
  };

  renderQuestionInfo = () => (
    <Tooltip
      testid="question"
      disableFocusListener
      disableTouchListener
      title={this.props.checkpointQuestion}
      className={this.props.classes.logo}
      classes={{ tooltip: this.props.classes.tooltip }}
    >
      <img testid="infoIcon" src={InfoIcon} alt="" />
    </Tooltip>
  );

  renderTooltip = string => (
    <Tooltip disableFocusListener disableTouchListener title={string}>
      <div className={this.props.classes.tooltipText}>{string}</div>
    </Tooltip>
  );

  render() {
    const { classes, segmentName, segmentValues, checkpointQuestion, segmentValue } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <Grid container style={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center">
          <Grid item xs={11} md={11} lg={11}>
            {this.renderTooltip(segmentName[0])}
          </Grid>
          <Grid item xs={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {checkpointQuestion ? this.renderQuestionInfo() : null}
          </Grid>
        </Grid>
        <Select
          multiple
          testid="select"
          value={segmentValue}
          onChange={this.handleChange}
          input={<Input id="segment-label-placeholder" />}
          name="segmentValue"
          renderValue={selected => selected.join(', ')}
        >
          {segmentValues[0].map(value => (
            <MenuItem testid="menuitem" key={value} value={value}>
              <Checkbox checked={segmentValue.indexOf(value) > -1} />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

SegmentSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  segmentValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  segmentName: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHandleChangeFilter: PropTypes.func.isRequired,
  isCheckpointFilter: PropTypes.bool,
  checkpointQuestion: PropTypes.string,
  segmentValue: PropTypes.arrayOf(PropTypes.any),
};

SegmentSelect.defaultProps = {
  isCheckpointFilter: false,
  segmentValue: [],
  checkpointQuestion: null,
};

export default withStyles(styles)(SegmentSelect);
