import PropTypes from 'prop-types';

import { useIntercom } from '@wisersolutions/intercom';
import { selectIntercomUserSettings } from './intercomSelectors';

const IntercomSnippet = ({ intercomId: appId, user }) => {
  if (!appId) {
    throw Error(`An intercom id must be provided (was: ${appId}).`);
  }

  const settings = selectIntercomUserSettings(user);

  useIntercom(appId, settings);

  return null;
};

IntercomSnippet.propTypes = {
  intercomId: PropTypes.string.isRequired,
  user: PropTypes.objectOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ),
};

IntercomSnippet.defaultProps = {
  user: {},
};

export default IntercomSnippet;
