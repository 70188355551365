import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { casualPack, silverPack, goldPack, platinumPack } from '../../../../variables/variables';
import PlansTableHead from './components/plansTableHead/plansTableHead';
import PlansTable from './components/plansTable/plansTable';
import PacksDialog from './components/packsDialog/packsDialog';
import { Event } from '../../../../../../setup/analytics';

import TriangleGrey from '../../../../../../assets/images/TriangleGrey.png';
import TriangleYellow from '../../../../../../assets/images/TriangleYellow.png';
import TriangleRed from '../../../../../../assets/images/TriangleRed.png';
import TriangleBlue from '../../../../../../assets/images/TriangleBlue.png';

const styles = {
  tableCell: {
    borderBottom: 'none',
    padding: '0px 14px 20px 14px',
    width: '14%',
  },
  tableCellSpecial: {
    borderBottom: 'none',
    padding: '0px 14px 20px 14px',
    width: '14%',
    background: '#fff3ee',
  },
  tableHeader: {
    width: '16%',
    borderBottom: 'none',
    padding: '0px 10px 1px 25px',
  },
  tableHeaderEdit: {
    position: 'relative',
    bottom: '30px',
    borderBottom: 'none',
  },
  frame: {
    padding: 20,
    textAlign: 'center',
    width: '94%',
  },
  bold: {
    fontWeight: 600,
    fontSize: '18px',
  },
  noBorder: {
    borderBottom: 'none',
  },
  cellFrame: {
    borderBottom: 'none',
    padding: '4px 53px 3px 11px',
    background: '#fff3ee',
  },
  table: {
    position: 'relative',
    top: 20,
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
  },
  scope: {
    color: '#00C7FC',
    cursor: 'pointer',
  },
  gradeIcon: { textAlign: 'center', marginTop: 10, height: 12, display: 'block' },

  triangle: {
    height: '20px',
  },
};

class Packs extends React.PureComponent {
  onSelectPack = packSelected => {
    const { onQuoteOrderChange, quoteOrder } = this.props;
    Event('Editing', `Select: ${packSelected.membership}`, 'Explore modules, quote');
    onQuoteOrderChange({
      ...quoteOrder,
      membership: packSelected.membership,
    });
  };

  getSelectedTriangleIcon = packageType => {
    if (packageType.membership === 'Ad-hoc') {
      return TriangleGrey;
    }
    if (packageType.membership === 'Silver') {
      return TriangleRed;
    }
    if (packageType.membership === 'Gold') {
      return TriangleYellow;
    }
    if (packageType.membership === 'Platinum') {
      return TriangleBlue;
    }
    return '';
  };

  renderUpgrade = (color, packageType) => {
    const { quoteOrder } = this.props;
    const packSelected = quoteOrder.membership === packageType.membership;
    if (packSelected || (packageType.membership === 'Ad-hoc' && quoteOrder.membership === null)) {
      return (
        <Typography style={styles.gradeIcon}>
          <img
            src={this.getSelectedTriangleIcon(packageType)}
            testid="selected"
            style={styles.triangle}
            alt=""
          />
        </Typography>
      );
    }

    if (packageType.membership === 'Ad-hoc' || quoteOrder.membership === 'Platinum') {
      return <div style={{ height: 15, display: 'block' }} />;
    }

    if (packageType.membership === 'Silver' && quoteOrder.membership === 'Gold') {
      return <div style={{ height: 15, display: 'block' }} />;
    }

    return (
      <UpgradeText testid="upgradeText" color={color}>
        upgrade to {packageType.membership}
      </UpgradeText>
    );
  };

  render() {
    const { classes, quoteOrder, onHandleDrawerScope } = this.props;
    return (
      <React.Fragment>
        <Table testid="table" size="small" style={styles.table}>
          <TableHead />
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableHeaderEdit}>
                <Typography variant="h5" testid="header" paragraph>
                  Choose your plan
                </Typography>
                {/* <Typography style={styles.title}> Choose your plan </Typography> */}
                <Typography style={styles.scope} testid="seeScope" onClick={onHandleDrawerScope}>
                  Edit scope
                </Typography>
              </TableCell>
              <TableCell className={classes.noBorder} />
              <TableCell align="right" colSpan={3} className={classes.cellFrame}>
                <div style={styles.frame}>
                  <Typography style={styles.bold}> Mission Packs </Typography>
                  <Typography>
                    Get a mission pack and benefit from discounted fees per store and other perks
                  </Typography>
                  <PacksDialog />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: classes.tableHeader }} component="th" scope="row">
                <PlansTableHead testid="packageTableHead" />
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} component="th" scope="row">
                <Grid container spacing={16} direction="column">
                  <Grid item onClick={() => this.onSelectPack(casualPack)}>
                    <PlansTable
                      testid="casualPack"
                      packageType={casualPack}
                      packSelected={quoteOrder.membership === casualPack.membership}
                      quoteOrder={quoteOrder}
                      color="#b6bdc3"
                    />
                    {this.renderUpgrade('#b6bdc3', casualPack)}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell classes={{ root: classes.tableCellSpecial }} component="th" scope="row">
                <Grid container spacing={16} direction="column">
                  <Grid item onClick={() => this.onSelectPack(silverPack)}>
                    <PlansTable
                      testid="silverPack"
                      packageType={silverPack}
                      packSelected={quoteOrder.membership === silverPack.membership}
                      quoteOrder={quoteOrder}
                      color="#ff7673"
                    />
                    {this.renderUpgrade('#ff7673', silverPack)}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell classes={{ root: classes.tableCellSpecial }} component="th" scope="row">
                <Grid container spacing={16} direction="column">
                  <Grid testid="selectGold" item onClick={() => this.onSelectPack(goldPack)}>
                    <PlansTable
                      testid="goldPack"
                      packageType={goldPack}
                      packSelected={quoteOrder.membership === goldPack.membership}
                      quoteOrder={quoteOrder}
                      color="#ffd71f"
                    />
                    {this.renderUpgrade('#ffd71f', goldPack)}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell classes={{ root: classes.tableCellSpecial }} component="th" scope="row">
                <Grid container spacing={16} direction="column">
                  <Grid item onClick={() => this.onSelectPack(platinumPack)}>
                    <PlansTable
                      testid="platinumPack"
                      packageType={platinumPack}
                      packSelected={quoteOrder.membership === platinumPack.membership}
                      quoteOrder={quoteOrder}
                      color="#00b4ca"
                    />
                    {this.renderUpgrade('#00b4ca', platinumPack)}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

const UpgradeText = styled(Typography)`
  text-align: center;
  height: 12px;
  margin-top: 10px !important;
  color: ${props => props.color} !important;
  cursor: pointer;
`;

Packs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  onQuoteOrderChange: PropTypes.func.isRequired,
  onHandleDrawerScope: PropTypes.func.isRequired,
};

export default withStyles(styles)(Packs);
