import { GET_PHOTOS_BY_CAMPAIGN, UPDATE_TAG_PHOTO } from './actions';

function photosByCampaign(previousState = {}, action) {
  const { campaignId, type } = action;
  if (type === GET_PHOTOS_BY_CAMPAIGN) {
    const { photos } = action;
    return { ...previousState, [campaignId]: photos };
  }
  if (type === UPDATE_TAG_PHOTO && previousState[campaignId]) {
    const { photo } = action;
    return {
      ...previousState,
      [campaignId]: previousState[campaignId].map(it => {
        if (photo.userMissionId === it.submissionId) {
          return {
            ...it,
            photosPerSubmission: it.photosPerSubmission.map(p => {
              if (p.url === photo.url) {
                return photo;
              }
              return p;
            }),
          };
        }
        return { ...it };
      }),
    };
  }
  return previousState;
}

export default photosByCampaign;
