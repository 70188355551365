export function filterPhoto(
  allSelectedSegments,
  photos,
  query,
  allSelectedCheckpoints,
  photoCheckpointShortDescriptionSelected,
  sharesOfVisibilityValuesSelected,
  photosTagsValuesSelected,
  cycles,
) {
  if (!photos) {
    return [];
  }

  return photos
    .filter(
      photo =>
        doesPhotoMatchTheLocationQuery(photo, query) &&
        doesPhotoBelongToAllSegments(photo, allSelectedSegments) &&
        filterCheckpoints(allSelectedCheckpoints, photo) &&
        filterCycles(cycles, photo),
    )
    .map(photo =>
      doesPhotoMatchCheckpointShortDescription(photo, photoCheckpointShortDescriptionSelected),
    )
    .map(submission => filterSharesOfVisibility(sharesOfVisibilityValuesSelected, submission))
    .map(submission => filterPhotoTags(submission, photosTagsValuesSelected))
    .filter(photo => photo.photosPerSubmission && photo.photosPerSubmission.length > 0)
    .sort((photo1, photo2) => new Date(photo1.submittedAt) - new Date(photo2.submittedAt));
}

function filterCycles(cycles, photo) {
  if (cycles == null || cycles.length === 0) {
    return true;
  }
  return cycles.includes(photo.cycle);
}

function filterCheckpoints(allSelectedCheckpoints, photo) {
  if (!allSelectedCheckpoints) {
    return true;
  }
  const noSelection = allSelectedCheckpoints.every(
    selectedCheckpoint => selectedCheckpoint[1].length === 0,
  );

  if (noSelection) {
    return true;
  }
  const selectedCheckpoints = allSelectedCheckpoints.filter(
    selectedCheckpoint => selectedCheckpoint[1].length !== 0,
  );

  return doesSubmissionBelongToAllCheckpoints(photo, selectedCheckpoints);
}

function filterPhotoTags(submission, selectedValues) {
  if (!selectedValues) {
    return submission;
  }
  const keys = Object.keys(selectedValues);
  if (
    keys.length === 0 ||
    keys.map(key => selectedValues[key]).reduce((acc, values) => acc && values.length === 0, true)
  ) {
    return submission;
  }

  return {
    ...submission,
    photosPerSubmission: submission.photosPerSubmission.filter(
      photo =>
        Object.keys(selectedValues)
          .map(key => {
            if (photo.tags == null) {
              // photoAnswerTags
              return false;
            }
            const currentTag = photo.tags.find(tag => tag.name === key);
            return (
              selectedValues[key].length === 0 ||
              (currentTag != null && selectedValues[key].includes(currentTag.value))
            );
          })
          .filter(result => result === false).length === 0,
    ),
  };
}

function filterSharesOfVisibility(sharesOfVisibilitySelectedValues, submission) {
  if (!sharesOfVisibilitySelectedValues) {
    return submission;
  }
  const keys = Object.keys(sharesOfVisibilitySelectedValues);
  if (
    keys.length === 0 ||
    keys
      .map(key => sharesOfVisibilitySelectedValues[key])
      .reduce((acc, values) => acc && values.length === 0, true)
  ) {
    return submission;
  }

  const filteredValues = sharesOfVisibilitySelectedValues;
  let selectedValues = submission.sharesOfVisibility;
  if (selectedValues == null) {
    return { ...submission, photosPerSubmission: [] };
  }

  if (filteredValues.locationsSelected && filteredValues.locationsSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.locationsSelected.includes(value.location),
    );
  }

  if (filteredValues.labelsSelected && filteredValues.labelsSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.labelsSelected.includes(value.label),
    );
  }

  if (filteredValues.categoriesSelected && filteredValues.categoriesSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.categoriesSelected.includes(value.category),
    );
  }

  if (filteredValues.subCategoriesSelected && filteredValues.subCategoriesSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.subCategoriesSelected.includes(value.subCategory),
    );
  }

  if (filteredValues.manufacturersSelected && filteredValues.manufacturersSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.manufacturersSelected.includes(value.manufacturer),
    );
  }

  if (filteredValues.packGroupsSelected && filteredValues.packGroupsSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.packGroupsSelected.includes(value.packGroup),
    );
  }

  if (filteredValues.promoMechanicsSelected && filteredValues.promoMechanicsSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.promoMechanicsSelected.includes(value.promoPacks),
    );
  }

  if (filteredValues.productSegmentsSelected && filteredValues.productSegmentsSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.productSegmentsSelected.includes(value.segment),
    );
  }

  if (filteredValues.brandsSelected && filteredValues.brandsSelected.length > 0) {
    selectedValues = selectedValues.filter(value =>
      filteredValues.brandsSelected.some(selected => selected === value.brand),
    );
  }

  const selectedUrls = selectedValues.flatMap(row => {
    if (row.photosUrls == null) {
      return [];
    }
    return row.photosUrls;
  });

  return {
    ...submission,
    photosPerSubmission: submission.photosPerSubmission.filter(p => selectedUrls.includes(p.url)),
  };
}

function doesSubmissionBelongToAllCheckpoints(submission, selectedCheckpoints) {
  return selectedCheckpoints.every(selectedCheckpointByCategory =>
    doesSubmissionBelongToOneCheckpointAnswer(submission, selectedCheckpointByCategory),
  );
}

function doesSubmissionBelongToOneCheckpointAnswer(submission, selectedCheckpointByCategory) {
  if (submission.checkpoints) {
    return submission.checkpoints.some(
      checkpoint =>
        selectedCheckpointByCategory[1].some(selectedCheckpoint =>
          checkpoint.answers.includes(selectedCheckpoint),
        ) && checkpoint.shortDescription === selectedCheckpointByCategory[0],
    );
  }
  return false;
}

function doesPhotoMatchTheLocationQuery(photo, query) {
  if (query === null || query === undefined) {
    return true;
  }

  if (query && query === '') {
    return true;
  }
  return photo.location.toLowerCase().includes(query.toLowerCase());
}

function doesPhotoBelongToAllSegments(photo, allSelectedSegments) {
  if (!allSelectedSegments) {
    return true;
  }
  const keys = Object.keys(allSelectedSegments);
  if (
    keys.length === 0 ||
    keys
      .map(key => allSelectedSegments[key])
      .reduce((acc, values) => acc && values.length === 0, true)
  ) {
    return true;
  }

  return Object.keys(allSelectedSegments)
    .filter(key => allSelectedSegments[key] && allSelectedSegments[key].length > 0)
    .every(key => {
      const valuesForGivenSegment = photo.segments.filter(segment => segment.name === key)[0].value;
      return allSelectedSegments[key].includes(valuesForGivenSegment);
    });
}

export function selectSegmentsByCategory(segmentsByMission) {
  return segmentsByMission[0].map(currentSegment => ({
    [currentSegment.name]: [
      ...new Set(
        segmentsByMission
          .flat()
          .filter(value => value.name === currentSegment.name)
          .map(value => value.value),
      ),
    ],
  }));
}

function doesPhotoMatchCheckpointShortDescription(photo, photoCheckpointShortDescriptionSelected) {
  if (
    !photoCheckpointShortDescriptionSelected ||
    photoCheckpointShortDescriptionSelected.length === 0
  ) {
    return photo;
  }
  return {
    ...photo,
    photosPerSubmission: photo.photosPerSubmission.filter(p =>
      photoCheckpointShortDescriptionSelected.includes(p.shortDescription),
    ),
  };
}

export function extractPhotoCheckpointShortDescriptions(fullPhotoStructure) {
  const shortDescriptionWithDuplicated = fullPhotoStructure
    .map(d => d.photosPerSubmission)
    .flat()
    .map(p => p.shortDescription);
  return [...new Set(shortDescriptionWithDuplicated)];
}

export const preparePhotoFoldersWithPhotosPerSubmission = photos => {
  if (!photos || photos.length === 0) return undefined;

  return JSON.stringify({
    folders: photos.map(photo => ({
      name: `${photo.missionId} - ${photo.submissionId}`,
      photoUrls: photo.photosPerSubmission.map(p => p.url),
    })),
  });
};

export const preparePhotoFoldersWithoutPhotosPerSubmission = (photos, name) => {
  if (!photos || photos.length === 0) return undefined;

  return JSON.stringify({
    folders: [
      {
        name: `${name}`,
        photoUrls: photos.map(p => p.url),
      },
    ],
  });
};
