import React from 'react';
import { List } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { infoExploreCampaignTypes } from '../../variables/variables';
import ModulesListFooter from '../modules/components/modulesListFooter';
import ModulesInfo from '../modules/components/modulesInfo';
import SendMail from '../components/sendMail';

import ReportCardList from '../../industryReports/components/reportCardList/reportCardList';
import Explore from '../explore';

function IndustryReports(props) {
  const [open, setOpen] = React.useState(false);
  const openSendRequest = () => setOpen(true);
  const closeSendRequest = () => setOpen(false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Explore tab={3} />
      </Grid>
      <Grid item xs={12}>
        <ModulesInfo infoExploreCampaignTypes={infoExploreCampaignTypes.industryReports} />
      </Grid>
      <Grid item xs={12}>
        <List {...props} actions={null} pagination={null} filters={null} perPage={100}>
          <ReportCardList {...props} testid="reportCardList" />
        </List>
      </Grid>
      <Grid item xs={12}>
        <ModulesListFooter
          testid="footer"
          title="Request a new Industry Report: "
          titleButton="Contact us"
          openSendRequest={openSendRequest}
          variant="outlined"
        />
      </Grid>

      <SendMail
        testid="SendMail"
        closeSendRequest={closeSendRequest}
        open={open}
        campaignType="Industry report"
        content="A member of our team will be in touch to discuss the launch of your custom industry report."
        mailTitle="Client request industry report"
        mailBody="A client requested a custom industry report, contact client to find out more."
        isWithInput
        inputPlaceholder="Please share any additional information relevant to your new Industry Report"
      />
    </Grid>
  );
}

export default IndustryReports;
