import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { showNotification, withDataProvider } from 'react-admin';

import { simpleSnooperTheme } from '../../../../theme/snooperTheme';
import { verifyEmail, getParameterByName } from '../services/authentication';

const styles = theme => ({
  background: {
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  errorMessage: {
    marginTop: theme.spacing.unit * 8,
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: simpleSnooperTheme.palette.secondary.main,
  },
  progress: {
    margin: theme.spacing.unit * 8,
  },
});

const imagebackground = {
  backgroundImage:
    "url('https://www.snooper-app.com/wp-content/uploads/2018/11/Landing_page_clients_1.jpg'",
};

export class VerifyEmailPresenter extends PureComponent {
  state = {
    isLoading: true,
    errorMessage: null,
  };

  async componentDidMount() {
    const userId = getParameterByName('userId');
    const token = getParameterByName('token');
    const { history, dispatch } = this.props;
    try {
      await verifyEmail(userId, token);
      history.push('/login');
      dispatch(showNotification('Your email was verified, you can now login.'));
    } catch (ex) {
      this.setState({
        isLoading: false,
        errorMessage:
          ex.message ||
          'Could not validate your email adress, please contact the support at info@snooper-app.com',
      });
    }
  }
  renderLoading() {
    const { isLoading } = this.state;
    const { classes } = this.props;

    if (!isLoading) {
      return null;
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    const { classes } = this.props;
    if (!errorMessage) {
      return null;
    }
    return (
      <Typography color="error" spacing={16} className={classes.errorMessage}>
        {errorMessage}
      </Typography>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={simpleSnooperTheme}>
        <div className={classes.background} style={imagebackground}>
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Verifying your email
              </Typography>
              {this.renderLoading()}
              {this.renderErrorMessage()}
            </Paper>
          </main>
        </div>
      </MuiThemeProvider>
    );
  }
}

VerifyEmailPresenter.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withDataProvider(withRouter(withStyles(styles)(VerifyEmailPresenter)));
