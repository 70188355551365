import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, CircularProgress, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Download } from 'styled-icons/boxicons-regular';

import DownloadService from '../../../../../../services/downloadAndZipService';

/**
 * @typedef {Object} EventType
 * @property {string} SUCCESS - Represents a successful event, indicating that the download is allowed.
 * @property {string} RESTRICTED - Represents a restricted event, indicating that the download is not allowed.
 * @property {string} OTHER - Represents any other type of event that doesn't fall into the success or restricted categories.
 */

/**
 * A constant object that contains different types of events related to download permissions.
 *
 * @type {EventType}
 */
export const downloadEventType = Object.freeze({
  SUCCESS: 'SUCCESS',
  RESTRICTED: 'RESTRICTED',
  OTHER: 'OTHER',
});

const styles = {
  logoEnable: {
    maxHeight: '21px',
  },
  logoDisabled: {
    maxHeight: '21px',
    opacity: '20%',
    cursor: 'not-allowed',
  },
  downloadProgress: {
    marginLeft: '10px',
    maxHeight: '20px',
  },
  textEnable: {
    color: '#0071bc',
    marginLeft: 5,
  },
  textDisabled: {
    color: '#808080',
    marginLeft: 5,
    cursor: 'not-allowed',
  },
  combinedButtonStyle: {
    maxWidth: 'auto',
    maxHeight: '35px',
    minWidth: 'auto',
    minHeight: '35px',
    padding: 5,
    borderRadius: 10,
    border: `1px solid #0071bc`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  singleButtonStyle: {
    maxWidth: 'auto',
    maxHeight: '35px',
    minWidth: 'auto',
    padding: '10px',
    minHeight: '35px',
  },
  dialog: {
    zIndex: 5,
  },
  title: {
    textAlign: 'left',
  },
  button: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#0071bc',
    borderRadius: 10,
    color: 'white',
    textDecoration: 'none',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 20,
    justifyContent: 'center',
  },
};

class DownloadAndZipPhotosButton extends React.PureComponent {
  state = {
    isDownloading: false,
    isDialogBoxOpen: false,
  };

  handleModalButtonClick = () => {
    this.setState({ isDialogBoxOpen: !this.state.isDialogBoxOpen });
  };

  handleOnClick = async () => {
    const { photoFolders, url, method, onTrackEvent } = this.props;
    try {
      this.setState({ isDownloading: true });
      await DownloadService.downloadAndZipPhotos(photoFolders, method, url);
      if (onTrackEvent && typeof onTrackEvent === 'function')
        onTrackEvent(downloadEventType.SUCCESS);
    } catch (error) {
      if (error.message === 'CampaignPhotoDownloadIsNotAllowed') {
        this.setState({ isDialogBoxOpen: true, isDownloading: false });
        if (onTrackEvent && typeof onTrackEvent === 'function')
          onTrackEvent(downloadEventType.RESTRICTED);
        return;
      }
      alert(error.message);
    }
    this.setState({ isDownloading: false });
  };

  renderPhotoDownloadProhibitModal = () => (
    <Dialog
      open={this.state.isDialogBoxOpen}
      style={styles.dialog}
      testid="dialog"
      onClose={() => this.handleModalButtonClick()}
    >
      <DialogTitle style={styles.title} testid="dialogTitle">
        {`The Photo gallery download is disabled for this campaign.`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText testid="dialogContent">
          {`To request access, please contact your Wiser customer success representative.`}
        </DialogContentText>
        <DialogActions testid="dialogActions">
          <div style={styles.buttonContainer}>
            <Button onClick={() => this.handleModalButtonClick()} style={styles.button}>
              Close
            </Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  render() {
    const { tooltip, showIcon, showText } = this.props;
    const { isDownloading } = this.state;
    return (
      <>
        {this.renderPhotoDownloadProhibitModal()}
        <Tooltip testid="downloadPhotoByVisitIconTooltip" title={tooltip}>
          <Button
            testid="downloadPhotosButton"
            style={showIcon && showText ? styles.combinedButtonStyle : styles.singleButtonStyle}
            onClick={() => this.handleOnClick()}
            disabled={isDownloading}
            size="small"
          >
            {showIcon ? (
              <Download size={20} color="#0071bc" testid="downloadPhotoButtonImage" />
            ) : null}
            {showText ? (
              <span
                style={isDownloading ? styles.textDisabled : styles.textEnable}
                testid="downloadPhotoButtonText"
              >
                {isDownloading ? `Zipping` : `Download`}
              </span>
            ) : null}
          </Button>
        </Tooltip>
        {isDownloading ? (
          <CircularProgress
            size={20}
            style={styles.downloadProgress}
            testid="downloadInProgressAnimation"
          />
        ) : null}
      </>
    );
  }
}

DownloadAndZipPhotosButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  photoFolders: PropTypes.shape({
    folders: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        photoUrls: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
  showIcon: PropTypes.bool.isRequired,
  showText: PropTypes.bool.isRequired,
  onTrackEvent: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
};

export default DownloadAndZipPhotosButton;
