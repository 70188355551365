import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = {
  header: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#33475b',
  },
  content: {
    fontSize: '13px',
    color: '#33475b',
  },
  question: {
    fontSize: '13px',
    color: '#00c9ff',
    width: '100%',
  },
};

export default function Description({ content }) {
  return (
    <Grid container>
      <Typography testid="title" style={styles.header} gutterBottom paragraph>
        {content.title}
      </Typography>
      <Typography testid="intro" style={styles.content} gutterBottom paragraph>
        {content.intro}
      </Typography>
      <Typography testid="question" style={styles.question} gutterBottom paragraph>
        {content.question}
      </Typography>
      {content.topics
        ? content.topics.map(topic => (
            // eslint-disable-next-line react/jsx-indent
            <div>
              <Typography testid="topicTitle" style={styles.header}>
                {topic.title}
              </Typography>
              <Typography testid="topicContent" style={styles.type} gutterBottom paragraph>
                {topic.content}
              </Typography>
            </div>
          ))
        : null}
    </Grid>
  );
}

Description.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    intro: PropTypes.string,
    question: PropTypes.string,
    topics: PropTypes.arrayOf({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
