import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Authentication from '../authentication.presenter';
import { forgotPassword } from '../services/authentication';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    color: 'white',
  },
  logInLink: {
    marginTop: theme.spacing.unit * 2,
  },
});

const getErrorMessage = (name, value) => {
  if (!value) {
    return 'Required';
  }
  if (name === 'email' && !value.includes('@')) {
    return 'Email is not valid';
  }
  return undefined;
};

export class ResetPasswordPresenter extends PureComponent {
  state = {
    email: undefined,
    emailError: undefined,
    isLoading: false,
  };

  onInputChange = event => {
    const { value, name } = event.target;
    const error = getErrorMessage(name, value);
    let payload = {
      [name]: value,
    };
    if (!error) {
      payload = {
        [name]: value,
        [`${name}Error`]: undefined,
      };
    }
    this.setState(payload);
  };

  onBlur = event => {
    const { name } = event.target;
    this.setState(prevState => ({
      [`${name}Error`]: getErrorMessage(name, prevState[name]),
    }));
  };

  onSubmit = async event => {
    const { email } = this.state;
    event.preventDefault();
    const { history } = this.props;
    const fields = Object.keys(this.state).filter(f => !f.endsWith('Error') && f !== 'isLoading');

    const errorMessages = fields.map(f => getErrorMessage(f, this.state[f]));
    const canSubmit = errorMessages.every(e => e == null);
    if (!canSubmit) {
      const payload = fields.reduce(
        (acc, name) => ({
          ...acc,
          [`${name}Error`]: getErrorMessage(name, this.state[name]),
        }),
        {},
      );
      this.setState(payload);
      return;
    }
    try {
      this.setState({
        isLoading: true,
      });
      await forgotPassword(email);

      this.setState({
        isLoading: false,
      });
      history.push({
        pathname: '/reset-password-done',
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        emailError: e.message,
      });
    }
  };

  renderButton() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return <CircularProgress className={classes.submit} />;
    }
    return (
      <Button
        id="submitButton"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={this.onSubmit}
      >
        Send
      </Button>
    );
  }
  render() {
    const { classes } = this.props;
    const { email, emailError } = this.state;

    return (
      <Authentication title="Reset Your Password">
        <form className={classes.form}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <TextField
                onChange={this.onInputChange}
                value={email}
                inputProps={{
                  type: 'email',
                  onBlur: this.onBlur,
                }}
                helperText={emailError}
                error={!!emailError}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
          </Grid>
          {this.renderButton()}
        </form>
      </Authentication>
    );
  }
}

ResetPasswordPresenter.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default withRouter(withStyles(styles)(ResetPasswordPresenter));
