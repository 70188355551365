import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    width: '101%',
    backgroundColor: '#fff',
    height: 60,
    border: 'solid 0.5px #c8d3e1',
  },
  foreground: {
    position: 'fixed',
    bottom: 0,
    width: '101%',
    backgroundColor: '#fff',
    height: 60,
    border: 'solid 0.5px #c8d3e1',
    zIndex: 1400,
  },
  button: {
    width: '121px',
    height: '40px',
    borderRadius: '4px',
    fontSize: '12px',
    textTransform: 'unset',
    marginRight: 20,
  },
};

export default function CustomModule({
  title,
  titleButton,
  foreground,
  openSendRequest,
  variant,
  buttonStyle,
}) {
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      style={foreground ? styles.foreground : styles.container}
      spacing={8}
    >
      <Grid item>
        <Typography testid="text">{title}</Typography>
      </Grid>
      <Grid item>
        <Button
          testid="button"
          style={buttonStyle ? buttonStyle.button : styles.button}
          onClick={openSendRequest}
          color="secondary"
          variant={variant}
        >
          {titleButton}
        </Button>
      </Grid>
    </Grid>
  );
}

CustomModule.propTypes = {
  buttonStyle: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  titleButton: PropTypes.string.isRequired,
  foreground: PropTypes.bool,
  openSendRequest: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

CustomModule.defaultProps = {
  buttonStyle: null,
  foreground: true,
  variant: 'contained',
};
