import qs from 'qs';
import fan from '../../../../../../../../../services/fan';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export async function updateAnswerByUserMissionId(userMissionId, checkpointId, key) {
  const result = await fan.delete(
    `/submissionsNew/${userMissionId}/checkpoint/${checkpointId}?key=${key}`,
    config,
  );
  return result.data;
}

export async function rotatePhoto(campaignId, degree, key) {
  const result = await fan.post(
    `/campaigns/${campaignId}/photos/rotate?degree=${degree}&key=${key}`,
    config,
  );
  return result.data;
}

export function appendTimestamp(url = '') {
  const now = Date.now();
  const urlComponents = url.split('?');
  if (urlComponents.length > 1) {
    const queryParams = qs.parse(urlComponents[1], { ignoreQueryPrefix: false });
    return `${urlComponents[0]}?${qs.stringify({ ...queryParams, timestamp: now })}`;
  }
  return `${url}?timestamp=${now}`;
}

export default null;
