import { trackEvent } from '@wisersolutions/segment';
// eslint-disable-next-line import/no-named-as-default
import selectSegmentUserSettings from './segmentSelector';

/**
 * Tracks a segment event.
 *
 * @param {string} type - The type of event to track.
 * @param {Object} params - Additional parameters to include in the event.
 * @param {Object} [user=undefined] - User object containing user data.
 * @param {Object} [customerSubscriptions=undefined] - Object containing customer subscription data.
 * @param {Object} [options={}] - Additional options for tracking.
 * @returns {void}
 */
export const trackSegmentEvents = (
  type,
  params,
  user = undefined,
  customerSubscriptions = undefined,
  options = {},
) => {
  const runtimeEnvironment = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'LOCAL';
  if (runtimeEnvironment === 'LOCAL') return;

  if (!type) throw new Error("Can't track event without an event key!");

  const userSegmentSettings = user ? selectSegmentUserSettings(user, customerSubscriptions) : {};

  const userInfo = {
    ...userSegmentSettings,
    ...params,
  };

  trackEvent(type, userInfo, options);
};

export default trackSegmentEvents;
