import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Modules from './modules/modules';
import ModulesInfo from './modules/components/modulesInfo';
import ExcellencePrograms from './excellencePrograms/excellencePrograms';
import DataSeries from './dataSeries/dataSeries';
import ModulesCard from '../homePage/components/modulesCard';
import { campaignTypesInformation } from '../variables/variables';
import { Event } from '../../../setup/analytics';
import SendMail from './components/sendMail';
import ModulesListFooter from './modules/components/modulesListFooter';

const exploreAllInfo = {
  title: 'Explore all Snooper solutions',
  content: 'Explore all our available reports or launch your own campaign',
};

const styles = {
  tabSelected: {
    borderBottom: '1px solid #017d9e',
  },
  wrapper: {
    height: '58px',
  },
  tabs: {
    height: '48px',
  },
  tabsFixed: {
    flexGrow: 0,
  },
  tabPanelRoot: {
    flexGrow: 1,
  },
  root: { height: '100%', display: 'flex', flexDirection: 'column' },
  exploreAll: { padding: 30, marginTop: 40 },
};

export function TabPanel(props) {
  const { children, value, index } = props;

  return value === index ? (
    <div testid="child" style={styles.tabPanelRoot}>
      {children}
    </div>
  ) : null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

export function ExploreAll() {
  const [open, setOpen] = React.useState(false);
  const openSendRequest = () => {
    Event('Click open', 'Contact us', 'Explore all');
    setOpen(true);
  };
  const closeSendRequest = () => setOpen(false);
  return (
    <React.Fragment>
      <Grid>
        <ModulesInfo infoExploreCampaignTypes={exploreAllInfo} />
      </Grid>

      <Grid container justify="space-evenly" alignItems="center" style={styles.exploreAll}>
        <ModulesCard content={campaignTypesInformation.modules} />
        <ModulesCard content={campaignTypesInformation.excellence} />
        <ModulesCard content={campaignTypesInformation.industryReports} />
        <ModulesCard content={campaignTypesInformation.dataSeries} />
      </Grid>

      <ModulesListFooter
        testid="footer"
        title="You can’t find what you’re looking for here? Request a custom mission"
        titleButton="Contact us"
        openSendRequest={openSendRequest}
        variant="outlined"
      />

      <SendMail
        testid="SendMail"
        closeSendRequest={closeSendRequest}
        open={open}
        content="A member of our team will be in touch to discuss the launch of your custom mission"
        extraInfo="A client requested to build a custom snooper solution "
        mailTitle="Client special request"
        mailBody="The client has reached out to build his own snooper solution"
      />
    </React.Fragment>
  );
}

function Explore(props) {
  const { classes, tab } = props;
  return (
    <div style={styles.root}>
      <Paper style={styles.tabsFixed}>
        <Tabs
          testid="tabs"
          value={tab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          centered
          classes={{
            root: classes.tabsRoot,
          }}
        >
          <Tab
            label="All"
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.wrapper,
            }}
            to="/explore/all"
            component={Link}
          />
          <Tab
            label="Quick trackers"
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.wrapper,
            }}
            to="/explore/quick-trackers"
            component={Link}
          />
          <Tab
            label="Excellence programs"
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.wrapper,
            }}
            to="/explore/excellence-programs"
            component={Link}
          />
          <Tab
            testid="industry"
            label="Industry reports"
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.wrapper,
            }}
            to="/explore/industry-reports"
            component={Link}
          />
          <Tab
            label="Data series"
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.wrapper,
            }}
            to="/explore/data-series"
            component={Link}
          />
        </Tabs>
      </Paper>
      <TabPanel value={tab} index={0}>
        <ExploreAll />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Modules />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ExcellencePrograms />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <DataSeries />
      </TabPanel>
    </div>
  );
}

Explore.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  tab: PropTypes.number,
};
Explore.defaultProps = {
  tab: 0,
};

export default withStyles(styles)(Explore);
