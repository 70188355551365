import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Event } from '../../../../setup/analytics';
import ContinueFooter from '../components/continueFooter/continueFooter';
import DatePicker from './components/datePicker/datePicker';
import ChannelSelection from './components/channelSelection/channelSelection';
import AdditionalInformation from './components/additionalInformation/additionalInformation';
import NumberOfRespondents from './components/numberOfRespondents/numberOfRespondents';
import NumberOfLocations from './components/numberOfLocations/numberOfLocations';
import CompletionStrategy from './components/completionStrategy/completionStrategy';

import HASQUOTE from '../../../../setup/featureFlipping';

const styles = {
  content: {
    '@media only screen and (min-width: 1900px)': {
      height: '69vh',
    },
    '@media only screen and (max-width: 1440px)': {
      height: '62vh',
    },
    height: '62vh',
    overflow: 'scroll',
    marginTop: 20,
    width: '96%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  calculateButton: { position: 'absolute', bottom: '10px', marginLeft: '2%' },
  card: {
    padding: 20,
    marginTop: 25,
    backgroundColor: '#fff',
    border: '1px solid #dadada',
  },
  divider: { margin: '18px 0px' },
  errorAll: { color: '#f6675e', position: 'relative', bottom: 15 },
  errorGrid: { zIndex: 10 },
  totalVisits: { color: 'grey' },
  price: {
    fontSize: '24px',
    fontWeight: '600',
  },
  from: {
    color: '#00c7fc',
    fontSize: '13px',
  },
  root: {
    backgroundColor: '#ffffff',
    width: '100%',
    padding: 20,
    borderRadius: '4px',
    boxShadow: ' 0 0 11px 5px rgba(234, 240, 246, 0.5)',
    marginTop: 30,
  },
  marginLeft: { marginLeft: 20 },
};

class QuoteType extends React.PureComponent {
  state = {
    everythingFilledIn: false,
    calculateIsClicked: false,
  };

  onGotoNextStep = () => {
    Event('Navigation', 'Select next step: quote', `Explore modules, scope`);
    this.setState({ calculateIsClicked: true });
    if (this.areSettingsCorrectlyFilled()) {
      this.setState({ everythingFilledIn: true });
      this.props.onGotoNextStep();
    }
    return null;
  };

  getOrderIfFullNetworkCompletion = completionStrategy => {
    const order = { ...this.props.quoteOrder };
    const sum = this.calculateFullNetworkSum();
    if (completionStrategy === 'Full network') {
      order.numberOfLocations = sum;
      return order;
    }
    const totalSurveys = order.multipleVisitPerStore * order.numberOfLocations;
    if (sum > 0 && sum < totalSurveys) {
      order.numberOfLocations = sum;
      return order;
    }
    return order;
  };

  areSettingsCorrectlyFilled = () => {
    const checkOrder = { ...this.props.quoteOrder };
    const startDate = checkOrder.startDate === null;
    const endDate = checkOrder.endDate === null;
    const storeTypes = checkOrder.storeTypes.length <= 0;
    const numberOfLocations = checkOrder.numberOfLocations <= 0;
    const multipleVisitPerStore = checkOrder.multipleVisitPerStore <= 0;
    const completionMethology = checkOrder.completionStrategy.trim() === '';
    if (
      startDate ||
      endDate ||
      storeTypes ||
      numberOfLocations ||
      multipleVisitPerStore ||
      completionMethology
    ) {
      return false;
    }
    return true;
  };

  calculateFullNetworkSum = () => {
    const { storeTypes } = this.props;
    let sum = 0;
    const storeSelected = storeTypes.filter(storeType => storeType.isChecked);
    const bannerSelected = storeSelected.map(banners =>
      banners.values.filter(ban => ban.isChecked),
    );
    if (bannerSelected.length > 0) {
      for (let i = 0; i < bannerSelected.flat().length; i++) {
        sum += bannerSelected.flat()[i].amount;
      }
    }
    return sum;
  };

  handleIsClicked = () => {
    this.setState({ calculateIsClicked: true });
  };

  handleChangeVisits = (numberOfLocations, multipleVisitPerStore) => event => {
    Event(
      'Editing',
      'Change visits',
      `numberOfLocations: ${numberOfLocations}, multipleVisitPerStore: ${multipleVisitPerStore}`,
    );
    const order = {
      ...this.props.quoteOrder,
      [numberOfLocations]: Number(event.target.value),
    };
    this.props.onQuoteOrderChange(order);
  };

  handleChangeCompletionStrategy = event => {
    Event(
      'Editing',
      `Change completion methodology: ${event.target.value}`,
      'Explore modules, scope',
    );
    const order = this.getOrderIfFullNetworkCompletion(event.target.value);
    order.completionStrategy = event.target.value;
    return this.props.onQuoteOrderChange(order);
  };

  renderContinueButton = () => {
    const { onGotoPreviousStep } = this.props;
    return (
      <ContinueFooter
        testid="footer"
        onGotoNextStep={this.onGotoNextStep}
        onGotoPreviousStep={onGotoPreviousStep}
      />
    );
  };

  renderScope = () => {
    const { quoteOrder } = this.props;
    const { multipleVisitPerStore, numberOfLocations } = quoteOrder;
    const { everythingFilledIn, calculateIsClicked } = this.state;

    return (
      <Grid container style={styles.root} spacing={16}>
        <Grid item container justify="space-between">
          <Typography variant="h6">What is the scope of your campaign?</Typography>
          <Typography style={styles.totalVisits}>
            Total visits: {(multipleVisitPerStore * numberOfLocations).toLocaleString()}
          </Typography>
        </Grid>
        <Grid item container direction="column">
          <CompletionStrategy
            testid="completionStrategy"
            quoteOrder={quoteOrder}
            calculateIsClicked={calculateIsClicked}
            everythingFilledIn={everythingFilledIn}
            handleChangeCompletionStrategy={this.handleChangeCompletionStrategy}
          />
          <NumberOfLocations
            testid="numberOfLocations"
            quoteOrder={quoteOrder}
            calculateIsClicked={calculateIsClicked}
            everythingFilledIn={everythingFilledIn}
            handleChangeVisits={this.handleChangeVisits}
          />
          <NumberOfRespondents
            testid="numberOfRespondents"
            quoteOrder={quoteOrder}
            calculateIsClicked={calculateIsClicked}
            everythingFilledIn={everythingFilledIn}
            handleChangeVisits={this.handleChangeVisits}
          />
        </Grid>
      </Grid>
    );
  };
  render() {
    const { calculateIsClicked } = this.state;
    const {
      isDrawerOpen,
      quoteOrder,
      onQuoteOrderChange,
      onSelectStoreTypeChange,
      storeTypes,
      classes,
    } = this.props;

    return (
      <Grid container direction="column">
        <Grid item container justify="space-between" alignItems="center">
          <Grid>
            <Typography variant="h5" testid="header" paragraph style={styles.marginLeft}>
              Define your campaign settings
            </Typography>
          </Grid>
          {HASQUOTE ? (
            <Grid>
              <Typography color="secondary" style={styles.price} paragraph>
                <span style={styles.from}> from </span>${quoteOrder.feePerAudit}
                <span style={styles.from}> per store</span>
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Typography color="#8c8c8c" style={styles.marginLeft}>
          Important: the settings you select (dates, channels, data collection methodology) will
          impact the base price of your mission.
        </Typography>

        <Grid item className={classes.content}>
          <DatePicker
            testid="datePicker"
            quoteOrder={quoteOrder}
            onQuoteOrderChange={onQuoteOrderChange}
            calculateIsClicked={calculateIsClicked}
          />

          <ChannelSelection
            testid="channelSelection"
            quoteOrder={quoteOrder}
            onQuoteOrderChange={onQuoteOrderChange}
            calculateIsClicked={calculateIsClicked}
            storeTypes={storeTypes}
            onSelectStoreTypeChange={onSelectStoreTypeChange}
            getOrderIfFullNetworkCompletion={this.getOrderIfFullNetworkCompletion}
          />

          {this.renderScope()}

          <Grid container style={styles.root} spacing={16}>
            <AdditionalInformation
              testid="additionalInformation"
              quoteOrder={quoteOrder}
              onQuoteOrderChange={onQuoteOrderChange}
            />
          </Grid>
        </Grid>
        <Grid item container style={styles.calculateButton} justify="flex-start" spacing={16}>
          {!isDrawerOpen ? this.renderContinueButton() : null}
          {!this.areSettingsCorrectlyFilled() && calculateIsClicked ? (
            <Grid item style={styles.errorGrid}>
              <FormHelperText
                testid="fieldMissing"
                style={styles.errorAll}
                id="component-error-text"
              >
                *Some fields are missing
              </FormHelperText>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

QuoteType.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onQuoteOrderChange: PropTypes.func.isRequired,
  onGotoNextStep: PropTypes.func.isRequired,
  quoteOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  storeTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSelectStoreTypeChange: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  onGotoPreviousStep: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuoteType);
