import React from 'react';
import { Datagrid } from 'react-admin';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ShoppingCartTotal from './components/shoppingCartTotal/shoppingCartTotal';
import ShoppingCartEmpty from './components/shoppingCartEmpty/shoppingCartEmpty';
import CongratulationsDialogContainer from '../../ordering/components/congratulationsDialog/congratulationsDialog';

const styles = {
  checkout: { padding: 20 },
};
const CustomPriceField = ({ record }) => <span>{record.orderItem.price.toLocaleString()}</span>;
CustomPriceField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
const CustomTextField = ({ source, record }) => <span>{record.orderItem[source]}</span>;
CustomTextField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  source: PropTypes.string.isRequired,
};
const dialogContentText = `A summary / confirmation has been sent to you via email! One of our customer success
managers will be in touch really soon. In the meantime don’t hesitate to contact us at
info@snooper-app.com!`;

class ShoppingCartShow extends React.PureComponent {
  state = {
    isSuccessful: false,
  };

  onHandleMessage = isSuccessful => {
    this.setState({ isSuccessful });
  };

  render() {
    const { total } = this.props;
    return (
      <React.Fragment>
        <Typography testid="title" variant="h5" style={styles.checkout}>
          Shopping cart
        </Typography>
        <Datagrid {...this.props}>
          <CustomTextField source="item" label="item" />
          <CustomTextField source="description" label="description" />
          <CustomPriceField source="price" label="$ Price" />
        </Datagrid>
        {total !== 0 ? (
          <ShoppingCartTotal
            {...this.props}
            testid="total"
            onHandleMessage={this.onHandleMessage}
          />
        ) : (
          <ShoppingCartEmpty {...this.props} testid="empty" />
        )}
        <CongratulationsDialogContainer
          testid="congratulations"
          isSuccessful={this.state.isSuccessful}
          dialogContent={dialogContentText}
        />
      </React.Fragment>
    );
  }
}

ShoppingCartShow.propTypes = {
  total: PropTypes.number.isRequired,
};

export default ShoppingCartShow;
