import React from 'react';
import { List } from 'react-admin';
import UseCaseList from './components/useCaseList/useCaseList';
import UseCaseFilter from './filters/useCasesFilter';

const IndustryReportList = props => (
  <List {...props} actions={null} pagination={null} filters={<UseCaseFilter />} perPage={100}>
    <UseCaseList {...props} testid="reportCardList" />
  </List>
);

export default IndustryReportList;
